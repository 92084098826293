import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import '../../stylesheets/dashboard/dashboard.css';
import { Card } from 'antd';

const style = {
    width: '100%',
    height: '30em',
    position: 'relative',
};
class Mapa extends Component {
    render() {
        return (
            <div>
                <div>
                    <Card className='cardMapa'>
                        <Map
                            google={this.props.google}
                            style={style}
                            className='mapaContainer'
                            initialCenter={{
                                lat: 19.3767594,
                                lng: -99.1602889,
                            }}
                            zoom={15}
                        >
                            <Marker onClick={this.onMarkerClick} name={'Current location'} />

                            <InfoWindow onClose={this.onInfoWindowClose}></InfoWindow>
                        </Map>
                    </Card>
                </div>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCIP4K035Gtc2x5t3L0ziQe5bVUXpNI4NY',
})(Mapa);
