import React, { Component } from 'react';
import { Form, Icon, Input, Row, Col, Spin, Modal } from 'antd';

import { connect } from "react-redux";

const FormItem = Form.Item;

class ModalEditarEmpleado extends Component {
    state = {
        confirmDirty: false,
    };

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }
    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Las contraseñas no coinciden!');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    }
    render() {
        const { getFieldDecorator } = this.props.form;

        const { fetchingEditarEmpleado,
            fetchingEmpleadoDetalle,
            openEditarEmpleadoModal,
            onChangeEditMode,
            empleadoDetalle,
            onRequestEditEmpleado,
        } = this.props;

        //console.log("empleadoDetalle", empleadoDetalle)

        const handleSubmit = (e) => {
            e.preventDefault();
            this.props.form.validateFieldsAndScroll((err, formulario) => {
                if (!err) {
                    if (!fetchingEditarEmpleado) {
                        onRequestEditEmpleado(formulario, empleadoDetalle.idEmpleado);
                        this.props.form.resetFields();
                    }
                }
            });
        }

        const handleCancel = () => {
            this.props.form.resetFields();
            onChangeEditMode();
        }

        const buttonProps = {
            htmlType: "submit",
            loading: fetchingEditarEmpleado,
        };

        const formItemLayout = {
            labelCol: {
                sm: { span: 0 },
            },
            wrapperCol: {
                sm: { span: 22 },
            },
        };

        return (

            <div>

                <Modal title="EDITAR OPERADOR"
                    visible={openEditarEmpleadoModal}
                    onOk={handleSubmit}
                    onCancel={handleCancel}
                    okButtonProps={buttonProps}
                    width={900}
                    height={300}
                    okText="Ok"
                    cancelText="Cancelar"
                >
                    <div>
                        <Spin spinning={fetchingEmpleadoDetalle}>
                            <Form>
                                <Row>
                                    <Col span={12}>
                                        <FormItem {...formItemLayout}
                                            extra=""
                                            label="Usuario"
                                        >
                                            {getFieldDecorator('mail', {
                                                initialValue: empleadoDetalle.ife,
                                                rules: [{
                                                    type: 'email',
                                                    required: true, message: "Favor de llenar el campo.",

                                                }],
                                            })(
                                                <Input disabled={true} prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} placeholder="Usuario" />
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item {...formItemLayout}
                                            label="Nombre operador"
                                        >
                                            {getFieldDecorator('empleado', {
                                                initialValue: empleadoDetalle.empleado,
                                                rules: [{
                                                    required: true, message: "Favor de llenar el campo."
                                                }]
                                            })(
                                                <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} placeholder="Ingrese el nombre" />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item {...formItemLayout}
                                            label="Contraseña"
                                        >
                                            {getFieldDecorator('password', {
                                                rules: [{
                                                    required: true, message: "Favor de llenar el campo.",
                                                }],
                                            })(
                                                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} type="password" placeholder="Contraseña" />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        openEditarEmpleadoModal: state.AdministradorReducer.openEditarEmpleadoModal,
        fetchingEditarEmpleado: state.AdministradorReducer.fetchingEditarEmpleado,
        fetchingEmpleadoDetalle: state.AdministradorReducer.fetchingEmpleadoDetalle,
        empleadoDetalle: state.AdministradorReducer.empleadoDetalle,
        perfiles: state.AdministradorReducer.perfiles,
        fetchingPerfiles: state.AdministradorReducer.fetchingPerfiles,
    };

};

const mapDispatchToProps = dispatch => {
    return {
        onChangeEditMode: () => {
            dispatch({ type: "OPEN_EDITAR_EMPLEADO_MODAL", openEditarEmpleadoModal: false });
        },
        onRequestEditEmpleado: (formulario, idEmpleado) => {
            dispatch({ type: "EDITAR_EMPLEADO_REQUEST", formulario: formulario, idEmpleado });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ModalEditarEmpleado));

