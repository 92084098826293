import React, { Component } from 'react';
import { Input, Col, Row, Pagination, Card, Tabs, Spin } from 'antd';
import '../../stylesheets/dashboard/dashboard.css';
import ReactPlayer from 'react-player/youtube';
import { connect } from 'react-redux';

const { TabPane } = Tabs;
const Search = Input.Search;

class BlogCards extends Component {
    state = {
        page: 1,
    };
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.onRequestListaNotas(0);
    }
    render() {
        const { listaNotas, totalNotas, onRequestListaNotas, onBuscarNota, total, totalEventos } = this.props;

        const changePage = (e) => {
            this.setState({
                page: e,
            });
            onRequestListaNotas(e);
        };

        const onSearch = (value) => {
            onBuscarNota(value);
        };

        return (
            <div className='dashboardFondo'>
                <br />
                <br />
                <br />
                <div>
                    <Tabs defaultActiveKey='1'>
                        <TabPane tab='Blog' key='1'>
                            <h1 className='blogTitulo'>Blog</h1>
                            <hr />
                            <Spin spinning={this.props.fetchingGetNotas}>
                                {listaNotas[0] ? <p className='totalBlog'>{total} artículos</p> : false}
                                <Col span={8}>
                                    <Search placeholder='Buscar Nota ' onSearch={(value) => onSearch(value)} className='searchBar' allowClear />
                                </Col>
                                <br />
                                <br />
                                <br />
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    {listaNotas[0] ? (
                                        <div>
                                            <div className='gutter-box'>
                                                {listaNotas.map((item, idItem) => (
                                                    <div key={idItem}>
                                                        {item.tipoByIdTipo.idTipo === 1 ? (
                                                            <div>
                                                                <Col className='gutter-row' xs={24} sm={12} md={12} lg={8}>
                                                                    <a href={'/Nota/' + item.idNota + '/' + item.listaUrl.tituloURL}>
                                                                        {item.imagen === '' ? (
                                                                            <Card className='cardBlog' hoverable cover={<ReactPlayer className='fotoBlog' width='100%' height='100%' url={item.video} />}>
                                                                                <p className='categoriasBlog'>{item.categoriaByIdCategoria.descripcion}</p>
                                                                                <h3 className='tituloBlog'>{item.titulo}</h3>
                                                                                {item.listaTiempo.tieneDias && <div>{item.listaDias.listaDiasDiferencia[idItem] === 0 ? <p className='tiempoPublicada'>Publicada recientemente</p> : <div>{item.listaDias.listaDiasDiferencia[idItem] === 1 ? <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} día</p> : <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} días</p>}</div>}</div>}
                                                                                {item.listaTiempo.tieneMeses && <div>{item.listaDias.listaMesDiferencia[idItem] === 1 ? <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaMesDiferencia[idItem]} mes</p> : <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaMesDiferencia[idItem]} meses</p>}</div>}
                                                                                {item.listaTiempo.tieneAños && <div>{item.listaDias.listaAñosDiferencia[idItem] === 1 ? <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} año</p> : <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} años</p>}</div>}
                                                                                <h6 className='descripcionBlog'>{item.descripcion}</h6>
                                                                                {/*  <Meta title={item.titulo} description={item.descripcion} /> */}
                                                                            </Card>
                                                                        ) : (
                                                                            <Card className='cardBlog' hoverable cover={<img alt='img' src={item.imagen} />}>
                                                                                <p className='categoriasBlog'>{item.categoriaByIdCategoria.descripcion}</p>
                                                                                <h3 className='tituloBlog'>{item.titulo}</h3>
                                                                                {item.listaTiempo.tieneDias && <div>{item.listaDias.listaDiasDiferencia[idItem] === 0 ? <p className='tiempoPublicada'>Publicada recientemente</p> : <div>{item.listaDias.listaDiasDiferencia[idItem] === 1 ? <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} día</p> : <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} días</p>}</div>}</div>}
                                                                                {item.listaTiempo.tieneMeses && <div>{item.listaDias.listaMesDiferencia[idItem] === 1 ? <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaMesDiferencia[idItem]} mes</p> : <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaMesDiferencia[idItem]} meses</p>}</div>}
                                                                                {item.listaTiempo.tieneAños && <div>{item.listaDias.listaAñosDiferencia[idItem] === 1 ? <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} año</p> : <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} años</p>}</div>}
                                                                                <h6 className='descripcionBlog'>{item.descripcion}</h6>
                                                                                {/*  <Meta title={item.titulo} description={item.descripcion} /> */}
                                                                            </Card>
                                                                        )}
                                                                    </a>
                                                                    <br />
                                                                </Col>
                                                            </div>
                                                        ) : (
                                                            false
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        false
                                    )}
                                </Row>
                                <br />
                                <Pagination onChange={changePage} total={totalNotas} style={{ textAlign: 'center' }} pageSize={12} />
                                <br />
                            </Spin>
                        </TabPane>
                        <TabPane tab='Sala de prensa' key='2'>
                            <h1 className='blogTitulo'>Sala de prensa</h1>
                            <hr />
                            <Spin spinning={this.props.fetchingGetNotas}>
                                {listaNotas[0] ? <p className='totalBlog'>{totalEventos} artículos</p> : false}
                                <Col span={8}>
                                    <Search placeholder='Buscar Nota ' onSearch={(value) => onSearch(value)} className='searchBar' allowClear />
                                </Col>
                                <br />
                                <br />
                                <br />
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    {listaNotas[0] ? (
                                        <div>
                                            <div className='gutter-box'>
                                                {listaNotas.map((item, idItem) => (
                                                    <div key={idItem}>
                                                        {item.tipoByIdTipo.idTipo === 2 ? (
                                                            <div>
                                                                <Col className='gutter-row' xs={24} sm={12} md={12} lg={8}>
                                                                    <a href={'/Nota/' + item.idNota + '/' + item.listaUrl.tituloURL}>
                                                                        {item.imagen === '' ? (
                                                                            <Card className='cardBlog' hoverable cover={<ReactPlayer className='fotoBlog' width='100%' height='100%' url={item.video} />}>
                                                                                <p className='categoriasBlog'>{item.categoriaByIdCategoria.descripcion}</p>
                                                                                <h3 className='tituloBlog'>{item.titulo}</h3>
                                                                                {item.listaTiempo.tieneDias && <div>{item.listaDias.listaDiasDiferencia[idItem] === 0 ? <p className='tiempoPublicada'>Publicada recientemente</p> : <div>{item.listaDias.listaDiasDiferencia[idItem] === 1 ? <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} día</p> : <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} días</p>}</div>}</div>}
                                                                                {item.listaTiempo.tieneMeses && <div>{item.listaDias.listaMesDiferencia[idItem] === 1 ? <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaMesDiferencia[idItem]} mes</p> : <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaMesDiferencia[idItem]} meses</p>}</div>}
                                                                                {item.listaTiempo.tieneAños && <div>{item.listaDias.listaAñosDiferencia[idItem] === 1 ? <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} año</p> : <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} años</p>}</div>}
                                                                                <h6 className='descripcionBlog'>{item.descripcion}</h6>
                                                                                {/*  <Meta title={item.titulo} description={item.descripcion} /> */}
                                                                            </Card>
                                                                        ) : (
                                                                            <Card className='cardBlog' hoverable cover={<img alt='img' src={item.imagen} />}>
                                                                                <p className='categoriasBlog'>{item.categoriaByIdCategoria.descripcion}</p>
                                                                                <h3 className='tituloBlog'>{item.titulo}</h3>
                                                                                {item.listaTiempo.tieneDias && <div>{item.listaDias.listaDiasDiferencia[idItem] === 0 ? <p className='tiempoPublicada'>Publicada recientemente</p> : <div>{item.listaDias.listaDiasDiferencia[idItem] === 1 ? <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} día</p> : <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} días</p>}</div>}</div>}
                                                                                {item.listaTiempo.tieneMeses && <div>{item.listaDias.listaMesDiferencia[idItem] === 1 ? <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaMesDiferencia[idItem]} mes</p> : <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaMesDiferencia[idItem]} meses</p>}</div>}
                                                                                {item.listaTiempo.tieneAños && <div>{item.listaDias.listaAñosDiferencia[idItem] === 1 ? <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} año</p> : <p className='tiempoPublicada'>Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} años</p>}</div>}
                                                                                <h6 className='descripcionBlog'>{item.descripcion}</h6>
                                                                                {/*  <Meta title={item.titulo} description={item.descripcion} /> */}
                                                                            </Card>
                                                                        )}
                                                                    </a>
                                                                    <br />
                                                                </Col>
                                                            </div>
                                                        ) : (
                                                            false
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        false
                                    )}
                                </Row>
                                <br />
                                <Pagination onChange={changePage} total={totalEventos} style={{ textAlign: 'center' }} pageSize={12} />
                                <br />
                            </Spin>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        fetchingGetNotas: state.AdministradorReducer.fetchingGetNotas,
        listaNotas: state.AdministradorReducer.listaNotas,
        totalNotas: state.AdministradorReducer.totalNotas,
        total: state.AdministradorReducer.total,
        totalEventos: state.AdministradorReducer.totalEventos,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRequestListaNotas: (page) => {
            dispatch({ type: 'GET_NOTAS_REQUEST', page: page });
        },
        onBuscarNota: (nota) => {
            dispatch({ type: 'BUSCAR_NOTA_REQUEST', nota, page: 0 });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogCards);
