import React, { Component } from 'react';

import imgIntegracion from '../../../../assets/img/nosotros/sissa-digital/soluciones-digitales.png';


class IntegracionDeSoluciones extends Component {

 
    render() {     

        
    return(

        
            <div>
                <img className="img-fluid" alt="desarrollo de software" src={imgIntegracion} />                
            </div>
                       
    );
    }
}

export default IntegracionDeSoluciones;