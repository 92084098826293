import React, { Component } from 'react';
import { Row, Col } from 'antd';
import '../stylesheets/dashboard/dashboard.css';
import Header from "../Components/HeaderHome";
import Footer from '../Components/Dashboard/Footer';
import Categorias from '../Components/Blog/Categorias';

class CategoriasDashboard extends Component {

    render() {
        return (
            <div className="App body fondo">
                <Header />
                <br />
                <br />
                <br />
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col style={{ marginLeft: 20 }} xs={24} md={19}>
                        <Categorias />
                    </Col>
                </Row>
                <br />
                <Col span={24}>
                </Col>
                <br />
                <Footer />
            </div>
        );
    }
}

export default CategoriasDashboard;    