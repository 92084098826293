import React, { Component } from 'react';
import '../../../stylesheets/Pages/mainI.css';
import '../../../stylesheets/Pages/quienesSomos.css';
import Footer from '../../Dashboard/Footer';
import Header from '../../HeaderHome';
import MetaTags from 'react-meta-tags';

class QuienesSomos extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
      }
      
    render() {

        const scriptNosotrosBanner = document.createElement("script");    
        scriptNosotrosBanner.src = "../assets/js/nosotros/banner.js";
        scriptNosotrosBanner.async = true;
        
        document.body.appendChild(scriptNosotrosBanner);

    return(
        <div>
             <MetaTags>                   
                <meta name="description" content="Somos una de las integradoras de soluciones tecnológicas de vanguardia más importantes en América Latina." />
            </MetaTags>

            <Header/>
                
            <main id="nosotros">    
   
                <div id="carouselNosotros" className="carousel slide">
                    <ol className="carousel-indicators">
                    <li data-target="#carouselNosotros" data-slide-to="0" className="active"></li>   
                    </ol>
  
                    {/* <!-- carousel content --> */}
                    <div className="carousel-inner">
  
                        {/* <!-- first slide --> */}
                        <div id="nosotros-slide1" className="carousel-item active" >
                        <div className="yk-carousel-caption d-flex flex-column justify-content-center h-90vh">
                            <div id='nosotros-slide1-text' className="d-flex flex-column  justify-content-center h-90vh">
                                <div className="title-container">
                                <div className="gray-thin-title1 title1 mt-auto" data-animation="animated bounceInLeft">
                                    ANTE CADA RETO UNA
                                </div>
                                <div className="red-bold-title1 title2 ml-4" data-animation="animated bounceInRight" >
                                    SOLUCIÓN
                                </div>
                                </div>
                                
                
                            </div>
                        </div>
                    </div>     
                    
                    </div>
  
                    {/* <!-- controls --> */}
                    <a className="carousel-control-prev" href="#carouselNosotros" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselNosotros" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                    </a>
                
                </div>
  

  
       
                    
                <section id="nosotros-short-description" className="d-flex align-items-center" data-aos="fade-up">
                    
                    <div className="container">
                        <div className="row">
                            <div id="short-description-text">
                                <p className="mb-0 section-text">
                                    <span className="capital-text color-sissa-p-red text-uppercase">SISSA Monitoring Integral. </span> 
                                    Aunque comenzamos como una fábrica de software enfocada en el desarrollo de sistemas de seguridad electrónica,
                                    hoy en día nos hemos consolidado como una de las integradoras de soluciones tecnológicas de vanguardia
                                    más importantes en toda América Latina.
                                </p>
                                
                            </div>            
                        </div>    
                    </div>
                </section>
                
                <div className="container separator-bottom">
                    <div className="row">
                        <div className="col-md-12 separator-line"></div>
                    </div>
                </div>
                
                    
                    
                <section id="nosotros-ante-cada-reto-una-solucion" className="d-flex align-items-center" data-aos="fade-up">
                    
                    <div className="container" >
                        <div className="row">
                            <div className="col-md-9 d-flex flex-direction-column section-text">
                                <h3>ANTE CADA RETO, UNA SOLUCIÓN</h3>
                                <p>
                                    Tomamos cada reto como una oportunidad de éxito. Es por ello que integramos la mejor tecnología a 
                                    nivel mundial para el desarrollo de soluciones que den respuesta a las necesidades particulares de
                                    las diferentes industrias, adaptándonos a las demandas y posibilidades específicas de cada uno de 
                                    nuestros clientes y estableciendo sólidas relaciones de negocios.
                                </p>
                            </div>        
                            <div className="col-md-3">
                                <img className="img-fluid" src="assets/img/nosotros/ante-cada-reto.png" alt="Cada reto es una oportunidad de éxito" />                
                
                            </div>    
                        </div>    
                    </div>
                </section>
                
                <div className="container separator-bottom">
                    <div className="row">
                        <div className="col-md-12 separator-line"></div>
                    </div>
                </div>     
  
        
    
                <section id="nostotros-organizaciones-relacionadas" className="d-flex align-items-center">
                    
                    <div className="container-fluid" data-aos="fade-up">
                        <div className="row section-header">
                            <div className="col-md-12 d-flex flex-direction-column section-text">
                                <h3>ORGANIZACIONES RELACIONADAS</h3>
                                <p>
                                    Somos parte de las organizaciones más destacables de la industria de la tecnología.
                                </p>
                            </div>
                        </div>  
                    
                        <div className="row content" data-aos="fade-up">
                            <div className="simple-hex-image">
                                <img className="img-fluid" src="assets/img/nosotros/bi.png" alt="Biometrics Institute" />                
                            </div>    
                            <div className="simple-hex-image">
                                <img className="img-fluid" src="assets/img/nosotros/alas.png" alt="Asociación Latinoamericana de Seguridad" />                
                            </div>    
                            <div className="simple-hex-image">
                                <img className="img-fluid" src="assets/img/nosotros/asis.png" alt="ASIS International" />                
                            </div>    
                        </div>
                    </div>
                </section>
                
                <div className="container separator-bottom">
                    <div className="row">
                        <div className="col-md-12 separator-line"></div>
                    </div>
                </div>
  
       
                    
                {/* <section id="nostotros-socios-de-negocio" className="d-flex align-items-center">
                    
                    <div className="container-fluid" data-aos="fade-up">
                        <div className="row section-header">
                            <div className="col-md-12 d-flex flex-direction-column section-text">
                                <h3>SOCIOS DE NEGOCIO</h3>
                                <p>
                                    Hemos establecido relaciones de socios de negocio, sustentadas en la 
                                    confianza, lealtad  y calidad, con importantes actores de distintas industrias.
                                </p>
                            </div>
                        </div>  
                    
                        <div className="row content" >
                            <div className="simple-hex-image-row" data-aos="fade-up">
                                <img className="img-fluid" src="assets/img/nosotros/socios/scotiabank.png" alt="Scotiabank" />                
                                <img className="img-fluid" src="assets/img/nosotros/socios/honeywell.png" alt="Honeywell" />                
                                <img className="img-fluid" src="assets/img/nosotros/socios/lomas_santa_fe.png" alt="Lomas Santa Fe" />                
                                <img className="img-fluid" src="assets/img/nosotros/socios/lauman.png" alt="Lauman" />                
                                <img className="img-fluid" src="assets/img/nosotros/socios/galerias-plaza-estrellas.png" alt="Galeria Plaza de las Estrellas" />                
                                <img className="img-fluid" src="assets/img/nosotros/socios/surman.png" alt="Surman" />   
                                <img className="img-fluid" src="assets/img/nosotros/socios/comtelsat.png" alt="Comtelsat" />                             
                            </div>    
                            <div className="simple-hex-image-row" data-aos="fade-up">
                                
                            </div>    
                            
                        </div>
                        
                    </div>
                </section> */}
                
                <div className="container separator-bottom">
                    <div className="row">
                        <div className="col-md-12 separator-line"></div>
                    </div>
                </div>
  
            </main>
  

            <Footer/>
        </div>
    );
    }
}

export default QuienesSomos;

