export const CLEAN_FORM = 'CLEAN_FORM';

export const OBTENER_TIPOS_ELEMENTOS_REQUEST = 'OBTENER_TIPOS_ELEMENTOS_REQUEST';
export const OBTENER_TIPOS_ELEMENTOS_SUCCESS = 'OBTENER_TIPOS_ELEMENTOS_SUCCESS';
export const OBTENER_TIPOS_ELEMENTOS_FAILURE = 'OBTENER_TIPOS_ELEMENTOS_FAILURE';

export const OBTENER_ELEMENTOS_REQUEST = 'OBTENER_ELEMENTOS_REQUEST';
export const OBTENER_ELEMENTOS_SUCCESS = 'OBTENER_ELEMENTOS_SUCCESS';
export const OBTENER_ELEMENTOS_FAILURE = 'OBTENER_ELEMENTOS_FAILURE';

export const CREAR_ELEMENTO_REQUEST = 'CREAR_ELEMENTO_REQUEST';
export const CREAR_ELEMENTO_SUCCESS = 'CREAR_ELEMENTO_SUCCESS';
export const CREAR_ELEMENTO_FAILURE = 'CREAR_ELEMENTO_FAILURE';

export const EDITAR_ELEMENTO_REQUEST = 'EDITAR_ELEMENTO_REQUEST';
export const EDITAR_ELEMENTO_SUCCESS = 'EDITAR_ELEMENTO_SUCCESS';
export const EDITAR_ELEMENTO_FAILURE = 'EDITAR_ELEMENTO_FAILURE';

export const BORRAR_ELEMENTO_REQUEST = 'BORRAR_ELEMENTO_REQUEST';
export const BORRAR_ELEMENTO_SUCCESS = 'BORRAR_ELEMENTO_SUCCESS';
export const BORRAR_ELEMENTO_FAILURE = 'BORRAR_ELEMENTO_FAILURE';

export const OPEN_NUEVO_ELEMENTO_MODAL = 'OPEN_NUEVO_ELEMENTO_MODAL';
export const OPEN_EDITAR_ELEMENTO_MODAL = 'OPEN_EDITAR_ELEMENTO_MODAL';
export const ELEMENTO_DETALLE = 'ELEMENTO_DETALLE';

export const SHOW_ERROR_MSG = 'SHOW_ERROR_MSG';
export const SHOW_SUCCESS_MSG = 'SHOW_SUCCESS_MSG';

// ****** NOTAS ******** //
export const CREAR_NOTA_REQUEST = 'CREAR_NOTA_REQUEST';
export const CREAR_NOTA_SUCCESS = 'CREAR_NOTA_SUCCESS';
export const CREAR_NOTA_FAILURE = 'CREAR_NOTA_FAILURE';

export const GET_NOTAS_REQUEST = 'GET_NOTAS_REQUEST';
export const GET_NOTAS_SUCCESS = 'GET_NOTAS_SUCCESS';
export const GET_NOTAS_FAILURE = 'GET_NOTAS_FAILURE';

export const GET_NOTAS_CATEGORIA_REQUEST = 'GET_NOTAS_CATEGORIA_REQUEST';
export const GET_NOTAS_CATEGORIA_SUCCESS = 'GET_NOTAS_CATEGORIA_SUCCESS';
export const GET_NOTAS_CATEGORIA_FAILURE = 'GET_NOTAS_CATEGORIA_FAILURE';

export const GET_NOTA_ESPECIFICA_REQUEST = 'GET_NOTA_ESPECIFICA_REQUEST';
export const GET_NOTA_ESPECIFICA_SUCCESS = 'GET_NOTA_ESPECIFICA_SUCCESS';
export const GET_NOTA_ESPECIFICA_FAILURE = 'GET_NOTA_ESPECIFICA_FAILURE';

export const CHANGE_CATEGORIA = 'CHANGE_CATEGORIA';

export const ELIMINAR_NOTA_REQUEST = 'ELIMINAR_NOTA_REQUEST';
export const ELIMINAR_NOTA_SUCCESS = 'ELIMINAR_NOTA_SUCCESS';
export const ELIMINAR_NOTA_FAILURE = 'ELIMINAR_NOTA_FAILURE';

export const SHOW_EDITAR_NOTA_MODAL = 'SHOW_EDITAR_NOTA_MODAL';
export const GET_INFO_NOTA_REQUEST = 'GET_INFO_NOTA_REQUEST';
export const GET_INFO_NOTA_SUCCESS = 'GET_INFO_NOTA_SUCCESS';

export const EDITAR_NOTA_REQUEST = 'EDITAR_NOTA_REQUEST';
export const EDITAR_NOTA_SUCCESS = 'EDITAR_NOTA_SUCCESS';
export const EDITAR_NOTA_FAILURE = 'EDITAR_NOTA_FAILURE';

// ****** NOTAS  POR CATEGORIA ******** //

export const GET_CATEGORIAS_REQUEST = 'GET_CATEGORIAS_REQUEST';
export const GET_CATEGORIAS_SUCCESS = 'GET_CATEGORIAS_SUCCESS';
export const GET_CATEGORIAS_FAILURE = 'GET_CATEGORIAS_FAILURE';

export const SHOW_NUEVA_CATEGORIA_MODAL = 'SHOW_NUEVA_CATEGORIA_MODAL';
export const SHOW_EDITAR_CATEGORIA_MODAL = 'SHOW_EDITAR_CATEGORIA_MODAL';

export const CREAR_CATEGORIA_REQUEST = 'CREAR_CATEGORIA_REQUEST';
export const CREAR_CATEGORIA_SUCCESS = 'CREAR_CATEGORIA_SUCCESS';
export const CREAR_CATEGORIA_FAILURE = 'CREAR_CATEGORIA_FAILURE';

export const EDITAR_CATEGORIA_REQUEST = 'EDITAR_CATEGORIA_REQUEST';
export const EDITAR_CATEGORIA_SUCCESS = 'EDITAR_CATEGORIA_SUCCESS';
export const EDITAR_CATEGORIA_FAILURE = 'EDITAR_CATEGORIA_FAILURE';

export const ELIMINAR_CATEGORIA_REQUEST = 'ELIMINAR_CATEGORIA_REQUEST';
export const ELIMINAR_CATEGORIA_SUCCESS = 'ELIMINAR_CATEGORIA_SUCCESS';
export const ELIMINAR_CATEGORIA_FAILURE = 'ELIMINAR_CATEGORIA_FAILURE';

export const BUSCAR_NOTA_REQUEST = 'BUSCAR_NOTA_REQUEST';
export const BUSCAR_NOTA_SUCCESS = 'BUSCAR_NOTA_SUCCESS';
export const BUSCAR_NOTA_FAILURE = 'BUSCAR_NOTA_FAILURE';

export const BUSCAR_NOTA_CATEGORIAS_REQUEST = 'BUSCAR_NOTA_CATEGORIAS_REQUEST';
export const BUSCAR_NOTA_CATEGORIAS_SUCCESS = 'BUSCAR_NOTA_CATEGORIAS_SUCCESS';
export const BUSCAR_NOTA_CATEGORIAS_FAILURE = 'BUSCAR_NOTA_CATEGORIAS_FAILURE';

export const ENVIAR_CONTACTO_REQUEST = 'ENVIAR_CONTACTO_REQUEST';
export const ENVIAR_CONTACTO_SUCCESS = 'ENVIAR_CONTACTO_SUCCESS';
export const ENVIAR_CONTACTO_FAILURE = 'ENVIAR_CONTACTO_FAILURE';

export const EMPLEADOS_ACTIVOS_REQUEST = 'EMPLEADOS_ACTIVOS_REQUEST';
export const EMPLEADOS_ACTIVOS_SUCCESS = 'EMPLEADOS_ACTIVOS_SUCCESS';
export const EMPLEADOS_ACTIVOS_FAILURE = 'EMPLEADOS_ACTIVOS_FAILURE';

export const EMPLEADO_DETALLE_REQUEST = 'EMPLEADO_DETALLE_REQUEST';
export const EMPLEADO_DETALLE_SUCCESS = 'EMPLEADO_DETALLE_SUCCESS';
export const EMPLEADO_DETALLE_FAILURE = 'EMPLEADO_DETALLE_FAILURE';

export const OPEN_EDITAR_EMPLEADO_MODAL = 'OPEN_EDITAR_EMPLEADO_MODAL';
export const OPEN_NUEVO_EMPLEADO_MODAL = 'OPEN_NUEVO_EMPLEADO_MODAL';

export const NUEVO_EMPLEADO_REQUEST = 'NUEVO_EMPLEADO_REQUEST';
export const NUEVO_EMPLEADO_SUCCESS = 'NUEVO_EMPLEADO_SUCCESS';
export const NUEVO_EMPLEADO_FAILURE = 'NUEVO_EMPLEADO_FAILURE';

export const ELIMINAR_EMPLEADO_REQUEST = 'ELIMINAR_EMPLEADO_REQUEST';
export const ELIMINAR_EMPLEADO_SUCCESS = 'ELIMINAR_EMPLEADO_SUCCESS';
export const ELIMINAR_EMPLEADO_FAILURE = 'ELIMINAR_EMPLEADO_FAILURE';

export const EDITAR_EMPLEADO_REQUEST = 'EDITAR_EMPLEADO_REQUEST';
export const EDITAR_EMPLEADO_SUCCESS = 'EDITAR_EMPLEADO_SUCCESS';
export const EDITAR_EMPLEADO_FAILURE = 'EDITAR_EMPLEADO_FAILURE';

export const GET_PERFILES_REQUEST = 'GET_PERFILES_REQUEST';
export const GET_PERFILES_SUCCESS = 'GET_PERFILES_SUCCESS';
export const GET_PERFILES_FAILURE = 'GET_PERFILES_FAILURE';

export const GET_GALERIA_REQUEST = 'GET_GALERIA_REQUEST';
export const GET_GALERIA_SUCCESS = 'GET_GALERIA_SUCCESS';
export const GET_GALERIA_FAILURE = 'GET_GALERIA_FAILURE';

export const SHOW_AGREGAR_FOTO_MODAL = 'SHOW_AGREGAR_FOTO_MODAL';

export const AGREGAR_FOTO_REQUEST = 'AGREGAR_FOTO_REQUEST';
export const AGREGAR_FOTO_SUCCESS = 'AGREGAR_FOTO_SUCCESS';
export const AGREGAR_FOTO_FAILURE = 'AGREGAR_FOTO_FAILURE';

export const SHOW_EDITAR_FOTO_MODAL = 'SHOW_EDITAR_FOTO_MODAL';

export const EDITAR_FOTO_REQUEST = 'EDITAR_FOTO_REQUEST';
export const EDITAR_FOTO_SUCCESS = 'EDITAR_FOTO_SUCCESS';
export const EDITAR_FOTO_FAILURE = 'EDITAR_FOTO_FAILURE';

export const ELIMINAR_FOTO_REQUEST = 'ELIMINAR_FOTO_REQUEST';
export const ELIMINAR_FOTO_SUCCESS = 'ELIMINAR_FOTO_SUCCESS';
export const ELIMINAR_FOTO_FAILURE = 'ELIMINAR_FOTO_FAILURE';
