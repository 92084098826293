import React, { Component } from 'react';
import '../../../stylesheets/Pages/notFound.css';
import Footer from '../../Dashboard/Footer';
import Header from '../../HeaderHome';


class NoEncontrado404 extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return(
            <div>
                <Header/>
                    <main>  
                        <section id="notFound404" className=" align-items-center">
                            <div id="description-container" data-aos="fade-up">
                                <div className="container justify-content-center">
                                    <div className="row flex-direction-column align-items-center nf404-text pt-4">                
                                            <p className="font-white title-1">
                                                <span  > 404 </span> 
                                                <span className="font-weight-light">- Página no encontrada </span>                       
                                            </p>   
                                    </div>        
                                </div>
                            </div>                         
                        </section>
                    </main>
  
                <Footer/>
        </div>
    );
    }
}

export default NoEncontrado404;
