import React, { Component } from 'react';

import img from '../../../../assets/img/nosotros/sissa-monitoring/proteccion-perimetral.png';


class ProteccionPerimetral extends Component {

 
    render() {    

        
    return(        
            <div>
                <img className="img-fluid" alt="Protección perimetral" src={img} />                
            </div>                       
    );
    }
}

export default ProteccionPerimetral;