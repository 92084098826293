import React, { Component } from 'react';
import { Form, Icon, Button, Row, Col, Modal, Input} from 'antd';
import ImageUploading from "react-images-uploading";

import { connect } from "react-redux";

const FormItem = Form.Item;


class ModalAgregarFoto extends Component {
    normFile = (e) => {
        //console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    state = {
        previewImage: '',
        uploadImagen: false,
        previewVisible: false,
        fileList: [],
    };
    constructor() {
        super();
        this.state = { checked: false };
        this.handleChange = this.handleChange.bind(this);
    };

    //Editor
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    handleChange(checked) {
        this.setState({ checked });
    };

    handleCancel = () => this.setState({ previewVisible: false, uploadImagen: false, });

    render() {
        const { getFieldDecorator } = this.props.form;
        const {onshowAgregarFotoModal,
            showAgregarFotoModal,
            onRequestNuevaFoto,
            fetchingNuevaFoto,
            } = this.props;
        

        const handleOk = (e) => {
            e.preventDefault();
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    if(!fetchingNuevaFoto){
                        //console.log("values de la foto", values)
                        onRequestNuevaFoto(values);
                        this.props.form.resetFields(); 
                    }
                }
            });
        }

        const handleCancel = () => {
            this.props.form.resetFields(); 
            onshowAgregarFotoModal();
        }

        const buttonProps = {
            htmlType: "submit",
            loading:fetchingNuevaFoto,
        };

        const formItemLayout = {
            labelCol: {
                sm: {span: 0},
            },
            wrapperCol: {
                sm: {span: 22},
            },
        };
        const onChange = (imageList) =>{
            //console.log("imageList", imageList)
            if(imageList.length!==0){
                this.setState({
                    uploadImagen: true,
                    fileList: imageList,
                });
            }
            else{
                this.setState({
                    uploadImagen: false,
                });
            }
        }

        return (
            <div>	
                <Modal title="Agregar nueva imagen a la galería"
                visible={showAgregarFotoModal}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={buttonProps}
                okText="Agregar imagen"
                cancelText="Cancelar"
                >
                    <div >
                        <Form>
                            <Row>
                                <Col span={24}>
                                    <FormItem {...formItemLayout}
                                        extra=""
                                        label="Nombre foto"
                                        >
                                        {getFieldDecorator('nombre', {
                                            rules: [{
                                                required: true, message: 'Favor de llenar el campo.',
                                                }],
                                        })(
                                            <Input prefix={<Icon type="form" style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={150} placeholder="Ingrese el nombre de la foto"/>
                                        )}
                                    </FormItem>
                                </Col>
                            <Col xs={24} sm={10}>
                            <FormItem
                                {...formItemLayout}
                                label="Imagen"
                            >
                                {getFieldDecorator('imagen', {
                                    rules: [{
                                        required: true, message: 'Favor de llenar el campo.',
                                        }],
                                })(
                                    <ImageUploading onChange={onChange}>
                                            {({ imageList, onImageUpload }) => (
                                            // write your building UI
                                            <div className="upload__image-wrapper">
                                        
                                                {this.state.uploadImagen === undefined || this.state.uploadImagen === false ? (
                                                    <Button type="primary" onClick={onImageUpload}>Sube una imagen</Button>
                                                ): (false)}
                                            
                                                {imageList.map(image => (
                                                <div key={image.key} className="image-item">
                                            
                                                    <img alt='img' style={{width:90}} src={image.dataURL} />
                                                    <Button type="danger" onClick={image.onRemove}><Icon type="delete" /></Button>
                                                </div>
                                                ))}
                                            </div>
                                            )}
                                    </ImageUploading>
                                )}
                            </FormItem>
                        </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {

	return {
        showAgregarFotoModal: state.AdministradorReducer.showAgregarFotoModal,
        fetchingNuevaFoto: state.AdministradorReducer.fetchingNuevaFoto,
	};
};

const mapDispatchToProps = dispatch => {
    return {
        onshowAgregarFotoModal: () => {
            dispatch({ type: "SHOW_AGREGAR_FOTO_MODAL", showAgregarFotoModal:false});
        },
        onRequestNuevaFoto: (form) => {
            dispatch({ type: "AGREGAR_FOTO_REQUEST", form});
        },
    };
};

export default connect(mapStateToProps,mapDispatchToProps) (Form.create()(ModalAgregarFoto));
