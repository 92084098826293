import React, { Component } from 'react';

import img from '../../../../assets/img/nosotros/sissa-monitoring/cctv.png';


class CCTV extends Component {

 
    render() {    

        
    return(        
            <div>
                <img className="img-fluid" alt="CCTV" src={img} />                
            </div>                       
    );
    }
}

export default CCTV;