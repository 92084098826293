import React, { Component } from 'react';
import { Form, Icon, Row, Col, Modal, Input } from 'antd';

import { connect } from "react-redux";

const FormItem = Form.Item;


class ModalEditarFoto extends Component {

    render() {
        const { getFieldDecorator } = this.props.form;
        const { onshowEditarFotoModal,
            showEditarFotoModal,
            onRequestEditarFoto,
            fetchingEditarFoto,
            fotoInfo
        } = this.props;
        //console.log("fotoInfo", fotoInfo)

        const handleOk = (e) => {
            e.preventDefault();
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    if (!fetchingEditarFoto) {
                        //console.log("values de la foto", values)
                        onRequestEditarFoto(values, fotoInfo);
                        this.props.form.resetFields();
                        onshowEditarFotoModal();
                    }
                }
            });
        }

        const handleCancel = () => {
            this.props.form.resetFields();
            onshowEditarFotoModal();
        }

        const buttonProps = {
            htmlType: "submit",
            loading: fetchingEditarFoto,
        };

        const formItemLayout = {
            labelCol: {
                sm: { span: 0 },
            },
            wrapperCol: {
                sm: { span: 22 },
            },
        };

        return (
            <div>
                <Modal title="Editar nombre de la foto"
                    visible={showEditarFotoModal}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okButtonProps={buttonProps}
                    okText="Editar nombre"
                    cancelText="Cancelar"
                >
                    <div >
                        <Form>
                            <Row>
                                <Col span={24}>
                                    {fotoInfo ? (
                                        <FormItem {...formItemLayout}
                                            extra=""
                                            label="Nuevo nombre foto"
                                        >
                                            {getFieldDecorator('nombre', {
                                                initialValue: fotoInfo.nombre,
                                                rules: [{
                                                    required: true, message: 'Favor de llenar el campo.',
                                                }],
                                            })(
                                                <Input prefix={<Icon type="form" style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={150} placeholder="Ingrese el nombre de la foto" />
                                            )}
                                        </FormItem>
                                    ) : (false)}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {

    return {
        showEditarFotoModal: state.AdministradorReducer.showEditarFotoModal,
        fetchingEditarFoto: state.AdministradorReducer.fetchingEditarFoto,
        fotoInfo: state.AdministradorReducer.fotoInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onshowEditarFotoModal: () => {
            dispatch({ type: "SHOW_EDITAR_FOTO_MODAL", showEditarFotoModal: false });
        },
        onRequestEditarFoto: (form, fotoInfo) => {
            dispatch({ type: "EDITAR_FOTO_REQUEST", form, fotoInfo });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ModalEditarFoto));
