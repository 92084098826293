import React, { Component } from 'react';
import { Col, Row } from 'antd';
import '../../stylesheets/dashboard/dashboard.css';
import Footer from '../Dashboard/Footer';
import Header from '../HeaderHome';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import Mapa from '../Dashboard/Mapa';
import Formulario from '../Dashboard/FormContacto';
import location from '../../assets/img/placeholder.png';
import phone from '../../assets/img/phone.png';
import Alert from '../../Components/Alerta';

class SISSADigital extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className='fondoBlanco'>
                <Alert />
                <Header />
                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className='imagenContacto'>
                        <Row>
                            <br />
                            <Col span={18} offset={3}>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <p className='nuestrosPartners2'>CONTÁCTANOS</p>
                                <br />
                                <br />
                            </Col>
                        </Row>
                    </div>
                    <Row className='fondoBlanco'>
                        <Col span={24} className='fondoBlancoFlotanteRayaRoja'>
                            <p className='letrasRojas'>
                                En <span style={{ fontWeight: 'bolder' }}>SISSA Monitoring Integral queremos darte la mejor atención posible.</span>{' '}
                            </p>
                            <p className='letrasRojas'>Ayúdanos a responder el siguiente formulario para que puedas recibir un trato inmediato y personalizado. </p>
                        </Col>
                    </Row>
                    <Row className='fondoBlanco' justify='s'>
                        <br />
                        {/* <Col lg={{ offset: 2, span: 10 }}>
                            <Formulario />
                        </Col> */}
                        <Col sm={{ offset: 3, span: 17 }} lg={{ offset: 6, span: 12 }}>
                            <Mapa />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} offset={6}>
                            <div className='lineaGrisLado'>
                                <Col lg={{ span: 12 }} style={{ textAlign: 'center' }}>
                                    <p>
                                        <img alt='img' src={phone} className='iconoTelefono' /> Llámanos
                                    </p>
                                    <a href='tel:5591856975' className='contactLinks' target='_blank'>
                                        55 6651 0200{' '}
                                    </a>{' '}
                                    <br />
                                    <a href='https://wa.me/+525544660515' className='contactLinks' target='_blank'>
                                        WhatsApp
                                    </a>
                                    <p>Atención de 9:00 a 18:00 horas. </p>
                                    <p>Lunes a viernes </p>
                                </Col>
                            </div>
                            <div>
                                <Col lg={{ span: 12 }} style={{ textAlign: 'center' }}>
                                    <p>
                                        <img alt='img' src={location} className='iconoLocation' /> Visítanos
                                    </p>
                                    <p>CDMX</p>
                                    <a href='https://maps.google.com/?q=19.3767594,-99.1602889' className='colorLetrasTablas'>
                                        <p>
                                            Pilares 768, Letrán Valle <br />
                                            Alc. Benito Juárez
                                            <br />
                                            C. P. 03650
                                        </p>
                                    </a>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                    <Col lg={{ offset: 6, span: 12 }}>
                        <hr />
                    </Col>
                </div>
                <Footer />
            </div>
        );
    }
}

export default SISSADigital;
