import React, {Component} from 'react';
import Desarrollo from '../sissa-digital/DesarrolloDeTecnologias';
import IntegracionDeSoluciones from '../sissa-digital/IntegracionDeSoluciones';
import EstrategiaDigital from '../sissa-digital/EstrategiaDigital';
import Particles from 'react-tsparticles';


class SissaDigital_ListDesktop extends Component
{

    constructor() {    
        super();    
        this.state = {
          subsection_title: '',          
          showDesarrollo: true,
          showIntegracion: false,
          showEstrategia: false
        };
        this.showComponent = this.showComponent.bind(this);
      }

    

    
      showComponent(name) {
        //console.log(name);
        switch (name) {
          case "desarrollo":
            this.setState({
                    subsection_title: 'DESARROLLO DE TECNOLOGÍAS ESPECÍFICAS',          
                    showDesarrollo: true,
                    showIntegracion: false,
                    showEstrategia: false
                });
            break;
          case "integracion":
            this.setState({
                    subsection_title: 'INTEGRACIÓN DE SOLUCIONES DIGITALES',          
                    showDesarrollo: false,
                    showIntegracion: true,
                    showEstrategia: false
                });
            break;
          case "estrategia":
            this.setState({
                    subsection_title: 'ESTRATEGIA DIGITAL ',          
                    showDesarrollo: false,
                    showIntegracion: false,
                    showEstrategia: true
                });
            break;
          default:
            this.setState({
                showDesarrollo: false,
                showIntegracion: false,
                showEstrategia: false
            });
        }
      }   


    
    render(){
        const { showDesarrollo, showEstrategia, showIntegracion } = this.state;  

        return(


            
            



            <section className="honeycomb-section container" id="desarrollo-de-tecnologias" data-aos="fade-up">

                <h1 hidden>Desarrollo de soluciones digitales</h1>
                    
            <Particles
                id="tsparticles"
                options={{
                background: {
                    color: {
                    value: "#ffffff",
                    },
                },
                fpsLimit: 60,
                interactivity: {
                    detectsOn: "canvas",
                    events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                    resize: true,
                    },
                    modes: {
                    bubble: {
                        distance: 400,
                        duration: 2,
                        opacity: 0.8,
                        size: 40,
                    },
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                    },
                },
                particles: {
                    color: {
                    value: "#9c9ca2",
                    },
                    links: {
                    color: "#9c9ca2",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                    },
                    collisions: {
                    enable: true,
                    },
                    move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: false,
                    speed: 6,
                    straight: false,
                    },
                    number: {
                    density: {
                        enable: true,
                        value_area: 800,
                    },
                    value: 80,
                    },
                    opacity: {
                    value: 0.5,
                    },
                    shape: {
                    type: "circle",
                    },
                    size: {
                    random: true,
                    value: 5,
                    },
                },
                detectRetina: true,
                }}
            />

                <div id="accordion" className="section-content-mobile">

                    <div className="card">
                        <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseDesarrollo" aria-expanded="true" aria-controls="collapseOne">
                                DESARROLLO DE TECNOLOGÍAS ESPECÍFICAS
                            </button>
                        </h2>
                        </div>

                        <div id="collapseDesarrollo" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li><i className="fas fa-code"></i>Software a la medida</li>
                                    <li><i className="fas fa-code"></i>Aplicaciones web</li>
                                    <li> <i className="fas fa-code"></i>Aplicaciones mobile</li>
                                    <li> <i className="fas fa-code"></i>Aplicaciones e-commerce</li>
                                    <li> <i className="fas fa-code"></i>CMS</li>
                                    <li> <i className="fas fa-code"></i>CRM</li>
                                    <li> <i className="fas fa-code"></i>Migraciones</li>
                                    <li> <i className="fas fa-code"></i>Análisis de requerimientos</li>
                                    <li><i className="fas fa-code"></i>Pruebas de conceptos</li>
                                    <li> <i className="fas fa-code"></i>Soporte</li>
                                    <li> <i className="fas fa-code"></i>Mantenimiento</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading2">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseIntegracion" aria-expanded="false" aria-controls="collapseOne">
                            INTEGRACIÓN DE SOLUCIONES DIGITALES
                            </button>
                        </h2>
                        </div>

                        <div id="collapseIntegracion" className="collapse" aria-labelledby="heading2" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li> <i className="fas fa-microchip"></i>Consultoría </li>
                                    <li> <i className="fas fa-microchip"></i>Configuración y parametrización</li>
                                    <li> <i className="fas fa-microchip"></i>Integración y migración</li>
                                    <li> <i className="fas fa-microchip"></i>Gestión del cambio</li>
                                    <li> <i className="fas fa-microchip"></i>Administración y mantenimiento</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <div className="card">
                        <div className="card-header" id="heading3">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseEstrategia" aria-expanded="false" aria-controls="collapseOne">
                            ESTRATEGIA DIGITAL
                            </button>
                        </h2>
                        </div>

                        <div id="collapseEstrategia" className="collapse" aria-labelledby="heading3" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li><i className="fas fa-chess-pawn"></i>Detección de necesidades</li>
                                    <li><i className="fas fa-chess-pawn"></i>Asesoramiento estratégico</li>
                                    <li><i className="fas fa-chess-pawn"></i>Impartición de cultura digital</li>
                                    <li><i className="fas fa-chess-pawn"></i>Consultoría técnica</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>



                    <div className="hexagon-icons col-sm-5" id="desarrollo_info">
                        <div  onMouseEnter={() => this.showComponent('desarrollo')}  className="vertientes-subsection-item"></div>     
                    </div>
               
                </div>

           
            <div className="section-content">

                <div className="hexagon-icons col-sm-3" id="desarrollo_info">


                    <div className="row"   >
                        <div className="vertientes-vertical-bar"></div>

                        <div className="col-sm-6 vertientes-left-column-icon"></div>
                        
                        <div className="col-sm-6 vertientes-right-column-icon">

                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 105 119" onMouseEnter={() => this.showComponent('desarrollo')}  className={` vertientes-subsection-icon hexagon   ${this.state.showDesarrollo ? 'hexagon_active': ''}`} >
                        
                                <g id="Shape">
                                    <g>
                                        <g>
                                            <polygon className="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                            <polygon id="_x3C_HexagonShape_x3E_" className={`st1`}  points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                                52.5,19.41 			"/>
                                        </g>
                                    </g>
                                </g>
                                <g id="Icon">
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <path className="st2" d="M47.01,86.19h-2.51c-0.22,0-0.4-0.16-0.43-0.37l-0.68-4.53c-0.72-0.13-1.42-0.31-2.11-0.56l-2.86,3.58
                                                    c-0.14,0.17-0.37,0.21-0.56,0.11l-2.18-1.25c-0.19-0.11-0.27-0.34-0.19-0.54l1.68-4.27c-0.56-0.47-1.07-0.99-1.54-1.54
                                                    l-4.27,1.67c-0.2,0.08-0.43,0-0.54-0.19l-1.26-2.18c-0.11-0.19-0.06-0.43,0.11-0.56l3.59-2.85c-0.24-0.68-0.43-1.38-0.56-2.1
                                                    l-4.53-0.68c-0.21-0.03-0.37-0.22-0.37-0.43v-2.52c0-0.22,0.16-0.4,0.37-0.43l4.53-0.68c0.13-0.72,0.32-1.43,0.56-2.1
                                                    l-3.59-2.86c-0.17-0.14-0.21-0.37-0.11-0.56l1.26-2.18c0.11-0.19,0.34-0.27,0.54-0.19l4.27,1.68c0.47-0.56,0.99-1.07,1.54-1.54
                                                    l-1.68-4.27c-0.08-0.2,0-0.43,0.19-0.54l2.18-1.26c0.19-0.11,0.43-0.06,0.56,0.11l2.85,3.59c0.68-0.25,1.39-0.43,2.11-0.57
                                                    l0.68-4.53c0.03-0.21,0.22-0.37,0.43-0.37h2.51c0.22,0,0.4,0.16,0.43,0.37l0.68,4.53c0.72,0.13,1.43,0.32,2.11,0.57l2.85-3.59
                                                    c0.14-0.17,0.38-0.22,0.56-0.11l2.18,1.26c0.19,0.11,0.27,0.34,0.19,0.54l-1.67,4.27c0.56,0.47,1.08,0.99,1.54,1.54l4.27-1.68
                                                    c0.2-0.08,0.43,0,0.54,0.19l1.25,2.18c0.11,0.19,0.06,0.43-0.11,0.56l-3.58,2.86c0.25,0.7,0.44,1.4,0.57,2.1l4.52,0.68
                                                    c0.21,0.03,0.37,0.22,0.37,0.43v2.51c0,0.22-0.16,0.4-0.37,0.43l-4.53,0.68c-0.13,0.71-0.32,1.41-0.57,2.1l3.58,2.85
                                                    c0.17,0.14,0.21,0.37,0.11,0.56l-1.25,2.18c-0.11,0.19-0.34,0.27-0.54,0.19l-4.27-1.67c-0.46,0.54-0.98,1.06-1.54,1.54
                                                    l1.67,4.27c0.08,0.2,0,0.43-0.19,0.54l-2.18,1.25c-0.19,0.11-0.43,0.06-0.56-0.11l-2.85-3.58c-0.68,0.25-1.39,0.43-2.11,0.56
                                                    l-0.68,4.53C47.41,86.03,47.22,86.19,47.01,86.19z M44.87,85.31h1.76l0.67-4.47c0.03-0.19,0.18-0.34,0.37-0.37
                                                    c0.87-0.13,1.72-0.36,2.54-0.68c0.18-0.07,0.38-0.01,0.5,0.14l2.81,3.53l1.52-0.88l-1.65-4.21c-0.07-0.18-0.02-0.38,0.13-0.5
                                                    c0.7-0.57,1.33-1.2,1.86-1.86c0.12-0.15,0.32-0.2,0.5-0.13l4.21,1.65L60.99,76l-3.53-2.81c-0.15-0.12-0.2-0.32-0.14-0.5
                                                    c0.32-0.84,0.55-1.69,0.68-2.55c0.03-0.19,0.18-0.34,0.37-0.37l4.47-0.67v-1.76l-4.47-0.67c-0.19-0.03-0.34-0.18-0.37-0.37
                                                    c-0.13-0.85-0.36-1.71-0.68-2.55c-0.07-0.18-0.01-0.38,0.14-0.5l3.53-2.82l-0.88-1.52l-4.21,1.66
                                                    c-0.18,0.07-0.38,0.02-0.5-0.13c-0.54-0.68-1.17-1.3-1.86-1.86c-0.15-0.12-0.2-0.32-0.13-0.5l1.65-4.21l-1.52-0.88l-2.81,3.54
                                                    c-0.12,0.15-0.32,0.21-0.5,0.14c-0.81-0.32-1.67-0.55-2.55-0.68c-0.19-0.03-0.34-0.18-0.37-0.37l-0.67-4.47h-1.76l-0.67,4.47
                                                    c-0.03,0.19-0.18,0.34-0.37,0.37c-0.88,0.14-1.73,0.37-2.55,0.68c-0.18,0.07-0.38,0.02-0.5-0.14l-2.82-3.54l-1.52,0.88
                                                    l1.66,4.21c0.07,0.18,0.02,0.38-0.14,0.5c-0.68,0.54-1.31,1.17-1.86,1.86c-0.12,0.15-0.32,0.21-0.5,0.13l-4.21-1.66l-0.88,1.52
                                                    l3.54,2.82c0.15,0.12,0.2,0.32,0.14,0.5c-0.31,0.81-0.54,1.67-0.68,2.55c-0.03,0.19-0.18,0.34-0.37,0.36l-4.47,0.67v1.77
                                                    l4.47,0.67c0.19,0.03,0.34,0.18,0.37,0.37c0.14,0.88,0.37,1.74,0.68,2.55c0.07,0.18,0.02,0.38-0.14,0.5L30.51,76l0.88,1.52
                                                    l4.21-1.65c0.18-0.07,0.38-0.02,0.5,0.13c0.55,0.68,1.17,1.31,1.86,1.86c0.15,0.12,0.2,0.32,0.13,0.5l-1.66,4.21l1.52,0.88
                                                    l2.82-3.53c0.12-0.15,0.32-0.2,0.5-0.14c0.82,0.32,1.67,0.55,2.54,0.68c0.19,0.03,0.34,0.18,0.37,0.37L44.87,85.31z
                                                    M45.75,75.1c-3.79,0-6.87-3.08-6.87-6.88c0-3.79,3.08-6.87,6.87-6.87c3.79,0,6.87,3.08,6.87,6.87
                                                    C52.62,72.01,49.54,75.1,45.75,75.1z M45.75,62.23c-3.3,0-5.99,2.69-5.99,5.99c0,3.31,2.69,6,5.99,6c3.31,0,6-2.69,6-6
                                                    C51.75,64.92,49.06,62.23,45.75,62.23z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <path className="st2" d="M45.75,59.61c-0.6,0-1.1-0.49-1.1-1.1c0-0.6,0.49-1.1,1.1-1.1c0.6,0,1.1,0.49,1.1,1.1
                                                C46.84,59.12,46.35,59.61,45.75,59.61z M45.75,58.3c-0.12,0-0.22,0.1-0.22,0.22c0,0.12,0.1,0.22,0.22,0.22
                                                c0.12,0,0.22-0.1,0.22-0.22C45.97,58.39,45.87,58.3,45.75,58.3z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M50.6,60.91c-0.19,0-0.38-0.05-0.55-0.15c-0.25-0.15-0.43-0.38-0.51-0.67c-0.07-0.28-0.03-0.58,0.11-0.83
                                                c0.29-0.5,0.99-0.69,1.49-0.39c0.25,0.14,0.43,0.37,0.51,0.66c0.08,0.28,0.04,0.58-0.11,0.84C51.36,60.7,50.99,60.91,50.6,60.91
                                                z M50.6,59.6c-0.08,0-0.15,0.04-0.19,0.11c-0.03,0.05-0.04,0.11-0.02,0.17c0.01,0.03,0.03,0.09,0.1,0.13
                                                c0.1,0.06,0.24,0.02,0.3-0.08c0.04-0.07,0.03-0.13,0.02-0.16c-0.01-0.03-0.03-0.09-0.1-0.13C50.68,59.61,50.64,59.6,50.6,59.6z"
                                                />
                                        </g>
                                        <g>
                                            <path className="st2" d="M54.16,64.47c-0.39,0-0.76-0.21-0.95-0.56c-0.14-0.25-0.18-0.55-0.11-0.83c0.08-0.28,0.26-0.52,0.51-0.66
                                                c0.5-0.29,1.21-0.1,1.5,0.4c0.15,0.25,0.19,0.54,0.11,0.83c-0.08,0.28-0.26,0.52-0.51,0.67C54.54,64.41,54.35,64.47,54.16,64.47
                                                z M54.16,63.15c-0.04,0-0.08,0.01-0.11,0.03c-0.07,0.04-0.09,0.1-0.1,0.13c-0.01,0.03-0.02,0.1,0.02,0.17
                                                c0.08,0.14,0.21,0.13,0.29,0.08c0.05-0.03,0.09-0.08,0.11-0.14c0.01-0.03,0.02-0.1-0.02-0.16
                                                C54.31,63.19,54.24,63.15,54.16,63.15z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M55.46,69.32c-0.6,0-1.1-0.49-1.1-1.1c0-0.6,0.49-1.1,1.1-1.1c0.6,0,1.1,0.49,1.1,1.1
                                                C56.55,68.83,56.06,69.32,55.46,69.32z M55.46,68c-0.12,0-0.22,0.1-0.22,0.22c0,0.12,0.1,0.22,0.22,0.22
                                                c0.12,0,0.22-0.1,0.22-0.22C55.67,68.1,55.58,68,55.46,68z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M54.15,74.17c-0.19,0-0.38-0.05-0.55-0.15c-0.52-0.3-0.7-0.98-0.4-1.5c0.28-0.5,0.98-0.69,1.49-0.4
                                                c0.25,0.14,0.43,0.38,0.51,0.66c0.08,0.28,0.04,0.58-0.11,0.83C54.91,73.96,54.55,74.17,54.15,74.17z M54.15,72.86
                                                c-0.08,0-0.14,0.04-0.18,0.1c-0.06,0.11-0.03,0.25,0.08,0.31c0.1,0.06,0.24,0.02,0.3-0.08c0.04-0.07,0.03-0.13,0.02-0.17
                                                c-0.01-0.03-0.03-0.09-0.1-0.13C54.23,72.87,54.19,72.86,54.15,72.86z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M50.61,77.73c-0.39,0-0.75-0.21-0.95-0.55c-0.3-0.52-0.13-1.19,0.4-1.49c0.51-0.3,1.21-0.11,1.5,0.4
                                                c0.15,0.25,0.19,0.55,0.11,0.83c-0.08,0.28-0.26,0.52-0.51,0.67C50.98,77.68,50.8,77.73,50.61,77.73z M50.6,76.41
                                                c-0.04,0-0.08,0.01-0.11,0.03c-0.1,0.06-0.14,0.19-0.08,0.3c0.06,0.1,0.19,0.14,0.3,0.08c0.05-0.03,0.09-0.08,0.11-0.14
                                                c0.01-0.03,0.02-0.1-0.02-0.16C50.75,76.45,50.68,76.41,50.6,76.41z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M45.75,79.03c-0.6,0-1.1-0.49-1.1-1.1c0-0.6,0.49-1.1,1.1-1.1c0.6,0,1.1,0.49,1.1,1.1
                                                C46.84,78.53,46.35,79.03,45.75,79.03z M45.75,77.71c-0.12,0-0.22,0.1-0.22,0.22c0,0.12,0.1,0.22,0.22,0.22
                                                c0.12,0,0.22-0.1,0.22-0.22C45.97,77.81,45.87,77.71,45.75,77.71z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M40.89,77.73c-0.19,0-0.38-0.05-0.55-0.15c-0.25-0.15-0.43-0.38-0.51-0.67c-0.08-0.28-0.04-0.58,0.11-0.84
                                                c0.29-0.5,0.99-0.69,1.49-0.4c0.25,0.15,0.44,0.38,0.51,0.67c0.07,0.28,0.03,0.57-0.11,0.83
                                                C41.65,77.52,41.28,77.73,40.89,77.73z M40.89,76.41c-0.08,0-0.15,0.04-0.19,0.11c-0.03,0.05-0.04,0.11-0.02,0.17
                                                c0.01,0.03,0.03,0.09,0.1,0.13c0.1,0.06,0.24,0.02,0.3-0.08c0.04-0.06,0.03-0.12,0.02-0.16c-0.02-0.06-0.05-0.11-0.11-0.14
                                                C40.97,76.42,40.93,76.41,40.89,76.41z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M37.35,74.17c-0.39,0-0.75-0.21-0.95-0.55c-0.15-0.26-0.19-0.55-0.11-0.84c0.08-0.28,0.26-0.52,0.51-0.66
                                                c0.49-0.28,1.2-0.1,1.49,0.4c0.3,0.52,0.12,1.2-0.4,1.5C37.73,74.12,37.54,74.17,37.35,74.17z M37.34,72.86
                                                c-0.04,0-0.08,0.01-0.11,0.03c-0.07,0.04-0.09,0.1-0.1,0.13c-0.01,0.03-0.02,0.1,0.02,0.17c0.06,0.11,0.19,0.15,0.3,0.09
                                                c0.11-0.06,0.14-0.2,0.08-0.3C37.49,72.9,37.42,72.86,37.34,72.86z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M36.04,69.32c-0.6,0-1.1-0.49-1.1-1.1c0-0.6,0.49-1.1,1.1-1.1c0.6,0,1.1,0.49,1.1,1.1
                                                C37.14,68.83,36.65,69.32,36.04,69.32z M36.04,68c-0.12,0-0.22,0.1-0.22,0.22c0,0.12,0.1,0.22,0.22,0.22
                                                c0.12,0,0.22-0.1,0.22-0.22C36.26,68.1,36.16,68,36.04,68z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M37.34,64.47c-0.19,0-0.38-0.05-0.55-0.15c-0.25-0.15-0.43-0.38-0.51-0.67c-0.08-0.28-0.04-0.58,0.11-0.83
                                                c0.29-0.5,0.99-0.69,1.49-0.4c0.25,0.14,0.43,0.38,0.51,0.66c0.08,0.28,0.04,0.58-0.11,0.83C38.1,64.25,37.73,64.47,37.34,64.47
                                                z M37.34,63.15c-0.08,0-0.15,0.04-0.19,0.11c-0.03,0.05-0.04,0.11-0.02,0.17c0.01,0.03,0.03,0.09,0.1,0.13
                                                c0.11,0.06,0.24,0.02,0.3-0.08c0.04-0.07,0.03-0.13,0.02-0.17c-0.01-0.03-0.03-0.09-0.1-0.13
                                                C37.42,63.16,37.38,63.15,37.34,63.15z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M40.9,60.91c-0.39,0-0.75-0.21-0.95-0.55c-0.15-0.25-0.19-0.55-0.11-0.84c0.08-0.28,0.26-0.52,0.51-0.66
                                                c0.49-0.28,1.2-0.1,1.49,0.4c0.15,0.25,0.19,0.55,0.11,0.83c-0.08,0.28-0.26,0.52-0.51,0.67C41.28,60.86,41.09,60.91,40.9,60.91
                                                z M40.9,59.6c-0.04,0-0.08,0.01-0.11,0.03c-0.07,0.04-0.09,0.09-0.1,0.13c-0.01,0.03-0.02,0.1,0.02,0.17
                                                c0.06,0.11,0.2,0.14,0.29,0.09c0.05-0.03,0.09-0.08,0.11-0.14c0.01-0.03,0.02-0.1-0.02-0.16C41.05,59.64,40.98,59.6,40.9,59.6z"
                                                />
                                        </g>
                                        <g>
                                            <g>
                                                <path className="st2" d="M66.15,56.59h-1.65c-0.22,0-0.4-0.16-0.43-0.37l-0.43-2.85c-0.4-0.08-0.8-0.18-1.19-0.32l-1.79,2.26
                                                    c-0.14,0.17-0.37,0.22-0.56,0.11l-1.43-0.82c-0.19-0.11-0.27-0.34-0.19-0.54l1.06-2.68c-0.3-0.26-0.59-0.56-0.87-0.87
                                                    l-2.69,1.06c-0.2,0.08-0.43,0-0.54-0.19l-0.82-1.43c-0.11-0.19-0.06-0.43,0.11-0.56l2.25-1.8c-0.13-0.38-0.24-0.78-0.32-1.19
                                                    l-2.85-0.43c-0.21-0.03-0.37-0.22-0.37-0.43v-1.64c0-0.22,0.16-0.4,0.37-0.43l2.85-0.43c0.08-0.41,0.19-0.81,0.32-1.19
                                                    l-2.25-1.8c-0.17-0.13-0.21-0.37-0.11-0.56l0.82-1.42c0.11-0.19,0.34-0.27,0.54-0.19l2.69,1.06c0.27-0.31,0.56-0.61,0.87-0.87
                                                    l-1.06-2.68c-0.08-0.2,0-0.43,0.19-0.54l1.43-0.82c0.19-0.11,0.43-0.06,0.56,0.11l1.79,2.26c0.39-0.13,0.78-0.24,1.19-0.32
                                                    l0.43-2.85c0.03-0.21,0.22-0.37,0.43-0.37h1.65c0.22,0,0.4,0.16,0.43,0.37l0.43,2.85c0.4,0.08,0.8,0.18,1.19,0.32L70,34.1
                                                    c0.14-0.17,0.37-0.21,0.56-0.11l1.42,0.82c0.19,0.11,0.27,0.34,0.19,0.54l-1.06,2.68c0.31,0.27,0.6,0.56,0.87,0.87l2.69-1.06
                                                    c0.2-0.08,0.43,0,0.54,0.19l0.82,1.42c0.11,0.19,0.06,0.43-0.11,0.56l-2.25,1.8c0.13,0.38,0.24,0.78,0.32,1.19l2.85,0.43
                                                    c0.21,0.03,0.37,0.22,0.37,0.43v1.64c0,0.22-0.16,0.4-0.37,0.43l-2.85,0.43c-0.08,0.41-0.19,0.81-0.32,1.19l2.26,1.8
                                                    c0.17,0.14,0.21,0.37,0.11,0.56l-0.82,1.42c-0.11,0.19-0.34,0.27-0.54,0.19l-2.69-1.05c-0.27,0.31-0.56,0.6-0.87,0.87
                                                    l1.06,2.68c0.08,0.2,0,0.43-0.19,0.54l-1.42,0.82c-0.19,0.11-0.43,0.06-0.56-0.11l-1.8-2.26c-0.39,0.13-0.79,0.24-1.19,0.32
                                                    l-0.43,2.85C66.55,56.44,66.37,56.59,66.15,56.59z M64.88,55.72h0.89l0.42-2.79c0.03-0.19,0.18-0.34,0.36-0.37
                                                    c0.55-0.09,1.11-0.23,1.64-0.44c0.18-0.07,0.38-0.01,0.5,0.14l1.76,2.21l0.77-0.44l-1.03-2.63c-0.07-0.18-0.02-0.38,0.13-0.5
                                                    c0.42-0.35,0.83-0.75,1.2-1.2c0.12-0.15,0.32-0.2,0.5-0.13l2.63,1.03l0.44-0.77l-2.21-1.76c-0.15-0.12-0.21-0.32-0.13-0.5
                                                    c0.2-0.51,0.35-1.06,0.44-1.63c0.03-0.19,0.18-0.34,0.37-0.36l2.79-0.42v-0.89l-2.79-0.42c-0.19-0.03-0.34-0.18-0.37-0.37
                                                    c-0.09-0.58-0.24-1.12-0.44-1.63c-0.07-0.18-0.02-0.38,0.13-0.5l2.21-1.76l-0.44-0.77l-2.63,1.03
                                                    c-0.18,0.07-0.38,0.02-0.5-0.13c-0.36-0.45-0.77-0.85-1.19-1.2c-0.15-0.12-0.2-0.32-0.13-0.5l1.03-2.63l-0.77-0.44l-1.76,2.21
                                                    c-0.12,0.15-0.32,0.2-0.5,0.14c-0.53-0.2-1.08-0.35-1.64-0.44c-0.19-0.03-0.34-0.18-0.36-0.37l-0.42-2.79h-0.89l-0.42,2.79
                                                    c-0.03,0.19-0.18,0.34-0.36,0.37c-0.56,0.09-1.11,0.24-1.63,0.44c-0.18,0.07-0.38,0.01-0.5-0.14l-1.75-2.21l-0.77,0.45
                                                    l1.03,2.63c0.07,0.18,0.02,0.38-0.13,0.5c-0.43,0.35-0.83,0.75-1.19,1.2c-0.12,0.15-0.32,0.2-0.5,0.13l-2.63-1.03l-0.44,0.77
                                                    l2.21,1.76c0.15,0.12,0.2,0.32,0.14,0.5c-0.2,0.51-0.35,1.06-0.44,1.63c-0.03,0.19-0.18,0.34-0.37,0.37l-2.79,0.42v0.89
                                                    l2.79,0.42c0.19,0.03,0.34,0.18,0.37,0.36c0.09,0.57,0.24,1.12,0.44,1.63c0.07,0.18,0.02,0.38-0.14,0.5l-2.21,1.76l0.44,0.77
                                                    l2.63-1.03c0.18-0.07,0.38-0.02,0.5,0.13c0.37,0.45,0.77,0.85,1.2,1.2c0.15,0.12,0.2,0.32,0.13,0.5l-1.03,2.63l0.77,0.45
                                                    l1.75-2.21c0.12-0.15,0.32-0.2,0.5-0.14c0.53,0.2,1.08,0.35,1.64,0.44c0.19,0.03,0.34,0.18,0.36,0.37L64.88,55.72z
                                                    M65.33,49.34c-2.56,0-4.64-2.08-4.64-4.64c0-2.56,2.08-4.64,4.64-4.64c2.56,0,4.64,2.08,4.64,4.64
                                                    C69.97,47.26,67.89,49.34,65.33,49.34z M65.33,40.94c-2.08,0-3.76,1.69-3.76,3.77c0,2.07,1.69,3.76,3.76,3.76
                                                    c2.08,0,3.77-1.69,3.77-3.76C69.09,42.63,67.41,40.94,65.33,40.94z"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            </svg>
                            
                        
                            <svg version="1.1" onMouseEnter={() => this.showComponent('integracion')}    xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" className={` vertientes-subsection-icon hexagon   ${this.state.showIntegracion ? 'hexagon_active': ''}`}
                                        viewBox="0 0 103 118">

                                    <polyline className="st0" points="51.5,114.1 99.2,86.6 99.2,31.4 51.5,3.9 3.8,31.4 3.8,86.6 51.5,114.1 "/>
                                    <polygon id="_x3C_HexagonShape_x3E_" className={` st1`}  points="16.8,39 16.8,79 51.5,99.1 86.2,79 86.2,39 51.5,18.9 "/>
                                    <g id="_x3C_icon_x3E_">
                                        <g>
                                            <g>
                                                <path className="st2" d="M69.8,67.9H33.2c-0.2,0-0.4-0.2-0.4-0.4V46.1c0-0.2,0.2-0.4,0.4-0.4h36.6c0.2,0,0.4,0.2,0.4,0.4v21.4
                                                    C70.2,67.7,70,67.9,69.8,67.9z M33.5,67.2h35.9V46.4H33.5V67.2z"/>
                                            </g>
                                            <g>
                                                <path className="st2" d="M72.9,46.4H30.1c-0.2,0-0.4-0.2-0.4-0.4V43c0-0.2,0.2-0.4,0.4-0.4h42.9c0.2,0,0.4,0.2,0.4,0.4v3.1
                                                    C73.3,46.3,73.1,46.4,72.9,46.4z M30.4,45.7h42.2v-2.4H30.4V45.7z"/>
                                            </g>
                                            <g>
                                                <path className="st2" d="M59.6,78.9c-0.1,0-0.2,0-0.3-0.1L53.5,73h-3.9l-5.9,5.9c-0.1,0.1-0.4,0.1-0.5,0l-1.1-1.1
                                                    c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3l5.7-5.7v-4c0-0.2,0.2-0.4,0.4-0.4h6.6c0.2,0,0.4,0.2,0.4,0.4v3.8l5.9,5.9
                                                    c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3l-1.1,1.1C59.8,78.9,59.7,78.9,59.6,78.9z M42.9,77.4l0.6,0.6l5.7-5.7
                                                    c0.1-0.1,0.2-0.1,0.3-0.1h4.2c0.1,0,0.2,0,0.3,0.1l5.7,5.7l0.6-0.6l-5.7-5.7c-0.1-0.1-0.1-0.2-0.1-0.3v-3.6h-5.9v3.7
                                                    c0,0.1,0,0.2-0.1,0.3L42.9,77.4z"/>
                                            </g>
                                            <g>
                                                <path className="st2" d="M61.2,64.6h-2.6c-0.2,0-0.4-0.2-0.4-0.4V51.2c0-0.2,0.2-0.4,0.4-0.4h2.6c0.2,0,0.4,0.2,0.4,0.4v13.1
                                                    C61.6,64.4,61.4,64.6,61.2,64.6z M59,63.9h1.9V51.5H59V63.9z"/>
                                            </g>
                                            <g>
                                                <path className="st2" d="M57,64.6h-2.6c-0.2,0-0.4-0.2-0.4-0.4V52.8c0-0.2,0.2-0.4,0.4-0.4H57c0.2,0,0.4,0.2,0.4,0.4v11.4
                                                    C57.4,64.4,57.2,64.6,57,64.6z M54.8,63.9h1.9V53.2h-1.9V63.9z"/>
                                            </g>
                                            <g>
                                                <path className="st2" d="M52.8,64.6h-2.6c-0.2,0-0.4-0.2-0.4-0.4v-9.9c0-0.2,0.2-0.4,0.4-0.4h2.6c0.2,0,0.4,0.2,0.4,0.4v9.9
                                                    C53.1,64.4,53,64.6,52.8,64.6z M50.6,63.9h1.9v-9.1h-1.9V63.9z"/>
                                            </g>
                                            <g>
                                                <path className="st2" d="M48.6,64.6H46c-0.2,0-0.4-0.2-0.4-0.4v-7.7c0-0.2,0.2-0.4,0.4-0.4h2.6c0.2,0,0.4,0.2,0.4,0.4v7.7
                                                    C48.9,64.4,48.8,64.6,48.6,64.6z M46.4,63.9h1.9v-7h-1.9V63.9z"/>
                                            </g>
                                            <g>
                                                <path className="st2" d="M44.4,64.6h-2.6c-0.2,0-0.4-0.2-0.4-0.4v-5.4c0-0.2,0.2-0.4,0.4-0.4h2.6c0.2,0,0.4,0.2,0.4,0.4v5.4
                                                    C44.7,64.4,44.6,64.6,44.4,64.6z M42.2,63.9H44v-4.7h-1.9V63.9z"/>
                                            </g>
                                            <g>
                                                <path className="st2" d="M62.9,66.2H40c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h22.9c0.2,0,0.4,0.2,0.4,0.4S63.1,66.2,62.9,66.2z"/>
                                            </g>
                                            <g>
                                                <path className="st2" d="M41.8,56.9c-0.1,0-0.3-0.1-0.3-0.2c-0.1-0.2,0-0.4,0.2-0.5c0.1,0,9.7-3.5,13.9-8.4c0.1-0.1,0.4-0.2,0.5,0
                                                    c0.2,0.1,0.2,0.4,0,0.5c-4.3,5-13.8,8.5-14.2,8.6C41.9,56.9,41.8,56.9,41.8,56.9z"/>
                                            </g>
                                            <g>
                                                <path className="st2" d="M55.8,50.6c-0.2,0-0.4-0.2-0.4-0.4v-1.8l-1.7,0c0,0,0,0,0,0c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4
                                                    l2,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v2.1C56.1,50.4,56,50.6,55.8,50.6z"/>
                                            </g>
                                            <g>
                                                <path className="st2" d="M52.9,43.4h-2.8c-0.2,0-0.4-0.2-0.4-0.4v-2.2c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8V43
                                                    C53.3,43.2,53.1,43.4,52.9,43.4z M50.5,42.6h2.1v-1.8c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1V42.6z"/>
                                            </g>
                                        </g>
                                    </g>
                                    </svg> 

                        
                            <svg version="1.1"  onMouseEnter={() => this.showComponent('estrategia')} className={` vertientes-subsection-icon hexagon   ${this.state.showEstrategia ? 'hexagon_active': ''}`}  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 105 119" >

                            <g id="Shape">
                                <g>
                                    <g>
                                        <polygon className="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                        <polygon id="_x3C_HexagonShape_x3E_" className={` st1`}  points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                            52.5,19.41 			"/>
                                    </g>
                                </g>
                            </g>
                            <g id="Icon">
                                <g>
                                    <g>
                                        <g>
                                            <path className="st2" d="M60.55,76.06H45.61c-0.23,0-0.41-0.18-0.41-0.41v-9.82c-3.55-2.55-5.66-6.65-5.66-11.01
                                                c0-7.47,6.08-13.55,13.54-13.55c7.47,0,13.54,6.08,13.54,13.55c0,4.36-2.11,8.46-5.66,11.01v9.82
                                                C60.96,75.87,60.78,76.06,60.55,76.06z M46.02,75.24h14.13v-9.62c0-0.13,0.07-0.26,0.18-0.33c3.44-2.39,5.49-6.3,5.49-10.47
                                                c0-7.02-5.71-12.73-12.73-12.73c-7.02,0-12.73,5.71-12.73,12.73c0,4.17,2.05,8.08,5.48,10.47c0.11,0.08,0.18,0.2,0.18,0.33
                                                V75.24z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M52.29,39.97c-0.23,0-0.41-0.18-0.41-0.41v-4.45c0-0.23,0.18-0.41,0.41-0.41c0.23,0,0.41,0.18,0.41,0.41
                                                v4.45C52.69,39.78,52.51,39.97,52.29,39.97z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M57.61,40.47c-0.04,0-0.07,0-0.11-0.01c-0.22-0.06-0.35-0.28-0.29-0.5l1.15-4.3
                                                c0.06-0.22,0.29-0.34,0.5-0.29c0.22,0.06,0.35,0.28,0.29,0.5l-1.15,4.3C57.96,40.35,57.79,40.47,57.61,40.47z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M62.63,42.35c-0.07,0-0.14-0.02-0.2-0.05c-0.2-0.11-0.26-0.36-0.15-0.56l2.22-3.86
                                                c0.11-0.2,0.36-0.26,0.56-0.15c0.19,0.11,0.26,0.36,0.15,0.56l-2.22,3.86C62.91,42.27,62.77,42.35,62.63,42.35z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M66.99,45.45c-0.1,0-0.21-0.04-0.29-0.12c-0.16-0.16-0.16-0.42,0-0.58l3.15-3.15
                                                c0.16-0.16,0.42-0.16,0.58,0s0.16,0.42,0,0.58l-3.15,3.15C67.2,45.41,67.1,45.45,66.99,45.45z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M38,46.49c-0.1,0-0.21-0.04-0.29-0.12l-3.14-3.15c-0.16-0.16-0.16-0.42,0-0.58c0.16-0.16,0.42-0.16,0.58,0
                                                l3.14,3.15c0.16,0.16,0.16,0.42,0,0.58C38.21,46.45,38.11,46.49,38,46.49z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M42.14,43.08c-0.14,0-0.28-0.07-0.35-0.2l-2.23-3.85c-0.11-0.19-0.05-0.44,0.15-0.56
                                                c0.2-0.11,0.44-0.05,0.56,0.15l2.23,3.85c0.11,0.19,0.05,0.44-0.15,0.56C42.27,43.06,42.2,43.08,42.14,43.08z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M47.01,40.85c-0.18,0-0.34-0.12-0.39-0.3l-1.15-4.3c-0.06-0.22,0.07-0.44,0.29-0.5
                                                c0.22-0.06,0.44,0.07,0.5,0.29l1.15,4.3c0.06,0.22-0.07,0.44-0.29,0.5C47.08,40.85,47.04,40.85,47.01,40.85z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M44.51,61.62c-0.14,0-0.28-0.07-0.35-0.2l-0.75-1.29c-0.1-0.17-0.06-0.4,0.1-0.52l2.03-1.62
                                                c-0.12-0.34-0.21-0.7-0.28-1.06l-2.57-0.39c-0.2-0.03-0.35-0.2-0.35-0.4v-1.48c0-0.2,0.15-0.37,0.35-0.4l2.57-0.38
                                                c0.07-0.37,0.17-0.72,0.28-1.06l-2.03-1.62c-0.16-0.13-0.2-0.35-0.1-0.52l0.75-1.29c0.1-0.17,0.31-0.25,0.5-0.17l2.41,0.95
                                                c0.23-0.27,0.49-0.53,0.78-0.77l-0.95-2.41c-0.07-0.19,0-0.4,0.18-0.5l1.28-0.74c0.17-0.1,0.4-0.06,0.52,0.1l1.61,2.03
                                                c0.34-0.12,0.69-0.21,1.06-0.28l0.38-2.57c0.03-0.2,0.2-0.35,0.4-0.35h1.49c0.2,0,0.37,0.15,0.4,0.35l0.39,2.57
                                                c0.37,0.07,0.72,0.17,1.06,0.28l1.61-2.03c0.13-0.16,0.35-0.2,0.52-0.1l1.29,0.74c0.17,0.1,0.25,0.31,0.18,0.5l-0.95,2.41
                                                c0.28,0.24,0.54,0.5,0.78,0.77l2.42-0.95c0.19-0.07,0.4,0,0.5,0.18l0.74,1.29c0.1,0.17,0.06,0.4-0.1,0.52l-2.03,1.61
                                                c0.12,0.35,0.21,0.7,0.28,1.06l2.56,0.38c0.2,0.03,0.35,0.2,0.35,0.4v1.48c0,0.2-0.15,0.37-0.35,0.4l-2.56,0.39
                                                c-0.07,0.35-0.16,0.71-0.28,1.06l2.03,1.62c0.16,0.13,0.2,0.35,0.1,0.52l-0.74,1.29c-0.1,0.18-0.31,0.25-0.5,0.18l-2.69-1.06
                                                c-0.21-0.08-0.31-0.32-0.23-0.53c0.08-0.21,0.32-0.31,0.53-0.23l2.37,0.93l0.39-0.68l-1.98-1.58c-0.14-0.11-0.19-0.3-0.13-0.47
                                                c0.19-0.49,0.32-0.98,0.39-1.47c0.03-0.18,0.17-0.32,0.34-0.34L63,55.79v-0.78l-2.51-0.38c-0.18-0.03-0.32-0.17-0.34-0.34
                                                c-0.07-0.49-0.2-0.99-0.39-1.47c-0.06-0.17-0.01-0.36,0.13-0.47l1.98-1.58l-0.39-0.68l-2.37,0.93
                                                c-0.17,0.07-0.36,0.01-0.47-0.12c-0.31-0.38-0.67-0.74-1.08-1.08c-0.14-0.11-0.19-0.3-0.12-0.47L58.38,47l-0.68-0.39l-1.58,1.98
                                                c-0.11,0.14-0.3,0.19-0.47,0.13c-0.46-0.18-0.95-0.31-1.47-0.39c-0.18-0.03-0.31-0.17-0.34-0.34l-0.38-2.51h-0.78l-0.38,2.51
                                                c-0.03,0.18-0.16,0.31-0.34,0.34c-0.52,0.08-1.01,0.21-1.46,0.39c-0.17,0.07-0.36,0.02-0.47-0.12l-1.58-1.98L47.79,47l0.93,2.36
                                                c0.06,0.17,0.02,0.35-0.12,0.47c-0.41,0.33-0.77,0.69-1.08,1.07c-0.11,0.14-0.3,0.19-0.47,0.12l-2.36-0.93l-0.39,0.68l1.98,1.58
                                                c0.14,0.11,0.19,0.3,0.13,0.47c-0.18,0.46-0.31,0.95-0.39,1.47c-0.03,0.18-0.17,0.31-0.34,0.34l-2.51,0.38v0.78l2.51,0.38
                                                c0.18,0.03,0.31,0.16,0.34,0.34c0.08,0.51,0.21,1.01,0.39,1.47c0.06,0.17,0.01,0.35-0.13,0.47l-1.98,1.58l0.39,0.68l2.36-0.93
                                                c0.21-0.08,0.45,0.02,0.53,0.23c0.08,0.21-0.02,0.45-0.23,0.53l-2.69,1.06C44.61,61.61,44.56,61.62,44.51,61.62z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M62.48,68.38H43.69c-0.23,0-0.41-0.18-0.41-0.41c0-0.23,0.18-0.41,0.41-0.41h18.79
                                                c0.23,0,0.41,0.18,0.41,0.41C62.89,68.2,62.7,68.38,62.48,68.38z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M62.48,71.13H43.69c-0.23,0-0.41-0.18-0.41-0.41c0-0.23,0.18-0.41,0.41-0.41h18.79
                                                c0.23,0,0.41,0.18,0.41,0.41C62.89,70.95,62.7,71.13,62.48,71.13z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M62.48,73.64H43.69c-0.23,0-0.41-0.18-0.41-0.41c0-0.23,0.18-0.41,0.41-0.41h18.79
                                                c0.23,0,0.41,0.18,0.41,0.41C62.89,73.46,62.7,73.64,62.48,73.64z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M57.95,80.47h-9.74c-0.23,0-0.41-0.18-0.41-0.41v-4.42c0-0.23,0.18-0.41,0.41-0.41h9.74
                                                c0.23,0,0.41,0.18,0.41,0.41v4.42C58.36,80.29,58.18,80.47,57.95,80.47z M48.62,79.66h8.92v-3.6h-8.92V79.66z"/>
                                        </g>
                                        <g>
                                            <path className="st2" d="M54.65,84.3h-3.13c-0.23,0-0.41-0.18-0.41-0.41v-3.82c0-0.23,0.18-0.41,0.41-0.41h3.13
                                                c0.23,0,0.41,0.18,0.41,0.41v3.82C55.05,84.12,54.87,84.3,54.65,84.3z M51.93,83.48h2.31v-3.01h-2.31V83.48z"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            </svg>

                        </div>
                        
                    </div>
                </div>
                <div className="section-image d-flex col-sm-9">
                    {showDesarrollo && <Desarrollo/> }
                    {showIntegracion && <IntegracionDeSoluciones/>}
                    {showEstrategia && <EstrategiaDigital/>}
                </div>
            </div>


            
            
        </section>

        );
    }
    componentDidMount(){
        window.scrollTo(0,0);
    }
}
export default SissaDigital_ListDesktop;

