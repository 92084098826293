import React, { Component } from 'react';

import img from '../../../../assets/img/nosotros/sissa-monitoring/automatizacion-bms.png';


class AutomatizacionBMS extends Component {

 
    render() {    

        
    return(        
            <div>
                <img className="img-fluid" alt="Sistema de automatización BMS" src={img} />                
            </div>                       
    );
    }
}

export default AutomatizacionBMS;