import React, { Component } from 'react';
import { Col, Row } from 'antd';
import '../../stylesheets/dashboard/peaje.css';
import logo from '../../assets/img/ixmaki/logo.png';
import logo2 from '../../assets/img/ixmaki/dedo.jpg';
import logo3 from '../../assets/img/ixmaki/biometricos.jpg';
import HeaderHome from '../HeaderHome';
import Footer from '../Dashboard/Footer';
import { Helmet } from 'react-helmet';

class QuienesSomos extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Solución Ixmaki</title>
                    <meta name='viewport' content='width=device-width, initial-scale=1' />
                    <meta name='theme-color' content='#000000' />
                    <meta property='og:title' content='Solución Ixmaki' />
                    <meta property='og:type' content='article' />
                    <meta property='og:url' content='https://www.sissamx.com.mx/Ixmaki' />
                    <meta name='description' content='Solución Ixmaki. Solución de manejo de identidad.' />
                </Helmet>
                <HeaderHome />
                <br />
                <br />
                <br />
                <br />
                <h1 className='tituloPagina'>IXMAKI: Sistema de Gestión de Identidad</h1>
                <br />
                <Row>
                    <Col xs={{ span: 19, offset: 2 }} lg={{ span: 6, offset: 1 }}>
                        <img alt='QuienesSomos' src={logo} className='logo-quienes2' />
                    </Col>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 15, offset: 1 }}>
                        <p className='letrasQuienes2'>En SISSA Digital creamos y desarrollamos IXMAKI, el sistema de gestión de identidad que permite el registro de información general (nombre, edad, sexo, etc.) y las diferentes biometrías humanas (huellas dactilares, rostro 2D y 3D, iris, palmas, cantos, huellas roladas, etc.) para la administración de identidades y el intercambio de datos biométricos.</p>
                    </Col>
                </Row>
                <br />
                <br />
                <Row className=''>
                    <h2 className='tituloQuienes'>Gestión de identidad biométrica</h2>
                    <Col span={22} offset={1}>
                        <p className='letrasQuienes2'>Esta plataforma es compatible con dispositivos que no requieren de contacto para la identificación de personas:</p>
                        <div className='letrasLista'>
                            <ul>
                                <li>Identificación por huella sin contacto</li>
                                <li>Identificación por rostro (2D y 3D)</li>
                                <li>Identificación por iris (en proceso de integración)</li>
                                <li>Tableros de información</li>
                                <li>Bitácoras de cada paso y cobro de peaje por usuario.</li>
                            </ul>
                        </div>
                        <p className='letrasQuienes2'>Además, es capaz de interactuar con dispositivos biométricos que tienen certificación de agencias de seguridad internacional, y con sistemas de control de acceso robustos, seguros y de vanguardia.</p>
                    </Col>
                </Row>
                <Row className=''>
                    <br />
                    <br />
                    <h2 className='tituloQuienes'>Pase de lista</h2>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 13, offset: 1 }}>
                        <p className='letrasQuienes2'>IXMAKI cuenta con el módulo móvil Pase de lista, utilizado para gestionar la identidad mediante un dispositivo multibiométrico (huella individual, iris, rostro 2D) con sistema operativo Android, el cual, además de ser fácil de usar y transportar, resulta muy útil para realizar la identificación en sitios donde no es posible tener dispositivos fijos ni comunicación directa con el repositorio de la información.</p>
                        <p className='letrasQuienes2'>Con este módulo es posible hacer la identificación de dos maneras:</p>
                        <div className='letrasLista'>
                            <ul>
                                <li>Conexión remota al servidor central</li>
                                <li>De manera local en el dispositivo (para después vaciar la información en el servidor).</li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={{ span: 19, offset: 2 }} lg={{ span: 7, offset: 1 }}>
                        <img alt='QuienesSomos' src={logo3} className='logo-quienes2' />
                    </Col>
                </Row>
                <Row className=''>
                    <br />
                    <h2 className='tituloQuienes'>NIST (National Institute of Standards and Technology)</h2>
                    <br />
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }}>
                        <div className='letrasLista'>
                            <p>Nuestro sistema también cuenta con el módulo NIST, a través del cual es posible generar archivos con un formato estándar internacional, lo que posibilita la interoperabilidad entre organizaciones de seguridad nacionales como internacionales, ideal para infraestructuras críticas de alta seguridad.</p>
                        </div>
                    </Col>
                </Row>
                <Row className=''>
                    <br />
                    <h2 className='tituloQuienes'>Gestión de identidad no-biométrica</h2>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }}>
                        <p className='letrasQuienes2'>Nuestro sistema de gestión de identidad ha incorporado distintos módulos que van más allá del uso de la biometría y buscan optimizar diversos procesos a partir de la información recabada:</p>
                        <div className='letrasLista'>
                            <ul>
                                <li>
                                    <span style={{ fontWeight: 'bold' }}>Digitalización de documentos personales y laborales</span> (para integrarlos a su registro electrónico o digital).
                                </li>
                                <li>
                                    <span style={{ fontWeight: 'bold' }}>Autentificación de documentos de identificación personal</span> (ine, pasaporte, visa), el cual ha dado buenos resultados incluso con identificaciones internacionales de difícil verificación que otros sistemas no han podido verificar.
                                </li>
                                <li>
                                    <span style={{ fontWeight: 'bold' }}>Time Keeping</span>, que administra y gestiona la pre-nómina del personal de cualquier empresa, organización o equipo de trabajo, basado en los registros del sistema de control de acceso (horas y días trabajados, por ejemplo) en conjunto con las políticas de asistencia propias del lugar.
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className=''>
                    <br />
                    <br />
                    <h2 className='tituloQuienes'>IXMAKI en infraestructuras de alta seguridad</h2>
                    <Row>
                        <Col xs={{ span: 16, offset: 3 }} lg={{ span: 8, offset: 1 }}>
                            <img alt='QuienesSomos' src={logo2} className='logo-quienes4' />
                        </Col>
                        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 13, offset: 0 }}>
                            <p className='letrasQuienes2'>Nuestra plataforma integral para el cobro de peaje cuenta con tres perfiles distintos que facilitan las tareas que desempeña cada involucrado en la operación. Dichos perfiles funcionan a partir de interfaces independientes, lo que mejora su usabilidad y permite llevar un adecuado control sobre los procesos.</p>
                            <div className='letrasQuienes2'>
                                <p>Actualmente esta plataforma se encuentra operando con éxito en diversos Centros de Readaptación Social de la República Mexicana, cubriendo todos los requerimientos de funcionalidad y operación correspondientes a tales infraestructuras críticas de alta seguridad, específicamente para controlar y restringir el acceso peatonal de sus instalaciones.</p>
                                <p>No obstante, aunque IXMAKI nació como una solución destinada a cubrir las necesidades de gestión y seguridad de Centros de Readaptación Social, hoy en día este sistema de gestión de identidad es adaptable a cualquier sector y ha sido implementado con éxito en centros educativos, hospitales, corporativos y deportivos.</p>
                            </div>
                        </Col>
                    </Row>
                    <h2 className='tituloQuienes'>Diseño y acondicionamiento de acuerdo con necesidades particulares</h2>
                    <Row>
                        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }}>
                            <p className='letrasQuienes2'>Nuestra plataforma integral para el cobro de peaje cuenta con tres perfiles distintos que facilitan las tareas que desempeña cada involucrado en la operación. Dichos perfiles funcionan a partir de interfaces independientes, lo que mejora su usabilidad y permite llevar un adecuado control sobre los procesos.</p>
                            <div className='letrasQuienes2'>
                                <p>Una de las ventajas más importantes de nuestra plataforma es que, al ser un desarrollo propio de SISSA Digital, nosotros tenemos el control del código fuente, lo que nos permite diseñar y personalizar el sistema según los requerimientos específicos de quien contrate nuestros servicios, ya que no es una solución cerrada (o de caja, como también se les conoce a las que son compatibles únicamente con los productos de su fabricante) e incluye servicio de soporte técnico personalizado.</p>
                            </div>
                        </Col>
                    </Row>
                </Row>
                <Row className=''>
                    <br />
                    <h2 className='tituloQuienes'>SISSA Digital</h2>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }}>
                        <p className='letrasQuienes2'>Nuestras soluciones digitales de innovación buscan optimizar y aportar valor a múltiples procesos en diferentes entornos bajo tres conceptos básicos de la transformación digital: desarrollo de tecnologías específicas, implementación de estrategias digitales e integración de soluciones digitales. Si te interesa obtener más información sobre nuestra plataforma integral para el cobro de peaje, no dudes en contactarnos por cualquiera de nuestros canales de comunicación y con gusto te atenderemos.</p>
                    </Col>
                </Row>
                <br />
                <br />
                <Footer />
            </div>
        );
    }
}

export default QuienesSomos;
