import React, { Component } from 'react';

import img from '../../../../assets/img/nosotros/sissa-infraestructura/proteccion-contra-incendios.png';


class ProteccionIncendio extends Component {

 
    render() {    

        
    return(        
            <div>
                <img className="img-fluid" alt="Sistemas de protección contra incendios" src={img} />                
            </div>                       
    );
    }
}

export default ProteccionIncendio;