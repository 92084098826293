import React, {Component} from 'react';
import Aire from '../sissa-infraestructura/Aire';
import AlarmaDeteccion from '../sissa-infraestructura/AlarmaDeteccion';
import DisenoArquitectonico from '../sissa-infraestructura/DisenoArquitectonico';
import EstructuraHerreria from '../sissa-infraestructura/EstructuraHerreria';
import InstalacionesElectricas from '../sissa-infraestructura/InstalacionesElectricas';
import InstalacionesHidraulicas from '../sissa-infraestructura/InstalacionesHidraulicas';
import InstalacionesSanitarias from '../sissa-infraestructura/InstalacionesSanitarias';
import Mantenimiento from '../sissa-infraestructura/Mantenimiento';
import ProteccionTorentas from '../sissa-infraestructura/ProteccionTormentas';
import ProteccionIncendio from '../sissa-infraestructura/ProteccionIncendio';
import Particles from 'react-tsparticles';




class SissaMonitoring_Contenido extends Component
{

    constructor() {    
        super();    
        this.state = {
          
            showMantenimiento: false,
            showEstructura: false,
            showDisenoArq: false,
            showProteccTormentas: false,
            showProteccionIncendio: false,
            showAlarmaDetecc: false,
            showInstalacionesSanitarias:false,
            showInstalacionesHidraulicas:false,
            showAire:false,
            showInstalacionesElectricas: true 

        };
        this.showComponent = this.showComponent.bind(this);
      }

    

    
      showComponent = (name) => {
        //console.log(name);
        switch (name) {
          case "mantenimiento":
            this.setState({                     
                showMantenimiento: true,
                showEstructura: false,
                showDisenoArq: false,
                showProteccTormentas: false,
                showProteccionIncendio: false,
                showAlarmaDetecc: false,
                showInstalacionesSanitarias:false,
                showInstalacionesHidraulicas:false,
                showAire:false,
                showInstalacionesElectricas: false
                });
            break;
          case "estructura":
            this.setState({
                showMantenimiento: false,
                showEstructura: true,
                showDisenoArq: false,
                showProteccTormentas: false,
                showProteccionIncendio: false,
                showAlarmaDetecc: false,
                showInstalacionesSanitarias:false,
                showInstalacionesHidraulicas:false,
                showAire:false,
                showInstalacionesElectricas: false
                });
            break;
          case "disenoArq":
            this.setState({
                showMantenimiento: false,
                showEstructura: false,
                showDisenoArq: true,
                showProteccTormentas: false,
                showProteccionIncendio: false,
                showAlarmaDetecc: false,
                showInstalacionesSanitarias:false,
                showInstalacionesHidraulicas:false,
                showAire:false,
                showInstalacionesElectricas: false
                });
            break;

            case "proteccTormentas":
            this.setState({                     
                showMantenimiento: false,
                showEstructura: false,
                showDisenoArq: false,
                showProteccTormentas: true,
                showProteccionIncendio: false,
                showAlarmaDetecc: false,
                showInstalacionesSanitarias:false,
                showInstalacionesHidraulicas:false,
                showAire:false,
                showInstalacionesElectricas: false
                });
            break;

            case "alarmaDetecc":
            this.setState({                     
                showMantenimiento: false,
                showEstructura: false,
                showDisenoArq: false,
                showProteccTormentas: false,
                showProteccionIncendio: false,
                showAlarmaDetecc: true,
                showInstalacionesSanitarias:false,
                showInstalacionesHidraulicas:false,
                showAire:false,
                showInstalacionesElectricas: false
                });
            break;

            case "instSanitarias":
            this.setState({                     
                showMantenimiento: false,
                showEstructura: false,
                showDisenoArq: false,
                showProteccTormentas: false,
                showProteccionIncendio: false,
                showAlarmaDetecc: false,
                showInstalacionesSanitarias:true,
                showInstalacionesHidraulicas:false,
                showAire:false,
                showInstalacionesElectricas: false
                });
            break;

            case "instHidraulicas":
            this.setState({                     
                showMantenimiento: false,
                showEstructura: false,
                showDisenoArq: false,
                showProteccTormentas: false,
                showProteccionIncendio: false,
                showAlarmaDetecc: false,
                showInstalacionesSanitarias:false,
                showInstalacionesHidraulicas:true,
                showAire:false,
                showInstalacionesElectricas: false
                });
            break;

            case "aire":
            this.setState({                     
                showMantenimiento: false,
                showEstructura: false,
                showDisenoArq: false,
                showProteccTormentas: false,
                showProteccionIncendio: false,
                showAlarmaDetecc: false,
                showInstalacionesSanitarias:false,
                showInstalacionesHidraulicas:false,
                showAire:true,
                showInstalacionesElectricas: false
                });
            break;

            case "instElectricas":
            this.setState({                     
                showMantenimiento: false,
                showEstructura: false,
                showDisenoArq: false,
                showProteccTormentas: false,
                showProteccionIncendio: false,
                showAlarmaDetecc: false,
                showInstalacionesSanitarias:false,
                showInstalacionesHidraulicas:false,
                showAire:false,
                showInstalacionesElectricas: true
                });
            break;

            case "proteccionIncendio":
            this.setState({                     
                showMantenimiento: false,
                showEstructura: false,
                showDisenoArq: false,
                showProteccTormentas: false,
                showProteccionIncendio: true,
                showAlarmaDetecc: false,
                showInstalacionesSanitarias:false,
                showInstalacionesHidraulicas:false,
                showAire:false,
                showInstalacionesElectricas: false
                });
            break;

          default:
            this.setState({
                                 
                showMantenimiento: true,
                showEstructura: false,
                showDisenoArq: false,
                showProteccTormentas: false,
                showProteccionIncendio: false,
                showAlarmaDetecc: false,
                showInstalacionesSanitarias:false,
                showInstalacionesHidraulicas:false,
                showAire:false,
                showInstalacionesElectricas: false             
            
            });
        }
      }   


    
    render(){
        const { showMantenimiento, showEstructura, showDisenoArq, showProteccTormentas, showProteccionIncendio, showAlarmaDetecc, showInstalacionesSanitarias, showInstalacionesHidraulicas, showAire, showInstalacionesElectricas } = this.state;  

        return(


            
            



            <section className="honeycomb-section container" id="desarrollo-de-tecnologias" data-aos="fade-up">

                <h1 hidden>Soluciones de infraestructura</h1>
                    
            <Particles
                id="tsparticles"
                options={{
                background: {
                    color: {
                    value: "#ffffff",
                    },
                },
                fpsLimit: 60,
                interactivity: {
                    detectsOn: "canvas",
                    events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                    resize: true,
                    },
                    modes: {
                    bubble: {
                        distance: 400,
                        duration: 2,
                        opacity: 0.8,
                        size: 40,
                    },
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                    },
                },
                particles: {
                    color: {
                    value: "#9c9ca2",
                    },
                    links: {
                    color: "#9c9ca2",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                    },
                    collisions: {
                    enable: true,
                    },
                    move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: false,
                    speed: 6,
                    straight: false,
                    },
                    number: {
                    density: {
                        enable: true,
                        value_area: 800,
                    },
                    value: 80,
                    },
                    opacity: {
                    value: 0.5,
                    },
                    shape: {
                    type: "circle",
                    },
                    size: {
                    random: true,
                    value: 5,
                    },
                },
                detectRetina: true,
                }}
            />

                <div id="accordion" className="section-content-mobile">

                    <div className="card">
                        <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseInstElect" aria-expanded="true" aria-controls="collapseOne">
                                INSTALACIONES Y SUBESTACIONES ELÉCTRICAS
                            </button>
                        </h2>
                        </div>

                        <div id="collapseInstElect" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li> <i className="fas fa-bolt"></i>Sistema de iluminación intensificado</li> 
                                    <li> <i className="fas fa-bolt"></i>Plantas de emergencia</li> 
                                    <li> <i className="fas fa-bolt"></i>Subestaciones derivadas</li> 
                                    <li> <i className="fas fa-bolt"></i>Tableros autosoportados</li> 
                                    <li> <i className="fas fa-bolt"></i>Sistemas UPS</li> 
                                    <li> <i className="fas fa-bolt"></i>Mantenimiento a tableros de transferencia</li> 
                                    <li> <i className="fas fa-bolt"></i>Gestorías para cambios de capacidad en subestaciones</li>                  
                                    <li> <i className="fas fa-bolt"></i>Renta de maquinaria y equipo</li>
                                    <li> <i className="fas fa-bolt"></i>Pruebas a los aceites dieléctricos de transformadores</li>
                                    <li> <i className="fas fa-bolt"></i>Instalación de equipo nuevo</li>
                                    <li> <i className="fas fa-bolt"></i>Grúas, tractocamiones, tolvas, canastillas, excavadoras, compactadoras…</li>
                                   
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading2">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseAire" aria-expanded="false" aria-controls="collapseOne">
                                SISTEMAS DE AIRE ACONDICIONADO
                            </button>
                        </h2>
                        </div>

                        <div id="collapseAire" className="collapse" aria-labelledby="heading2" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                               
                                <ul>
                                    <li><i className="fas fa-temperature-low"></i> Sistemas de aire acondicionado de precisión perimetral para site</li>
                                    <li><i className="fas fa-temperature-low"></i> Sistemas de aire de precisión Rack Cooler Inrow para site</li>
                                    <li><i className="fas fa-temperature-low"></i> Sistemas de aire tipo mochila para telecomunicaciones</li>
                                    <li><i className="fas fa-temperature-low"></i> Sistemas de aire de precisión autocontenidos</li>
                                    <li><i className="fas fa-temperature-low"></i> Soluciones para encerramientos de pasillos</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <div className="card">
                        <div className="card-header" id="heading3">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseInstHidraulicas" aria-expanded="false" aria-controls="collapseOne">
                                INSTALACIONES HIDRÁULICAS
                            </button>
                        </h2>
                        </div>

                        <div id="collapseInstHidraulicas" className="collapse" aria-labelledby="heading3" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li><i className="fas fa-tint"></i> Diseño y construcción de infraestructura hidráulica</li>
                                    <li><i className="fas fa-tint"></i> Todo lo relacionado con la conducción y tratamiento de agua</li>
                                    <li><i className="fas fa-tint"></i> Instalaciones de agua fría</li>
                                    <li><i className="fas fa-tint"></i> Instalaciones de agua caliente</li>
                                    <li><i className="fas fa-tint"></i> Construcción y supervisión de obras hidráulicas</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>


                    <div className="card">
                        <div className="card-header" id="heading4">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseInstSanitarias" aria-expanded="false" aria-controls="collapseOne">
                                INSTALACIONES SANITARIAS
                            </button>
                        </h2>
                        </div>

                        <div id="collapseInstSanitarias" className="collapse" aria-labelledby="heading4" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li><i className="fas fa-tint"></i> Acondicionamiento de redes de agua y alcantarillado</li>
                                    <li><i className="fas fa-tint"></i> Instalación de aparatos sanitarios, griferías y accesorios</li>
                                    <li><i className="fas fa-tint"></i> Suministro y equipamiento de cámaras de bombeo y desagües</li>
                                    <li><i className="fas fa-tint"></i> Sistemas de filtración y recirculación para piscinas</li>
                                    <li><i className="fas fa-tint"></i> Instalación de redes de desagüe pluvial e industrial</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>



                    <div className="card">
                        <div className="card-header" id="heading10">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseProteccionIncendios" aria-expanded="false" aria-controls="collapseOne">
                                SISTEMAS DE PROTECCIÓN CONTRA INCENDIOS
                            </button>
                        </h2>
                        </div>

                        <div id="collapseProteccionIncendios" className="collapse" aria-labelledby="heading10" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li><i className="fas fa-fire-extinguisher"></i> Niveles de riesgo en cada escenario</li>
                                    <li><i className="fas fa-fire-extinguisher"></i> Grado de cumplimiento de la normatividad utilizada de referencia</li>
                                    <li><i className="fas fa-fire-extinguisher"></i> Análisis del diseño y del cálculo de las instalaciones</li>
                                    <li><i className="fas fa-fire-extinguisher"></i> Condiciones de montaje</li>
                                    <li><i className="fas fa-fire-extinguisher"></i> Condiciones de mantenimiento y conservación</li>
                                    <li><i className="fas fa-fire-extinguisher"></i> Chapas magnéticas y botones</li>
                                    <li><i className="fas fa-fire-extinguisher"></i> Análisis y verificación de las condiciones de evacuación</li>
                                    <li><i className="fas fa-fire-extinguisher"></i> Pruebas de funcionamiento</li>
                                    <li><i className="fas fa-fire-extinguisher"></i> Presupuesto de acciones correctoras</li>
                                    <li><i className="fas fa-fire-extinguisher"></i> Plan de inversiones</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>


                    <div className="card">
                        <div className="card-header" id="heading5">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseAlarma" aria-expanded="false" aria-controls="collapseOne">
                                ALARMA Y DETECCIÓN
                            </button>
                        </h2>
                        </div>

                        <div id="collapseAlarma" className="collapse" aria-labelledby="heading5" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li> <i className="fas fa-bell"></i> Alarmas, estrobos y campanas</li>
                                    <li> <i className="fas fa-bell"></i> Detección de calor</li>
                                    <li> <i className="fas fa-bell"></i> Detección de humo</li>
                                    <li> <i className="fas fa-bell"></i> Detectores de gases tóxicos y mezclas explosivas</li>
                                    <li> <i className="fas fa-bell"></i> Detectores de humo de haz proyectado</li>
                                    <li> <i className="fas fa-bell"></i> Multisensores</li>
                                    <li> <i className="fas fa-bell"></i> Redes</li>
                                    <li> <i className="fas fa-bell"></i> Sistemas de voceo</li>
                                    <li> <i className="fas fa-bell"></i> Tableros inteligentes y convencionales</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>


                    <div className="card">
                        <div className="card-header" id="heading6">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseProteccTormentas" aria-expanded="false" aria-controls="collapseOne">
                                SISTEMA DE PROTECCIÓN CONTRA TORMENTAS ELÉCTRICAS Y SISTEMA DE TIERRAS
                            </button>
                        </h2>
                        </div>

                        <div id="collapseProteccTormentas" className="collapse" aria-labelledby="heading6" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li> <i className="fas fa-bolt"></i> Cálculo del diseño de la puesta a tierra con base en las normas NOM y NMX</li>
                                    <li> <i className="fas fa-bolt"></i> Sistema de tierras para electrónica, fuerza y pararrayos</li>
                                    <li> <i className="fas fa-bolt"></i> Medición de resistencia, resistividad con equipo especializado</li>
                                    <li> <i className="fas fa-bolt"></i> Informe técnico avalado ante la Secretaría de Energía y la Secretaría del Trabajo y Previsión Social</li>
                                    <li> <i className="fas fa-bolt"></i> Protección integral contra las sobretensiones transitorias y permanentes</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>



                    <div className="card">
                        <div className="card-header" id="heading7">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseDisenoArquitectonico" aria-expanded="false" aria-controls="collapseOne">
                                DISEÑO ARQUITECTÓNICO Y OBRA CIVIL
                            </button>
                        </h2>
                        </div>

                        <div id="collapseDisenoArquitectonico" className="collapse" aria-labelledby="heading7" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li><i className="fas fa-hard-hat"></i> Desarrollo de proyectos arquitectónicos</li>
                                    <li><i className="fas fa-hard-hat"></i> Proyectos en diseño de interiores</li>
                                    <li><i className="fas fa-hard-hat"></i> Instalaciones complementarias</li>
                                    <li><i className="fas fa-hard-hat"></i> Desarrollo de obras nuevas</li>
                                    <li><i className="fas fa-hard-hat"></i> Proyectos de remodelación</li>
                                    <li><i className="fas fa-hard-hat"></i> Gestión y administración de proyectos</li>
                                    <li><i className="fas fa-hard-hat"></i> Planificación y diseño de redes</li>
                                    <li><i className="fas fa-hard-hat"></i> Adquisición, gestoría y permisos</li>
                                    <li><i className="fas fa-hard-hat"></i> Ingeniería de sitio</li>
                                    <li><i className="fas fa-hard-hat"></i> Cálculo estructural</li>
                                    <li> <i className="fas fa-hard-hat"></i>  Ejecución de obra civil (construcción)</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>



                    <div className="card">
                        <div className="card-header" id="heading8">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseEstructura" aria-expanded="false" aria-controls="collapseOne">
                                ESTRUCTURA Y HERRERÍA
                            </button>
                        </h2>
                        </div>

                        <div id="collapseEstructura" className="collapse" aria-labelledby="heading8" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li> <i className="fas fa-compress-arrows-alt"></i> Fabricación de estructuras metálicas</li>
                                    <li> <i className="fas fa-compress-arrows-alt"></i> Montaje de estructuras metálicas</li>
                                    <li> <i className="fas fa-compress-arrows-alt"></i> Herrería general</li>
                                    <li> <i className="fas fa-compress-arrows-alt"></i> Suministro y colocación de lámina</li>
                                    <li> <i className="fas fa-compress-arrows-alt"></i> Suministro y aplicación de pintura industrial</li>
                                    <li> <i className="fas fa-compress-arrows-alt"></i> Cálculo estructural</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>



                    <div className="card">
                        <div className="card-header" id="heading9">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseMantenimiento" aria-expanded="false" aria-controls="collapseOne">
                                MANTENIMIENTO
                            </button>
                        </h2>
                        </div>

                        <div id="collapseMantenimiento" className="collapse" aria-labelledby="heading9" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li> <i className="fas fa-tools"></i> Preventivo</li>
                                    <li> <i className="fas fa-tools"></i> Correctivo</li>
                                    <li> <i className="fas fa-tools"></i> Predictivo</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>


                   
               
                </div>

           
            <div className="section-content">

                <div className="hexagon-icons col-sm-3" id="desarrollo_info">


                <div className="row"   >
                    <div className="vertientes-vertical-bar"></div>

                    <div className="col-sm-6 vertientes-left-column-icon">
                    
                            <svg version="1.1" onMouseEnter={() => this.showComponent('mantenimiento')}  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"  className={` vertientes-subsection-icon hexagon  ${this.state.showMantenimiento ? 'hexagon_active': ''}`}
                                viewBox="0 0 105 119" >
                    
                                <g id="Shape">
                                    <g>
                                        <g>
                                            <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                            <polygon id="_x3C_HexagonShape_x3E_" className={` st1`} points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                                52.5,19.41 			"/>
                                        </g>
                                    </g>
                                </g>
                                <g id="Icon">
                                    <g>
                                        <path class="st2" d="M62.75,58.36H39.01v0.89h23.74c2.05,0,3.72,1.67,3.72,3.72c0,2.05-1.67,3.72-3.72,3.72H35.91
                                            c-2.54,0-4.61,2.07-4.61,4.61c0,2.54,2.07,4.61,4.61,4.61h23.74V75H35.91c-2.05,0-3.72-1.67-3.72-3.72c0-2.05,1.67-3.72,3.72-3.72
                                            h26.84c2.54,0,4.61-2.07,4.61-4.61C67.36,60.42,65.29,58.36,62.75,58.36z"/>
                                        <path class="st2" d="M73.69,73.01L73.69,73.01c0-0.28-0.23-0.51-0.51-0.51h-5.65v1.03h5.65C73.46,73.53,73.69,73.3,73.69,73.01z"
                                            />
                                        <path class="st2" d="M73.69,77.88L73.69,77.88c0-0.28-0.23-0.51-0.51-0.51h-5.65v1.03h5.65C73.46,78.39,73.69,78.16,73.69,77.88z"
                                            />
                                        <path class="st2" d="M65.7,70.4v0.99h-3.07c-1.66,0-3.02,1.26-3.19,2.87h-0.78v2.4h0.78c0.17,1.61,1.54,2.87,3.19,2.87h3.07v0.99
                                            h1.22V70.4H65.7z"/>
                                        <path class="st2" d="M52.5,49.21c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1s2.1-0.94,2.1-2.1
                                            C54.6,50.15,53.66,49.21,52.5,49.21z"/>
                                        <path class="st2" d="M52.5,38.5c-4.87,0-9.26,2.71-11.45,7.07c-0.23,0.46-0.05,1.03,0.42,1.26c0.22,0.11,0.48,0.13,0.72,0.05
                                            c0.24-0.08,0.43-0.25,0.54-0.47c1.87-3.72,5.61-6.03,9.77-6.03c4.16,0,7.9,2.31,9.77,6.03c0.16,0.32,0.48,0.52,0.84,0.52
                                            c0.15,0,0.29-0.03,0.42-0.1c0.22-0.11,0.39-0.31,0.47-0.54c0.08-0.24,0.06-0.49-0.05-0.72v0C61.76,41.21,57.37,38.5,52.5,38.5z"/>
                                        <path class="st2" d="M52.5,45.65c2.15,0,4.09,1.2,5.06,3.12c0.11,0.22,0.31,0.39,0.54,0.47c0.24,0.08,0.49,0.06,0.72-0.05
                                            c0.22-0.11,0.39-0.31,0.47-0.54c0.08-0.24,0.06-0.49-0.05-0.72c-1.29-2.57-3.87-4.16-6.74-4.16c-2.87,0-5.45,1.59-6.74,4.16
                                            c-0.23,0.46-0.04,1.03,0.42,1.26c0.13,0.07,0.27,0.1,0.42,0.1c0.36,0,0.68-0.2,0.84-0.52C48.41,46.85,50.35,45.65,52.5,45.65z"/>
                                    </g>
                                </g>
                            </svg>

                            <svg version="1.1" onMouseEnter={() => this.showComponent('estructura')}  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 105 119" className={`vertientes-subsection-icon hexagon ${this.state.showEstructura ? 'hexagon_active': ''}`}>

                                <g id="Shape">
                                    <g>
                                        <g>
                                            <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                            <polygon id="_x3C_HexagonShape_x3E_" className={` st1 `} points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                                52.5,19.41 			"/>
                                        </g>
                                    </g>
                                </g>
                                <g id="Icon">
                                </g>
                                <g id="icons">
                                    <path class="st2" d="M34.55,38.78c2.93,7.24,5.5,14.39-2.46,19.2l0.02,4.36l19.34,0.02v-3.46h-3.32v-4.29h3.32V40.55
                                        c-1.93-0.28-3.88-1.21-5.59-3.04C41.24,34.9,36.99,36.3,34.55,38.78L34.55,38.78z M32.11,64.44l0.02,6.78l19.32,0.02v-6.76
                                        L32.11,64.44z M32.12,73.33v4.87c6.06,6.36,12.61,5.45,19.32,2.23v-7.06L32.12,73.33z M53.57,80.36
                                        c6.03,2.93,12.38,2.83,19.22-1.63l0.04-5.34l-19.25-0.02V80.36z M72.84,71.26l0.03-6.76l-19.3-0.02v6.76L72.84,71.26z M72.89,62.37
                                        l0.02-4.76c-5.25-2.51-5.78-9.31-2.6-19.43c-3.76-2.04-7.62-3.25-10.49-0.51c-1.6,1.58-3.85,2.72-6.25,2.93v14h3.32v4.29h-3.32
                                        v3.46L72.89,62.37z"/>
                                </g>
                            </svg>

                            <svg version="1.1" onMouseEnter={() => this.showComponent('disenoArq')}  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"   viewBox="0 0 105 119" className={`vertientes-subsection-icon hexagon ${this.state.showDisenoArq ? 'hexagon_active': ''}`}>
                           
                            <g id="Shape">
                                <g>
                                    <g>
                                        <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                        <polygon id="_x3C_HexagonShape_x3E_" className={` st1`} points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                            52.5,19.41 			"/>
                                    </g>
                                </g>
                            </g>
                            <g id="Icon">
                                <g>
                                    <g>
                                        <path class="st2" d="M55.8,43.42H49.2l1-12.14h4.59L55.8,43.42z M50.71,42.04h3.58l-0.77-9.36h-2.04L50.71,42.04z"/>
                                    </g>
                                    <g>
                                        <path class="st2" d="M75.29,61.04H29.71v-0.69c0-2.98,0.49-5.9,1.45-8.66c2.14-6.14,6.54-11.16,12.1-13.83l0.55-3.09l0.27-0.16
                                            c0.1-0.06,2.62-1.53,6.57-1.88l0.82-0.07l-0.77,9.39h3.58l-0.77-9.37l0.83,0.09c2.2,0.23,4.38,0.84,6.5,1.82l0.34,0.16l0.48,3.13
                                            c1.64,0.76,3.16,1.72,4.54,2.84c5.68,4.63,9.07,11.97,9.07,19.65V61.04z M31.11,59.66h42.78c-0.2-7.01-3.36-13.66-8.55-17.88
                                            c-1.38-1.13-2.92-2.07-4.58-2.81l-0.34-0.15l-0.48-3.13c-1.6-0.71-3.24-1.19-4.89-1.44l0.76,9.19H49.2l0.76-9.23
                                            c-2.49,0.34-4.25,1.13-4.9,1.46l-0.56,3.16l-0.33,0.15c-5.37,2.47-9.64,7.27-11.7,13.18C31.64,54.55,31.18,57.07,31.11,59.66z"/>
                                    </g>
                                    <g>
                                        
                                            <rect x="60.71" y="35.13" transform="matrix(0.9882 -0.1531 0.1531 0.9882 -5.4997 9.879)" class="st2" width="1.39" height="11.04"/>
                                    </g>
                                    <g>
                                        
                                            <rect x="37.92" y="39.96" transform="matrix(0.1756 -0.9845 0.9845 0.1756 -4.1906 76.3051)" class="st2" width="11.09" height="1.39"/>
                                    </g>
                                    <g>
                                        <path class="st2" d="M47.31,57.8H32.84l-0.04-0.65c-0.18-3.17,2.06-7.15,2.15-7.31l1.21,0.69c-0.02,0.03-1.82,3.23-1.97,5.89
                                            h13.12V57.8z"/>
                                    </g>
                                    <g>
                                        <path class="st2" d="M77.5,64.22H27.5l0-0.69c-0.02-2.98,1.73-3.84,2.66-3.87l0.03,0l44.68,0c0.92,0.03,2.65,0.89,2.63,3.87
                                            L77.5,64.22z M28.92,62.83h47.15c-0.2-1.65-1.16-1.78-1.25-1.79H30.21C30.07,61.06,29.13,61.21,28.92,62.83z"/>
                                    </g>
                                    <g>
                                        <path class="st2" d="M56.23,87.72h-7.09c-0.38,0-0.69-0.31-0.69-0.69v-3.29c-1.71-0.36-3.36-0.93-4.92-1.7l-2.02,2.62
                                            c-0.11,0.15-0.28,0.24-0.46,0.26c-0.18,0.02-0.37-0.03-0.51-0.14l-5.62-4.34c-0.3-0.23-0.36-0.67-0.13-0.97l2.06-2.68
                                            c-1.11-1.32-2.04-2.76-2.8-4.31l-3.32,0.87c-0.37,0.1-0.75-0.12-0.85-0.5L28.09,66c-0.05-0.18-0.02-0.37,0.07-0.53
                                            c0.09-0.16,0.24-0.27,0.42-0.32l3.42-0.89c-0.01-0.23-0.02-0.48-0.02-0.73c0-0.18,0.07-0.36,0.2-0.49
                                            c0.13-0.13,0.31-0.2,0.49-0.2h10.13c0.38,0,0.68,0.3,0.69,0.67c0.15,4.99,4.18,8.91,9.17,8.91c5,0,9.03-3.91,9.17-8.91
                                            c0.01-0.37,0.32-0.67,0.69-0.67h10.13c0.18,0,0.36,0.07,0.49,0.2c0.13,0.13,0.2,0.31,0.2,0.49c0,0.91-0.07,1.82-0.19,2.73
                                            l3.29,1.21c0.36,0.13,0.54,0.53,0.41,0.89l-2.45,6.66c-0.06,0.17-0.19,0.31-0.36,0.39c-0.17,0.08-0.36,0.08-0.53,0.02l-3.2-1.18
                                            c-0.6,0.99-1.28,1.92-2.03,2.78l2.02,2.7c0.11,0.15,0.16,0.33,0.13,0.51c-0.03,0.18-0.12,0.35-0.27,0.46l-5.68,4.25
                                            c-0.31,0.23-0.74,0.17-0.97-0.14l-1.99-2.65c-1.48,0.71-3.04,1.23-4.65,1.57v3.29C56.92,87.4,56.61,87.72,56.23,87.72z
                                            M49.83,86.33h5.71v-3.17c0-0.34,0.24-0.62,0.57-0.68c1.87-0.33,3.67-0.94,5.36-1.81c0.3-0.15,0.67-0.07,0.87,0.2l1.92,2.56
                                            l4.57-3.42l-1.95-2.6c-0.2-0.27-0.18-0.64,0.04-0.88c0.96-1.04,1.81-2.21,2.53-3.47c0.17-0.29,0.52-0.42,0.84-0.31l3.09,1.14
                                            l1.97-5.35l-3.17-1.17c-0.31-0.11-0.5-0.43-0.44-0.76c0.13-0.79,0.21-1.59,0.24-2.39H63.2c-0.23,2.5-1.34,4.82-3.15,6.59
                                            c-1.98,1.93-4.6,2.99-7.37,2.99c-2.77,0-5.38-1.06-7.37-2.99c-1.82-1.77-2.92-4.09-3.15-6.59h-8.77
                                            c0.01,0.18,0.02,0.36,0.03,0.53c0.02,0.33-0.2,0.63-0.52,0.71l-3.3,0.86l1.44,5.52L34.26,71c0.33-0.09,0.67,0.08,0.81,0.39
                                            c0.81,1.8,1.88,3.46,3.19,4.93c0.22,0.25,0.23,0.62,0.03,0.88l-1.99,2.57l4.52,3.49l1.95-2.53c0.21-0.27,0.58-0.35,0.87-0.19
                                            c1.75,0.93,3.64,1.58,5.61,1.94c0.33,0.06,0.57,0.35,0.57,0.68V86.33z"/>
                                    </g>
                                    <g>
                                        <path class="st2" d="M52.68,77.9c-3.16,0-6.31-1.07-8.87-3.02c-2.57-1.95-4.44-4.7-5.28-7.75l1.34-0.37
                                            c1.55,5.65,6.94,9.75,12.81,9.75V77.9z"/>
                                    </g>
                                </g>
                            </g>
                            </svg>
                           
                            <svg version="1.1" onMouseEnter={() => this.showComponent('proteccTormentas')} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={`vertientes-subsection-icon hexagon  ${this.state.showProteccTormentas ? 'hexagon_active': ''}`}
                                viewBox="0 0 105 119">                           
                            <g id="Shape">
                                <g>
                                    <g>
                                        <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                        <polygon id="_x3C_HexagonShape_x3E_" className={` st1  `} points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                            52.5,19.41 			"/>
                                    </g>
                                </g>
                            </g>
                            <g id="Icon">
                                <path class="st2" d="M55.7,35.43L55.7,35.43c0.39,0,0.61,0.19,0.61,0.61L55.09,52.8c0,0.42-0.42,0.19,0,0l3.83-1.61
                                    c0.42,0,0.61,0.19,0.42,0.61l-9.12,31.48c0,0.42-0.2,0.42-0.2-0.19l3.03-21.01c0-0.39-1.61,0.42-1.8,0.42L46,64.52
                                    c-0.23,0-0.42-0.23-0.42-0.61L48,39.07c0-0.42,0.42-0.81,0.61-1.03L55.7,35.43z"/>
                            </g>
                            </svg>

                            <svg version="1.1" onMouseEnter={() => this.showComponent('alarmaDetecc')} className={`vertientes-subsection-icon hexagon  ${this.state.showAlarmaDetecc ? 'hexagon_active': ''}`} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    viewBox="0 0 105 119" >                                
                                <g id="Shape">
                                    <g>
                                        <g>
                                            <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                            <polygon id="_x3C_HexagonShape_x3E_" className={` st1  `} points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                                52.5,19.41 			"/>
                                        </g>
                                    </g>
                                </g>
                                <g id="Icon">
                                    <path class="st2" d="M31.89,48.29c-0.09,0-0.18-0.04-0.25-0.1c-0.07-0.07-0.11-0.15-0.11-0.25l-0.05-4.19
                                        c0-0.19,0.15-0.35,0.35-0.36l42.19-0.52c0.11,0.01,0.18,0.03,0.25,0.1c0.07,0.07,0.1,0.15,0.11,0.25l0.05,4.19
                                        c0,0.19-0.15,0.35-0.35,0.36L31.89,48.29C31.89,48.29,31.89,48.29,31.89,48.29z M32.19,44.1l0.04,3.49l41.49-0.51l-0.04-3.49
                                        L32.19,44.1z"/>
                                    <path class="st2" d="M35.75,51.36c-0.19,0-0.35-0.16-0.35-0.35l-0.04-3.12c0-0.09,0.04-0.18,0.1-0.25c0.07-0.07,0.15-0.1,0.25-0.11
                                        l34.55-0.42c0.19-0.02,0.35,0.15,0.36,0.35l0.04,3.12c0,0.09-0.04,0.18-0.1,0.25c-0.07,0.07-0.15,0.1-0.25,0.11L35.75,51.36
                                        C35.75,51.36,35.75,51.36,35.75,51.36z M36.07,48.24l0.03,2.41l33.84-0.41l-0.03-2.41L36.07,48.24z"/>
                                    <path class="st2" d="M53.17,76.99c-7.84,0-14.29-6.37-14.39-14.21l-0.14-11.81c0-0.19,0.15-0.35,0.35-0.36l28.07-0.34
                                        c0.11,0.01,0.18,0.04,0.25,0.1c0.07,0.07,0.1,0.15,0.11,0.25l0.14,11.81c0.05,3.83-1.41,7.45-4.1,10.21
                                        c-2.69,2.76-6.28,4.3-10.11,4.35L53.17,76.99z M39.34,51.32l0.14,11.46c0.09,7.45,6.23,13.51,13.68,13.51l0.17,0
                                        c3.64-0.04,7.05-1.51,9.61-4.13c2.56-2.62,3.95-6.07,3.9-9.71l-0.14-11.46L39.34,51.32z"/>
                                    <path class="st2" d="M55.11,72.7c-2.63,0-4.8-2.14-4.83-4.77c-0.02-1.29,0.47-2.51,1.37-3.43c0.9-0.92,2.11-1.44,3.4-1.46h0.06
                                        c2.63,0,4.79,2.14,4.82,4.77c0.03,2.66-2.11,4.85-4.77,4.88H55.11z M55.11,63.75c-2.33,0.03-4.15,1.9-4.13,4.18
                                        c0.03,2.24,1.88,4.07,4.12,4.07l0.05,0c2.27-0.03,4.1-1.9,4.07-4.17C59.2,65.58,57.35,63.75,55.11,63.75z"/>
                                    <path class="st2" d="M55.11,70.06c-1.19,0-2.17-0.97-2.19-2.15c-0.01-1.21,0.95-2.2,2.16-2.21c1.22,0,2.2,0.97,2.21,2.16
                                        c0.01,1.2-0.95,2.19-2.16,2.21C55.13,70.06,55.11,70.06,55.11,70.06z M55.11,66.39c-0.83,0.01-1.49,0.68-1.48,1.5
                                        c0.01,0.8,0.67,1.46,1.48,1.46v0.35l0.02-0.35c0.82-0.01,1.47-0.68,1.46-1.49C56.58,67.05,55.92,66.39,55.11,66.39z"/>
                                </g>
                            </svg>  
                    </div>

                    <div className="col-sm-6 vertientes-right-column-icon">
                       
                        <svg version="1.1" onMouseEnter={() => this.showComponent('instElectricas')} className={`vertientes-subsection-icon hexagon  ${this.state.showInstalacionesElectricas ? 'hexagon_active': ''}`} xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                            viewBox="0 0 105 119" >
                        
                            <g id="Shape">
                                <g>
                                    <g>
                                        <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                        <polygon id="_x3C_HexagonShape_x3E_"  className={` st1  `} points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                            52.5,19.41 			"/>
                                    </g>
                                </g>
                            </g>
                            <g id="Icon">
                                <path class="st2" d="M52.7,59.78c2.33,0,4.22-1.89,4.22-4.22c0-2.33-1.89-4.22-4.22-4.22c-2.33,0-4.22,1.89-4.22,4.22
                                    C48.48,57.89,50.37,59.78,52.7,59.78z M39.26,60.13c-6.48,3.74-10.56,8.82-10.56,8.82s6.44-0.99,12.92-4.73
                                    c1.99-1.15,3.75-2.42,5.24-3.64c-0.79-0.92-1.36-2.02-1.65-3.23C43.37,58.04,41.32,58.95,39.26,60.13z M52.3,47.85
                                    c0.58,0,1.14,0.07,1.69,0.19c0.38-2.08,0.67-4.53,0.67-7.17c0-7.48-2.36-13.55-2.36-13.55s-2.36,6.07-2.36,13.55
                                    c0,2.64,0.29,5.09,0.67,7.17C51.16,47.92,51.72,47.85,52.3,47.85z M65.74,60.13c-2.06-1.19-4.11-2.1-5.95-2.79
                                    c-0.29,1.21-0.86,2.32-1.65,3.23c1.49,1.22,3.25,2.49,5.24,3.64c6.48,3.74,12.92,4.73,12.92,4.73S72.22,63.88,65.74,60.13z
                                    M54.15,63.12c-0.47,0.09-0.96,0.15-1.45,0.15c-0.5,0-0.98-0.06-1.45-0.15L48.33,88.7c-0.19,1.64,1.01,2.98,2.66,2.98h3.41
                                    c1.65,0,2.85-1.34,2.66-2.98L54.15,63.12z"/>
                            </g>
                        </svg>

                        <svg version="1.1" onMouseEnter={() => this.showComponent('aire')} className={`vertientes-subsection-icon hexagon   ${this.state.showAire ? 'hexagon_active': ''}`} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 105 119">
                       
                            <g id="Shape">
                                <g>
                                    <g>
                                        <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                        <polygon id="_x3C_HexagonShape_x3E_" className={` st1 `} points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                            52.5,19.41 			"/>
                                    </g>
                                </g>
                            </g>
                            <g id="Icon">
                                <g>
                                    <path class="st2" d="M52.5,42.05c-1.09,0-1.98,0.89-1.98,1.98c0,1.09,0.89,1.98,1.98,1.98s1.98-0.89,1.98-1.98
                                        C54.48,42.94,53.59,42.05,52.5,42.05z"/>
                                    <path class="st2" d="M52.5,31.94c-4.6,0-8.75,2.56-10.82,6.68c-0.22,0.44-0.04,0.97,0.4,1.19c0.21,0.11,0.45,0.12,0.68,0.05
                                        c0.23-0.07,0.41-0.23,0.51-0.44c1.77-3.51,5.3-5.7,9.23-5.7c3.93,0,7.46,2.18,9.23,5.7c0.15,0.3,0.46,0.49,0.79,0.49
                                        c0.14,0,0.27-0.03,0.4-0.09c0.21-0.11,0.37-0.29,0.44-0.51c0.07-0.23,0.06-0.47-0.05-0.68v0C61.25,34.5,57.1,31.94,52.5,31.94z"/>
                                    <path class="st2" d="M52.5,38.69c2.03,0,3.86,1.13,4.78,2.95c0.11,0.21,0.29,0.37,0.51,0.44c0.23,0.07,0.47,0.06,0.68-0.05
                                        c0.21-0.11,0.37-0.29,0.44-0.51c0.07-0.23,0.06-0.47-0.05-0.68c-1.22-2.42-3.66-3.93-6.36-3.93c-2.71,0-5.15,1.51-6.36,3.93
                                        c-0.22,0.44-0.04,0.97,0.4,1.19c0.13,0.06,0.26,0.09,0.4,0.09c0.34,0,0.64-0.19,0.79-0.49C48.64,39.82,50.47,38.69,52.5,38.69z"/>
                                    <path class="st2" d="M52.5,87.06c-5.13,0-9.31-4.17-9.31-9.31c0-3.47,1.97-6.69,5.04-8.27V53.9c0-2.35,1.91-4.26,4.26-4.26
                                        c2.35,0,4.26,1.91,4.26,4.26v15.59c3.07,1.59,5.04,4.8,5.04,8.27C61.81,82.89,57.63,87.06,52.5,87.06z M52.5,50.64
                                        c-1.8,0-3.26,1.46-3.26,3.26v16.21l-0.29,0.14c-2.89,1.37-4.76,4.32-4.76,7.51c0,4.58,3.73,8.3,8.3,8.3c4.58,0,8.3-3.73,8.3-8.3
                                        c0-3.2-1.87-6.14-4.76-7.51l-0.29-0.14V53.9C55.76,52.1,54.3,50.64,52.5,50.64z"/>
                                    <path class="st2" d="M54.16,72.55v-9.72c0-0.92-0.75-1.66-1.66-1.66l0,0c-0.92,0-1.66,0.75-1.66,1.66v9.72
                                        c-2.2,0.7-3.8,2.77-3.8,5.2c0,3.02,2.45,5.46,5.46,5.46c3.02,0,5.46-2.45,5.46-5.46C57.96,75.32,56.37,73.26,54.16,72.55z"/>
                                </g>
                            </g>
                        </svg>

                        <svg version="1.1" onMouseEnter={() => this.showComponent('instHidraulicas')} className={`vertientes-subsection-icon hexagon  ${this.state.showInstalacionesHidraulicas ? 'hexagon_active': ''}`}  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 105 119" >
                         
                            <g id="Shape">
                                <g>
                                    <g>
                                        <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                        <polygon id="_x3C_HexagonShape_x3E_" className={` st1 `}  points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                            52.5,19.41 			"/>
                                    </g>
                                </g>
                            </g>
                            <g id="Icon">
                                <g>
                                    <path class="st2" d="M34.5,54.95L34.5,54.95h-1.96l0,0c-1.72,0-3.11,3.72-3.11,8.34c0,4.62,1.39,8.37,3.11,8.37h1.96
                                        c-1.73,0-3.13-3.75-3.13-8.37C31.37,58.67,32.76,54.95,34.5,54.95z"/>
                                    <path class="st2" d="M35.65,68.05c-1.01-0.7-1.48-2.88-1.48-4.76c0-1.86,0.47-4.03,1.48-4.76h0.87c0.02,0,0.03,0,0.04,0h1.28
                                        c-0.56-2.17-1.51-3.58-2.56-3.58c-1.73,0-3.13,3.72-3.13,8.34c0,4.62,1.39,8.37,3.13,8.37c1.06,0,2-1.44,2.56-3.61H35.65z
                                        M35.5,55.83c0.39,0,0.73,0.45,0.73,1.01c0,0.56-0.34,1.01-0.73,1.01c-0.41,0-0.73-0.45-0.73-1.01
                                        C34.76,56.27,35.09,55.83,35.5,55.83z M33.24,63.97c-0.32,0-0.58-0.45-0.58-1.01c0-0.56,0.25-1.01,0.58-1.01
                                        c0.32,0,0.59,0.45,0.59,1.01C33.83,63.51,33.57,63.97,33.24,63.97z M35.5,70.61c-0.41,0-0.73-0.45-0.73-1.01
                                        c0-0.53,0.32-0.98,0.73-0.98c0.39,0,0.73,0.45,0.73,0.98C36.23,70.16,35.89,70.61,35.5,70.61z"/>
                                    <path class="st2" d="M70.5,54.95L70.5,54.95h1.94l0,0c1.73,0,3.13,3.72,3.13,8.34c0,4.62-1.39,8.37-3.11,8.37H70.5
                                        c1.73,0,3.13-3.75,3.13-8.37C73.63,58.67,72.22,54.95,70.5,54.95z"/>
                                    <path class="st2" d="M69.72,54.95c-1.06,0-2,1.41-2.56,3.58h1.27c0.03,0,0.04,0,0.06,0h0.87c1.01,0.73,1.46,2.9,1.46,4.76
                                        c0,1.89-0.45,4.06-1.46,4.76h-2.2c0.56,2.17,1.51,3.61,2.56,3.61c1.73,0,3.13-3.75,3.13-8.37C72.84,58.67,71.45,54.95,69.72,54.95
                                        z M69.51,57.85c-0.41,0-0.73-0.45-0.73-1.01c0-0.57,0.32-1.01,0.73-1.01s0.73,0.45,0.73,1.01C70.24,57.4,69.91,57.85,69.51,57.85z
                                        M69.51,70.61c-0.41,0-0.73-0.45-0.73-1.01c0-0.53,0.32-0.98,0.73-0.98s0.73,0.45,0.73,0.98C70.24,70.16,69.91,70.61,69.51,70.61z
                                        M71.76,63.97c-0.32,0-0.59-0.45-0.59-1.01c0-0.56,0.27-1.01,0.59-1.01c0.32,0,0.58,0.45,0.58,1.01
                                        C72.34,63.51,72.08,63.97,71.76,63.97z"/>
                                    <path class="st2" d="M60.8,57.48c-2.48,0.62-5.3,0.99-8.3,0.99c-3,0-5.82-0.37-8.3-0.99c-1.66,1.44-2.69,3.35-2.69,5.44
                                        c0,4.59,4.92,8.34,10.99,8.34c6.06,0,10.99-3.75,10.99-8.34C63.49,60.84,62.46,58.92,60.8,57.48z M52.16,69.57
                                        c-4,0-7.42-1.89-8.8-4.54c1.7,1.89,4.58,3.13,7.82,3.13c5.24,0,9.48-3.21,9.48-7.19c0-0.93-0.24-1.83-0.66-2.62
                                        c1.03,1.13,1.65,2.54,1.65,4.03C61.64,66.36,57.4,69.57,52.16,69.57z"/>
                                    <path class="st2" d="M41.71,67.09c-0.8-1.27-1.26-2.68-1.26-4.17c0-1.21,0.3-2.37,0.83-3.41h-4.66l0,0c-0.02,0-0.03,0-0.06,0
                                        c-0.78,0-1.41,1.69-1.41,3.78c0,2.08,0.63,3.8,1.41,3.8c0.03,0,0.04-0.03,0.06-0.03v0.03H41.71z"/>
                                    <path class="st2" d="M68.45,59.51h-4.73c0.52,1.04,0.82,2.2,0.82,3.41c0,1.49-0.45,2.9-1.24,4.17h5.16c0.79,0,1.42-1.72,1.42-3.8
                                        C69.87,61.21,69.24,59.51,68.45,59.51z"/>
                                    <path class="st2" d="M52.5,47.34c-6.59,0-13.68,1.66-13.68,5.3c0,3.63,7.08,5.27,13.68,5.27c6.59,0,13.68-1.64,13.68-5.27
                                        C66.18,49,59.09,47.34,52.5,47.34z M53.64,51.14c0.89,0.31,1.51,0.96,1.51,1.44c0,0.67-1.13,1.24-2.51,1.24
                                        c-0.73,0-1.39-0.17-1.86-0.42c0.41,0.14,0.89,0.22,1.41,0.22c1.38,0,2.51-0.56,2.51-1.21C54.7,51.99,54.28,51.48,53.64,51.14z
                                        M53.68,50.53c-0.37-0.11-0.78-0.17-1.18-0.17c-1,0-1.89,0.33-2.54,0.79l-3.45-0.82c1.63-0.31,3.63-0.51,5.99-0.51
                                        c0.75,0,1.45,0.03,2.11,0.06L53.68,50.53z M50.92,54.19c0.48,0.12,1.01,0.2,1.58,0.2c1.01,0,1.92-0.2,2.56-0.53l4.09,0.96
                                        c-1.73,0.39-3.96,0.64-6.65,0.64c-1.15,0-2.23-0.06-3.21-0.14L50.92,54.19z M56.08,52.58c-0.03-0.43-0.35-0.9-0.87-1.32l1.72-1.19
                                        c4.44,0.59,6.8,1.92,6.8,2.57c0,0.31-0.61,0.82-1.76,1.29L56.08,52.58z M41.17,51.71c-0.03,0.06-0.06,0.25-0.04,0.45
                                        c-0.2-0.48-0.2-0.62-0.14-0.82c0.15-0.71,3.32-2.48,10.59-2.48c6.61,0,10.44,1.41,11.1,2.17c-1.54-0.76-5.04-1.66-10.33-1.66
                                        C45.08,49.37,41.46,51.01,41.17,51.71z M41.27,52.64c0-0.37,0.76-0.96,2.23-1.5l5.45,1.27c-0.03,0.09-0.03,0.14-0.03,0.23
                                        c0,0.31,0.15,0.59,0.44,0.84l-2.27,1.55C43.29,54.42,41.27,53.23,41.27,52.64z M53.06,57.35c-4.55,0-9.37-0.85-11.33-2.65
                                        c2.25,1.44,6.55,2.11,10.64,2.11c5.89,0,12.23-1.41,12.23-4.51c0-0.7-0.32-1.32-0.9-1.86c0.99,0.62,1.59,1.44,1.59,2.37
                                        C65.29,55.94,58.95,57.35,53.06,57.35z"/>
                                </g>
                            </g>
                        </svg>

                        <svg version="1.1" onMouseEnter={() => this.showComponent('instSanitarias')} className={`vertientes-subsection-icon hexagon  ${this.state.showInstalacionesSanitarias ? 'hexagon_active': ''}`} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 105 119" >
                           
                            <g id="Shape">
                                <g>
                                    <g>
                                        <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                        <polygon id="_x3C_HexagonShape_x3E_" className={` st1 `}  points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                            52.5,19.41 			"/>
                                    </g>
                                </g>
                            </g>
                            <g id="Icon">
                                <g>
                                    <path class="st2" d="M52.52,60.17c-1.54,4.59-4.59,7.64-4.59,10.69c0,6.14,9.19,6.14,9.19,0C57.12,67.81,54.05,64.75,52.52,60.17z
                                        M52.52,73.94c4.59-3.08,0-6.13,0-12.27c0,3.08,3.05,6.14,3.05,9.19C55.57,73.94,52.52,73.94,52.52,73.94z"/>
                                    <path class="st2" d="M30.61,43.54L30.61,43.54h-2.38l0,0c-2.09,0-3.81,4.56-3.81,10.15c0,5.62,1.7,10.18,3.81,10.18h2.36
                                        c-2.09,0-3.79-4.56-3.79-10.18C26.8,48.1,28.5,43.54,30.61,43.54z"/>
                                    <path class="st2" d="M32.01,59.51c-1.23-0.89-1.8-3.53-1.8-5.82c0-2.26,0.56-4.9,1.8-5.76l1.05-0.04c0.03,0,0.05,0,0.05,0h1.56
                                        c-0.68-2.64-1.81-4.35-3.12-4.35c-2.09,0-3.8,4.56-3.8,10.15c0,5.62,1.71,10.18,3.8,10.18c1.3,0,2.43-1.71,3.12-4.36H32.01z"/>
                                    <path class="st2" d="M32.7,45.84c0,0.68-0.39,1.23-0.88,1.23c-0.5,0-0.89-0.55-0.89-1.23c0-0.68,0.39-1.23,0.89-1.23
                                        C32.31,44.61,32.7,45.16,32.7,45.84z"/>
                                    <path class="st2" d="M32.7,61.4c0,0.65-0.39,1.23-0.88,1.23c-0.5,0-0.89-0.59-0.89-1.23c0-0.68,0.39-1.23,0.89-1.23
                                        C32.31,60.17,32.7,60.71,32.7,61.4z"/>
                                    <path class="st2" d="M29.81,53.28c0,0.68-0.33,1.23-0.72,1.23c-0.39,0-0.72-0.55-0.72-1.23c0-0.65,0.33-1.23,0.72-1.23
                                        C29.48,52.04,29.81,52.63,29.81,53.28z"/>
                                    <path class="st2" d="M74.41,43.54L74.41,43.54h2.36l0,0c2.11,0,3.81,4.56,3.81,10.15c0,5.62-1.7,10.18-3.81,10.18h-2.36
                                        c2.09,0,3.8-4.56,3.8-10.18C78.21,48.1,76.5,43.54,74.41,43.54z"/>
                                    <path class="st2" d="M73,59.51c1.22-0.89,1.78-3.53,1.78-5.82c0-2.26-0.56-4.9-1.78-5.76l-1.06-0.04c0,0-0.05,0-0.07,0h-1.54
                                        c0.68-2.64,1.83-4.35,3.12-4.35c2.11,0,3.8,4.56,3.8,10.15c0,5.62-1.7,10.18-3.8,10.18c-1.29,0-2.43-1.71-3.12-4.36H73z"/>
                                    <path class="st2" d="M72.3,45.84c0,0.68,0.39,1.23,0.89,1.23c0.48,0,0.89-0.55,0.89-1.23c0-0.68-0.41-1.23-0.89-1.23
                                        C72.69,44.61,72.3,45.16,72.3,45.84z"/>
                                    <ellipse class="st2" cx="73.19" cy="61.4" rx="0.89" ry="1.23"/>
                                    <path class="st2" d="M75.21,53.28c0,0.68,0.31,1.23,0.7,1.23c0.39,0,0.72-0.55,0.72-1.23c0-0.65-0.33-1.23-0.72-1.23
                                        C75.52,52.04,75.21,52.63,75.21,53.28z"/>
                                    <path class="st2" d="M71.91,49.09H33.2l0,0c0,0-0.05,0-0.07,0c-0.94,0-1.71,2.06-1.71,4.6c0,2.57,0.77,4.63,1.71,4.63
                                        c0.02,0,0.05,0,0.05,0h0.02h16.52l-0.56-1.85l4.3-1.75l-2.16-3.29l5.07,4.08l-3.6,1.3l2.16,1.51h16.98c0.94,0,1.73-2.05,1.73-4.63
                                        C73.64,51.15,72.85,49.09,71.91,49.09z"/>
                                </g>
                            </g>
                        </svg>

                        <svg version="1.1" onMouseEnter={() => this.showComponent('proteccionIncendio')} className={`vertientes-subsection-icon hexagon  ${this.state.showProteccionIncendio ? 'hexagon_active': ''}`} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                viewBox="0 0 105 119" >
                           
                            <g id="Shape">
                                <g>
                                    <g>
                                        <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                        <polygon id="_x3C_HexagonShape_x3E_" className={` st1 `}  points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                            52.5,19.41 			"/>
                                    </g>
                                </g>
                            </g>
                            <g id="Icon">
                                <g>
                                        <path class="st2" d="M56.97,72.3H67.2l1.55,1.55v7.9l-1.55,1.55H56.97v-1.53c1.1,0,2.09-0.44,2.81-1.16
                                                c0.72-0.72,1.16-1.71,1.16-2.81c0-1.1-0.44-2.09-1.16-2.81c-0.72-0.72-1.71-1.16-2.81-1.16V72.3L56.97,72.3z M56.97,79.25
                                                c0.4,0,0.76-0.16,1.02-0.42c0.26-0.26,0.42-0.62,0.42-1.02c0-0.4-0.16-0.76-0.42-1.02c-0.26-0.26-0.62-0.42-1.02-0.42V79.25z
                                                M46.74,72.3h10.23v1.53c-1.1,0-2.09,0.44-2.81,1.16c-0.25,0.25-0.48,0.54-0.65,0.86h-5.97c-0.8,0-1.58-0.08-2.35-0.22v-1.78
                                                L46.74,72.3L46.74,72.3z M56.97,83.31H46.74l-1.55-1.55v-2.1c0.77,0.11,1.56,0.17,2.35,0.17h6.01c0.17,0.29,0.38,0.56,0.61,0.79
                                                c0.72,0.72,1.71,1.16,2.81,1.16V83.31L56.97,83.31z M56.97,76.37v2.88c-0.4,0-0.76-0.16-1.02-0.42c-0.26-0.26-0.42-0.62-0.42-1.02
                                                c0-0.4,0.16-0.76,0.42-1.02C56.21,76.53,56.57,76.37,56.97,76.37z"/>
                                        <polygon class="st2" points="53.5,68.46 60.44,68.46 61.8,69.82 61.8,71.5 60.44,72.86 53.5,72.86 52.13,71.5 52.13,69.82          "/>
                                        <path class="st2" d="M56.97,35.69c4.94,0,9.4,2,12.64,5.23c3.23,3.23,5.23,7.7,5.23,12.64c0,4.94-2,9.4-5.23,12.64
                                                c-3.23,3.23-7.7,5.23-12.64,5.23v-13.9c1.1,0,2.09-0.44,2.81-1.16c0.72-0.72,1.16-1.71,1.16-2.81c0-1.1-0.44-2.09-1.16-2.81
                                                c-0.72-0.72-1.71-1.16-2.81-1.16v-9.88c1.88,0,3.67,0.37,5.3,1.05c1.69,0.7,3.22,1.73,4.5,3.01c1.28,1.28,2.31,2.8,3.01,4.5
                                                c0.68,1.63,1.05,3.42,1.05,5.3c0,0.3,0.24,0.54,0.54,0.54c0.3,0,0.54-0.24,0.54-0.54c0-2.02-0.4-3.95-1.13-5.71
                                                c-0.76-1.83-1.87-3.48-3.24-4.85c-1.38-1.38-3.02-2.48-4.85-3.24c-1.76-0.73-3.69-1.13-5.71-1.13V35.69z M56.97,71.44
                                                c-4.94,0-9.4-2-12.64-5.23c-3.23-3.23-5.23-7.7-5.23-12.64c0-4.94,2-9.4,5.23-12.64c3.23-3.23,7.7-5.23,12.64-5.23v2.93
                                                c-0.3,0-0.54,0.24-0.54,0.54s0.24,0.54,0.54,0.54v9.88c-1.1,0-2.09,0.44-2.81,1.16C53.44,51.47,53,52.47,53,53.56
                                                c0,1.1,0.44,2.09,1.16,2.81c0.72,0.72,1.71,1.16,2.81,1.16V71.44z"/>
                                        <path class="st3" d="M56.56,76.85c0.55,0,0.99,0.44,0.99,0.99s-0.44,0.99-0.99,0.99h-9.02c-4.23,0-8.08-1.72-10.87-4.5
                                                c-2.79-2.78-4.52-6.61-4.52-10.83V51.57c0-0.55,0.44-0.99,0.99-0.99c0.55,0,0.99,0.44,0.99,0.99V63.5c0,3.67,1.51,7.01,3.94,9.43
                                                c2.43,2.42,5.78,3.92,9.47,3.92H56.56z"/>
                                        <path class="st3" d="M33.14,47.49L33.14,47.49c0.82,0,1.57,0.33,2.1,0.87c0.54,0.54,0.87,1.28,0.87,2.1v0.84
                                                c0,0.82-0.33,1.57-0.87,2.1c-0.54,0.54-1.28,0.87-2.1,0.87h0c-0.82,0-1.57-0.33-2.1-0.87c-0.54-0.54-0.87-1.28-0.87-2.1v-0.84
                                                c0-0.82,0.33-1.57,0.87-2.1C31.57,47.82,32.32,47.49,33.14,47.49z"/>
                                </g>
                            </g>

                        </svg>

                    </div>
                                       
                </div> 
                    
                </div>
                <div className="section-image d-flex col-sm-9">
                    {showMantenimiento && <Mantenimiento/> }
                    {showEstructura && <EstructuraHerreria/>}
                    {showDisenoArq && <DisenoArquitectonico/>}
                    {showProteccTormentas && <ProteccionTorentas/>}
                    {showAlarmaDetecc && <AlarmaDeteccion/>}
                    {showInstalacionesSanitarias && <InstalacionesSanitarias/>}
                    {showInstalacionesHidraulicas && <InstalacionesHidraulicas/>}
                    {showAire && <Aire/>}
                    {showInstalacionesElectricas && <InstalacionesElectricas/>}
                    {showProteccionIncendio && <ProteccionIncendio/>}

                </div>
            </div>
            
        </section>

        );
    }
    componentDidMount(){
        window.scrollTo(0,0);
    }
}
export default SissaMonitoring_Contenido;

