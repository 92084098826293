import React, { Component } from 'react';
import { Col, Row } from 'antd';
import '../stylesheets/dashboard/dashboard.css';
import Header from '../Components/HeaderHome';
import Ejem from '../Components/Dashboard/Footer';
import BlogCards from '../Components/Blog/BlogCards';

class BlogContainer extends Component {
    render() {
        return (
            <div className='dashboardFondo'>
                <Header />
                <br />
                <br />
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col style={{ marginLeft: 20 }} xs={24} md={19}>
                        <BlogCards />
                    </Col>
                </Row>
                <br />
                <Ejem />
            </div>
        );
    }
}

export default BlogContainer;
