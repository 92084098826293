import React, { Component } from 'react';

import imgDesarrollo from '../../../../assets/img/nosotros/sissa-digital/desarrollo-de-tecnologias-propias.png';


class DesarrolloDeTecnologias extends Component {

 
    render() {     

        
    return(

        
            <div>
                <img className="img-fluid" alt="desarrollo de software" src={imgDesarrollo} />                
            </div>
                       
    );
    }
}

export default DesarrolloDeTecnologias;