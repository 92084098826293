import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import '../../stylesheets/Pages/inicio.css';
import imagenConcamin from '../../assets/videos/ss-concamin.png';
// import imagen2 from '../../assets/videos/fondoDelVideo.png'
// import imgConcamin from '../../assets/videos/sc-concamin.png'
// import videoSissa from '../../assets/videos/VIDEO_SISSA.mp4'
// import videoSissa2 from '../../assets/videos/SISSA_SEA.mp4'
//import videoConcamin from '../../assets/videos/SISSA-VIDEO-CONCAMIN.mp4'
// import { OverPack } from 'rc-scroll-anim'
// import TweenOne from 'rc-tween-one'
// import QueueAnim from 'rc-queue-anim'

class Video extends Component {
    render() {
        return (
            <div>
                <div className='fondoVideo'></div>
                <Card className='containerVideo'>
                    <Row className='code-box-shape queue-anim-demo'>
                        <Col lg={{ span: 10, offset: 2 }} xs={{ span: 22, offset: 1 }}>
                            <div className='centradoQuienes'>
                                <h1 className='tituloQuienesVideo'>¿Quiénes somos?</h1>
                                <p className='descripcionQuienesVideo'>Somos una empresa integradora de sistemas tecnológicos con 12 años de experiencia en la creación de soluciones totalmente personalizadas para el desarrollo de proyectos únicos en su clase en el sector público y privado.</p>
                            </div>
                        </Col>
                        <Col lg={{ span: 10, offset: 1 }} xs={{ span: 22, offset: 1 }}>
                            {/* <video controls loop className='videoPresentacion' poster={imagenConcamin}>
                <source src={videoConcamin} type='video/mp4' />
                Tu navegador no soporta HTML5 video.
              </video> */}
                        </Col>
                    </Row>
                    {/* <Row className='code-box-shape queue-anim-demo'>
            <OverPack style={{ overflow: 'hidden' }}>
              <TweenOne
                key='0'
                animation={{ opacity: 1 }}
                className='code-box-shape'
                style={{ opacity: 0, marginBottom: 10 }}
              />
              <QueueAnim key='queue' leaveReverse style={{ position: 'relative' }}>
                <div key='a' className='code-box-shape queue-anim-demo'>
                  <Col className='code-box-shape queue-anim-demo'>
                    <h2 className='subtituloVideo'>Ante cada Reto una Solución</h2>
                  </Col>
                  <Col
                    lg={{ span: 14, offset: 5 }}
                    xs={{ span: 22, offset: 1 }}
                    className='code-box-shape queue-anim-demo'
                  >
                    <video controls loop className='videoPresentacion2' poster={imgConcamin}>
                      <source src={videoConcamin} type='video/mp4' />
                      Tu navegador no soporta HTML5 video.
                    </video>
                  </Col>
                </div>
              </QueueAnim>
            </OverPack>
          </Row> */}
                </Card>
                <div className='fondoVideo'></div>
            </div>
        );
    }
}
export default Video;
