import React, { Component } from 'react';

import img from '../../../../assets/img/nosotros/sissa-infraestructura/instalaciones-subestaciones-electricas.png';


class InstalacionesElectricas extends Component {

 
    render() {    

        
    return(        
            <div>
                <img className="img-fluid" alt="Instalaciones y subestaciones eléctricas" src={img} />                
            </div>                       
    );
    }
}

export default InstalacionesElectricas;