import React, { Component } from 'react';
import { Form, Icon, Input, Row, Col, Card, Button, Select, message } from 'antd';
import '../../stylesheets/Administrator/Administrator.css'
import { connect } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import ImageUploading from "react-images-uploading";
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;

class CrearNota extends Component {
    componentDidMount() {
        this.props.onRequestCategorias();
        this.props.onRequestListEmpleadosActivos();
    }
    normFile = (e) => {
        //console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    state = {
        previewImage: '',
        uploadImagen: false,
        previewVisible: false,
        fileList: [],
        editorState: EditorState.createEmpty(),
        tipoElemento: null,
        showAlerta: false,
        showAlertaTamano: false,
        showAlertaTamanoBajo: false
    };
    constructor() {
        super();
        this.state = { checked: false };
        this.handleChange = this.handleChange.bind(this);
    };

    //Editor
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    handleChange(checked) {
        this.setState({ checked });
    };

    handleCancel = () => this.setState({ previewVisible: false, uploadImagen: false, });
    render() {
        const { getFieldDecorator } = this.props.form;
        const { showAlerta, showAlertaTamano, showAlertaTamanoBajo, listNombresEmpleados } = this.state;
        const { editorState } = this.state;     //editor

        const { onRequestCrearNota,
            fetchingCrearNota,
            fetchingGetCategorias,
            categorias,
            onShowErrorMsg
        } = this.props;

        if (showAlerta) {
            message.config({
            });
            message.error("Sólo se aceptan imágenes jpeg, jpg , png y webp.", 5);
            onShowErrorMsg();
            setTimeout(() => {
                this.setState({
                    showAlerta: false,
                    showAlertaTamano: false,
                    showAlertaTamanoBajo: false
                });
            }, 2000);
        }
        else if (showAlertaTamano) {
            message.config({
            });
            message.error("Tu archivo es demasiado pesado. No debe de pasar de 600KB.", 5);
            onShowErrorMsg();
            setTimeout(() => {
                this.setState({
                    showAlerta: false,
                    showAlertaTamano: false,
                    showAlertaTamanoBajo: false
                });
            }, 2000);
        }
        else if (showAlertaTamanoBajo) {
            message.config({
            });
            message.error("Tu archivo debe medir mínimo 10KB para ser aceptado.", 5);
            onShowErrorMsg();
            setTimeout(() => {
                this.setState({
                    showAlerta: false,
                    showAlertaTamano: false,
                    showAlertaTamanoBajo: false
                });
            }, 2000);
        }


        const handleOk = (e) => {
            e.preventDefault();
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    if (!fetchingCrearNota) {
                        const htmlEditor = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                        // const background = 'transparent';
                        const fileList = this.state.fileList;
                        /* if(fileList===undefined){
                            onRequestCrearNota(values, htmlEditor, '');
                        } */

                        if (fileList[0].file.type === "image/jpeg" || fileList[0].file.type === "image/jpg" || fileList[0].file.type === "image/png" || fileList[0].file.type === "image/webp") {
                            if (fileList[0].file.size <= 600000) {
                                if (fileList[0].file.size >= 10000) {
                                    onRequestCrearNota(values, htmlEditor, fileList);
                                    this.props.form.resetFields();
                                    this.setState({
                                        previewVisible: false,
                                        uploadImagen: false,
                                        fileList: undefined,
                                        editorState: EditorState.createEmpty(), //editor
                                    });
                                }
                                else {
                                    this.setState({
                                        showAlertaTamanoBajo: true
                                    });
                                }

                            }
                            else {
                                this.setState({
                                    showAlertaTamano: true
                                });
                            }

                        }
                        else {
                            this.setState({
                                showAlerta: true
                            });
                        }

                    }
                }
            });
        }
        const onChange = (imageList) => {
            if (imageList.length !== 0) {
                this.setState({
                    uploadImagen: true,
                    fileList: imageList,
                });
            }
            else {
                this.setState({
                    uploadImagen: false,
                });
            }
        }
        //Editor
        const onEditorHTML = () => {
            /*  //console.log(draftToHtml(convertToRaw(editorState.getCurrentContent()))); */
        }
        //console.log("listNombresEmpleados", listNombresEmpleados)
        const formItemLayout = {
            labelCol: {
                sm: { span: 0 },
            },
            wrapperCol: {
                sm: { span: 22 },
            },
        };

        return (
            <div >
                <Card className="cardNuevaNota">
                    <Form onSubmit={handleOk}>
                        <Row type="flex" justify="space-around">
                            <Col span={24}>
                                {listNombresEmpleados ? (
                                    <Form.Item label="Autor">
                                        {getFieldDecorator('autor', {
                                            rules: [{
                                                required: true, message: 'Favor de llenar el campo.',
                                            }],
                                        })(
                                            <Select
                                                placeholder="Selecciona el autor"
                                            >

                                                {listNombresEmpleados.map((item) =>
                                                    <Option value={item}>{item}</Option>
                                                )}
                                            </Select>,
                                        )}
                                    </Form.Item>
                                ) : (false)}
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Tipo Nota">
                                    {getFieldDecorator('idTipoNota', {
                                        rules: [{
                                            required: true, message: 'Favor de llenar el campo.',
                                        }],
                                    })(
                                        <Select
                                            placeholder="Selecciona el tipo de nota a crear"
                                        >
                                            <Option value="1">BLOG</Option>
                                            <Option value="2">EVENTO</Option>
                                        </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <FormItem {...formItemLayout}
                                    extra=""
                                    label="Título de Nota"
                                >
                                    {getFieldDecorator('titulo', {
                                        rules: [{
                                            required: true, message: 'Favor de llenar el campo.',
                                        }],
                                    })(
                                        <Input prefix={<Icon type="form" style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={150} placeholder="Ingrese el título" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <Form.Item {...formItemLayout}
                                    label="Descripción de Nota"
                                >
                                    {getFieldDecorator('descripcion', {
                                        rules: [{
                                            required: true, message: 'Favor de llenar el campo.',
                                        }]
                                    })(
                                        <TextArea prefix={<Icon type="container" style={{ color: 'rgba(0,0,0,.25)' }} />} rows={10} placeholder="Ingrese descripción" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item {...formItemLayout}
                                    label="URL video"
                                >
                                    {getFieldDecorator('urlVideo', {
                                    })(
                                        <Input prefix={<Icon type="container" style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={5000} placeholder="Ingrese el url del video" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item {...formItemLayout}
                                    label="Contenido de Nota"
                                >
                                    {getFieldDecorator('texto', {
                                        rules: [{
                                            required: true, message: 'Favor de llenar el campo.',
                                        }],
                                    })(
                                        <Editor
                                            editorState={editorState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor editorHTML"
                                            onEditorStateChange={this.onEditorStateChange}
                                            onChange={onEditorHTML}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                                                inline: {
                                                    inDropdown: false,
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                                                    bold: { className: undefined },
                                                    italic: { className: undefined },
                                                    underline: { className: undefined },
                                                    strikethrough: { className: undefined },
                                                    monospace: { className: undefined },
                                                    superscript: { className: undefined },
                                                    subscript: { className: undefined },
                                                },
                                                blockType: {
                                                    inDropdown: true,
                                                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                },
                                                fontSize: {
                                                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                },
                                                fontFamily: {
                                                    options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                },
                                                list: {
                                                    inDropdown: false,
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                    options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                    unordered: { className: undefined },
                                                    ordered: { className: undefined },
                                                    indent: { className: undefined },
                                                    outdent: { className: undefined },
                                                },
                                                textAlign: {
                                                    inDropdown: false,
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                    options: ['left', 'center', 'right', 'justify'],
                                                    left: { className: undefined },
                                                    center: { className: undefined },
                                                    right: { className: undefined },
                                                    justify: { className: undefined },
                                                },
                                                colorPicker: {
                                                    className: undefined,
                                                    component: undefined,
                                                    popupClassName: undefined,
                                                    colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                                        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                                        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                                        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                                        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                                        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                                                },
                                                link: {
                                                    inDropdown: false,
                                                    className: undefined,
                                                    component: undefined,
                                                    popupClassName: undefined,
                                                    dropdownClassName: undefined,
                                                    showOpenOptionOnHover: true,
                                                    defaultTargetOption: '_self',
                                                    options: ['link', 'unlink'],
                                                    link: { className: undefined },
                                                    unlink: { className: undefined },
                                                    linkCallback: undefined
                                                },
                                                emoji: {
                                                    className: undefined,
                                                    component: undefined,
                                                    popupClassName: undefined,
                                                    emojis: [
                                                        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                                        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                                        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                                        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                                        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                                        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                                        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                                        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                                        '✅', '❎', '💯',
                                                    ],
                                                },
                                                embedded: {
                                                    className: undefined,
                                                    component: undefined,
                                                    popupClassName: undefined,
                                                    embedCallback: undefined,
                                                    defaultSize: {
                                                        height: 'auto',
                                                        width: 'auto',
                                                    },
                                                },
                                                image: {
                                                    className: undefined,
                                                    component: undefined,
                                                    popupClassName: undefined,
                                                    urlEnabled: true,
                                                    uploadEnabled: true,
                                                    alignmentEnabled: true,
                                                    uploadCallback: undefined,
                                                    previewImage: false,
                                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                    alt: { present: true, mandatory: true },
                                                    defaultSize: {
                                                        height: 'auto',
                                                        width: 'auto',
                                                    },
                                                },
                                                remove: { className: undefined, component: undefined },
                                                history: {
                                                    inDropdown: false,
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                    options: ['undo', 'redo'],
                                                    undo: { className: undefined },
                                                    redo: { className: undefined },
                                                },
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item {...formItemLayout}
                                    label="Palabras Clave"
                                >
                                    {getFieldDecorator('palabraClave1', {
                                        rules: [{
                                            required: true, message: 'Favor de llenar el campo.',
                                        }]
                                    })(
                                        <Input prefix={<Icon type="container" style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={5000} placeholder="Ingrese una palabra clave" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item {...formItemLayout}
                                    label="Palabras Clave"
                                >
                                    {getFieldDecorator('palabraClave2', {
                                        rules: [{
                                            required: true, message: 'Favor de llenar el campo.',
                                        }]
                                    })(
                                        <Input prefix={<Icon type="container" style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={5000} placeholder="Ingrese otra palabra clave" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row type="flex" justify="space-around">
                            <Col xs={13} sm={14}>
                                <Form.Item {...formItemLayout}
                                    label="Categoría"
                                >
                                    {getFieldDecorator('categoria', {
                                        rules: [{
                                            required: true, message: 'Favor de llenar el campo.',
                                        }],
                                    })(
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Seleccionar"
                                            loading={fetchingGetCategorias}
                                        >
                                            {categorias.map(option => <Option key={option.idCategoria}>{option.descripcion}</Option>)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={10}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Imagen"
                                >
                                    <ImageUploading onChange={onChange}>
                                        {({ imageList, onImageUpload }) => (
                                            // write your building UI
                                            <div className="upload__image-wrapper">

                                                {this.state.uploadImagen === undefined || this.state.uploadImagen === false ? (
                                                    <Button type="primary" onClick={onImageUpload}>Upload images</Button>
                                                ) : (false)}

                                                {imageList.map(image => (
                                                    <div key={image.key} className="image-item">

                                                        <img alt='img' style={{ width: 90 }} src={image.dataURL} />
                                                        <Button type="danger" onClick={image.onRemove}><Icon type="delete" /></Button>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </ImageUploading>
                                </FormItem>
                            </Col>
                        </Row>

                        <Row type="flex" justify="space-around">
                            <Col xs={20} sm={18}>
                                <Button htmlType="submit" className="btnCrearNota" size="large" loading={fetchingCrearNota}>
                                    Crear Nota
                                </Button>
                            </Col>

                        </Row>
                    </Form>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {

    return {
        fetchingCrearNota: state.AdministradorReducer.fetchingCrearNota,
        fetchingGetCategorias: state.AdministradorReducer.fetchingGetCategorias,
        categorias: state.AdministradorReducer.categorias,
        listNombresEmpleados: state.AdministradorReducer.listNombresEmpleados
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestCategorias: () => {
            dispatch({ type: "GET_CATEGORIAS_REQUEST" });
        },
        onRequestCrearNota: (formulario, htmlEditor, fileList) => {
            dispatch({ type: "CREAR_NOTA_REQUEST", formulario: formulario, htmlEditor: htmlEditor, fileList });
        },
        onShowErrorMsg: () => {
            dispatch({ type: "SHOW_ERROR_MSG", showErrorMsg: false, textMessage: null });
        },
        onRequestListEmpleadosActivos: () => {
            dispatch({ type: "EMPLEADOS_ACTIVOS_REQUEST" });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(CrearNota));