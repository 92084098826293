import React, { Component } from 'react'
import { Menu, Icon, Col, Row, Dropdown } from 'antd'
import '../stylesheets/Header.css'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import logo from '../assets/pictures/LOGOTIPO-COLOR.png'
const { SubMenu } = Menu

class Header extends Component {
  state = {
    width: 0,
    height: 0
  }

  componentDidMount() {
    this.props.onRequestCategorias()
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render() {
    // const { categorias } = this.props

    const menu = (
      <Menu>
        <Menu.Item>
          <Link to='/'>
            <Col style={{ textAlign: 'center', color: '#a82433', fontWeight: 700 }}>Ir a inicio</Col>
          </Link>
        </Menu.Item>
        <Menu.Item key='quienesSomos'>
          <Link to='/QuienesSomos' style={{ color: '#a82433', fontWeight: 700 }}>
            Quiénes Somos
          </Link>
        </Menu.Item>
        <SubMenu key='sub1' title={'Servicios'}>
          <Menu.ItemGroup key='grupoSomos'>
            <Menu.Item key='grupoSomos2' style={{ color: '#a82433', fontWeight: 700 }}>
              <Link style={{ color: '#a82433', fontWeight: 700 }} to='/SISSADigital'>
                SISSA Digital
              </Link>
            </Menu.Item>
            <Menu.Item key='grupoSomos3' style={{ color: '#a82433', fontWeight: 700 }}>
              <Link style={{ color: '#a82433', fontWeight: 700 }} to='/SISSAInfraestructura'>
                SISSA Infraestructura
              </Link>
            </Menu.Item>
            <Menu.Item key='grupoSomos4' style={{ color: '#a82433', fontWeight: 700 }}>
              <Link style={{ color: '#a82433', fontWeight: 700 }} to='/SISSAMonitoring'>
                SISSA Monitoring
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        {/* <SubMenu key="subCategorias" title="Sectores">
                    {categorias.map(item =>
                        <Menu.ItemGroup key="grupoCategorias">
                            {item.idCategoria !== 23 && item.idCategoria !== 25 && item.idCategoria !== 26 ? (
                                <Menu.Item key={item.idCategoria}><a style={{ color: "#a82433", fontWeight: 700 }} href={"/Categorias/" + item.idCategoria} >
                                    {item.descripcion}
                                </a></Menu.Item>
                            ) : (false)}
                        </Menu.ItemGroup>
                    )}
                </SubMenu> */}
        {/* <Menu.Item key="ligaspartners">
                    <Link to="/Partners" style={{ color: "#a82433", fontWeight: 700 }} >
                        Partners
                    </Link>
                </Menu.Item> */}
        <Menu.Item key='ligascontacto'>
          <Link to='/Contacto' style={{ color: '#a82433', fontWeight: 700 }}>
            Contacto
          </Link>
        </Menu.Item>
        <Menu.Item key='ligasblog'>
          <Link to='/blogPrincipal' style={{ color: '#a82433', fontWeight: 700 }}>
            {' '}
            Blog
          </Link>
        </Menu.Item>
        <SubMenu key='subProductos' title={'Productos'}>
          <Menu.ItemGroup key='ligasPeaje'>
            <Menu.Item key='SolucionesPeaje' style={{ color: '#a82433', fontWeight: 700 }}>
              <Link style={{ color: '#a82433', fontWeight: 700 }} to='/peaje'>
                Soluciones Peaje
              </Link>
            </Menu.Item>
            <Menu.Item key='ligasIixma' style={{ color: '#a82433', fontWeight: 700 }}>
              <Link style={{ color: '#a82433', fontWeight: 700 }} to='/Ixmaki'>
                Soluciones IXMAKI
              </Link>
            </Menu.Item>
            <Menu.Item key='ligasVSS' style={{ color: '#a82433', fontWeight: 700 }}>
              <Link style={{ color: '#a82433', fontWeight: 700 }} to='/VSS'>
                Soluciones VSS
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
      </Menu>
    )

    const menu3 = (
      <Menu>
        <Menu.Item key='SISSADigital' style={{ color: '#a82433', fontWeight: 700 }}>
          <Link style={{ color: '#a82433', fontWeight: 700 }} to='/SISSADigital'>
            SISSA Digital
          </Link>
        </Menu.Item>
        <Menu.Item key='SISSAInfraestructura' style={{ color: '#a82433', fontWeight: 700 }}>
          <Link style={{ color: '#a82433', fontWeight: 700 }} to='/SISSAInfraestructura'>
            SISSA Infraestructura
          </Link>
        </Menu.Item>
        <Menu.Item key='SISSAMonitoring' style={{ color: '#a82433', fontWeight: 700 }}>
          <Link style={{ color: '#a82433', fontWeight: 700 }} to='/SISSAMonitoring'>
            SISSA Monitoring
          </Link>
        </Menu.Item>
      </Menu>
    )

    const menu5 = (
      <Menu>
        <Menu.Item key='SubPeaje'>
          <Link style={{ color: '#a82433', fontWeight: 700 }} to='/peaje'>
            Soluciones Peaje
          </Link>
        </Menu.Item>
        <Menu.Item key='SubIxmaki' style={{ color: '#a82433', fontWeight: 700 }}>
          <Link style={{ color: '#a82433', fontWeight: 700 }} to='/Ixmaki'>
            Soluciones IXMAKI
          </Link>
        </Menu.Item>
        <Menu.Item key='SubVSS' style={{ color: '#a82433', fontWeight: 700 }}>
          <Link style={{ color: '#a82433', fontWeight: 700 }} to='/VSS'>
            Soluciones VSS
          </Link>
        </Menu.Item>
      </Menu>
    )

    // const menu4 = (
    //     <Menu>
    //         {categorias.map(item =>
    //             <Menu.ItemGroup key="grupoCategoriasOtro">
    //                 {item.idCategoria !== 23 && item.idCategoria !== 25 && item.idCategoria !== 26 ? (
    //                     <Menu.Item key={'menu4' + item.idCategoria + 'otro'}><a style={{ color: "#a82433", fontWeight: 700 }} href={"/Categorias/" + item.idCategoria} >
    //                         {item.descripcion}
    //                     </a></Menu.Item>
    //                 ) : (false)}
    //             </Menu.ItemGroup>
    //         )}
    //     </Menu>
    // );

    return (
      <div className='FixedHeader' style={{ background: '#ffffff', fontFamily: 'Roboto, sans-serif' }}>
        {this.state.width >= 1000 ? (
          <div>
            <Row>
              <Col span={5} offset={2} style={{ marginTop: 10 }}>
                <Link to='/'>
                  <img alt='LogoTejiendoRedes' src={logo} className='logo-simple2' />
                </Link>
              </Col>
              <Col span={15} offset={0}>
                <Menu mode='horizontal' style={{ marginTop: 15 }}>
                  <Menu.Item key='1'>
                    <Link to='/' style={{ color: '#a82433', fontWeight: 700 }}>
                      Inicio
                    </Link>
                  </Menu.Item>
                  <Menu.Item key='9'>
                    <Link to='/QuienesSomos' style={{ color: '#a82433', fontWeight: 700 }}>
                      Quiénes Somos
                    </Link>
                  </Menu.Item>
                  <Menu.Item key='2'>
                    <Dropdown overlay={menu3}>
                      <span style={{ color: '#a82433', fontWeight: 700 }}>
                        Servicios
                        <Icon type='down' />
                      </span>
                    </Dropdown>
                  </Menu.Item>
                  {/* <Menu.Item key="3">
                                        <Dropdown overlay={menu4}>
                                            <Link to="/blogPrincipal" style={{ color: "#a82433", fontWeight: 700 }}>Sectores<Icon type="down" /></Link>
                                        </Dropdown>
                                    </Menu.Item>
                                    <Menu.Item key="4">
                                        <Link to="/Partners" style={{ color: "#a82433", fontWeight: 700 }}>Partners</Link>
                                    </Menu.Item> */}
                  <Menu.Item key='5'>
                    <Link to='/Contacto' style={{ color: '#a82433', fontWeight: 700 }}>
                      Contacto
                    </Link>
                  </Menu.Item>
                  <Menu.Item key='6'>
                    <Link to='/blogPrincipal' style={{ color: '#a82433', fontWeight: 700 }}>
                      Blog
                    </Link>
                  </Menu.Item>
                  <Menu.Item key='8'>
                    <Dropdown overlay={menu5}>
                      <span style={{ color: '#a82433', fontWeight: 700 }}>
                        Productos
                        <Icon type='down' />
                      </span>
                    </Dropdown>
                  </Menu.Item>
                </Menu>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <div className='logo' />
            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
              <Col
                className='gutter-row'
                lg={{ span: 6 }}
                md={{ span: 4, offset: 1 }}
                xs={{ span: 4, offset: 1 }}
                style={{ marginTop: 5, marginLeft: 5 }}
              >
                <div>
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Icon type='menu' className='iconoMenu' />
                  </Dropdown>
                </div>
              </Col>
              <Col
                className='gutter-row'
                lg={{ span: 12 }}
                md={{ span: 7, offset: 4 }}
                xs={{ span: 9, offset: 4 }}
                style={{ marginTop: 5 }}
              >
                <div>
                  <img alt='LogoTejiendoRedes' src={logo} className='logo-responsive' />
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    categorias: state.AdministradorReducer.categorias
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestCategorias: () => {
      dispatch({ type: 'GET_CATEGORIAS_REQUEST' })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
