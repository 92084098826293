import React, { Component } from 'react';
import { Col, Row } from 'antd';
import '../../stylesheets/dashboard/peaje.css';
import HeaderHome from '../../Components/HeaderHome';
import Footer from '../../Components/Dashboard/Footer';
import { Helmet } from 'react-helmet';

class VSS extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>VSS</title>
                    <meta name='viewport' content='width=device-width, initial-scale=1' />
                    <meta name='theme-color' content='#000000' />
                    <meta property='og:title' content='Solución VSS' />
                    <meta property='og:type' content='article' />
                    <meta property='og:url' content='https://www.sissamx.com.mx/peaje' />
                    <meta name='description' content='Plataforma de integración capaz de comunicarse con múltiples tecnologías gracias a que soporta palabras clave de comunicación' />
                </Helmet>
                <HeaderHome />
                <br />
                <br />
                <Row className=''>
                    <br />
                    <br />
                    <br />
                    <h1 className='tituloPagina'>VSS: plataforma de integración de tecnologías</h1>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }}>
                        <p className='letrasQuienes2'>VSS, solución creada y desarrollada por SISSA Digital, es una plataforma de integración capaz de comunicarse con múltiples tecnologías gracias a que soporta palabras clave de comunicación como los protocolos industriales BACnet, Modbus, TCP/IP y SNMP, así como los protocolos propietarios para PLCs como Allen-Bradley, OMRON, Yaskawa y ABB, entre otros.</p>
                        <p className='letrasQuienes2'>En otras palabras, VSS es una plataforma multiprotocolos que, a través de un ambiente gráfico y de manera centralizada, permite vincular tales protocolos para controlar y operar múltiples acciones de monitoreo y control, incluyendo lo relacionado con control de accesos, VMS y sanidad de dispositivos tecnológicos.</p>
                    </Col>
                </Row>
                <br />
                <br />
                <Row className=''>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 10, offset: 1 }}>
                        <h2 className='tituloQuienes'> VSS (Vector SCADA System)</h2>
                        <p className='letrasQuienes2'>Esta plataforma está basada en el sistema SCADA (Supervisory Control And Data Acquisition), el cual utiliza protocolos industriales. Sin embargo, VSS es una solución abierta de integración tecnológica basada en la arquitectura de AVEVA (Wonderware) de Snyder para la implementación de más protocolos de comunicación y la creación de reglas de negocio particulares que respondan a las necesidades específicas de los diferentes entornos, incluyendo (además de los industriales) hospitales, refinerías, estacionamientos y edificios inteligentes, entre muchos otros.</p>
                        <p className='letrasQuienes2'>Además, a pesar de que nos basamos en la arquitectura de AVEVA, en SISSA Digital podemos personalizar nuestra solución más allá de lo que dicha plataforma ofrece de acuerdo con los requerimientos de cada usuario, al grado de modificar su base de datos para aumentar sus niveles de seguridad, aumentar la carga de backend para aumentar la robustez del procesamiento de datos, comunicarse directamente con la base de datos (biométricos y generales) de un sistema de gestión de identidad o, incluso, embeber video de diferentes fuentes para acciones de VMS.</p>
                    </Col>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                        <h2 className='tituloQuienes'> Una solución multifuncional</h2>
                        <p className='letrasQuienes2'>Con VSS es posible monitorear el estado y funcionamiento de las tecnologías utilizadas en una infraestructura (desde las redes hasta los dispositivos de mayor prioridad), interactuar con el BMS para acciones de automatización, enlazar correlaciones de eventos, controlar alertas a partir de analíticas de video, entre muchas otras operaciones, dependiendo de los objetivos de cada empresa u organización.</p>
                        <p className='letrasQuienes2'>Esta plataforma de integración toma las bondades de SCADA en el mundo industrial y, con ayuda de tecnología de Microsoft, las lleva a cualquier sector de manera personalizada y fácil de usar, logrando comunicarse no solo con los PLCs, sino también directamente con las máquinas.</p>
                        <p className='letrasQuienes2'>Además, al ser una solución desarrollada SISSA Digital, tenemos la facilidad de implementar componentes creados por nosotros mismos que nos permitan integrar, de una manera rápida y efectiva, diferentes tecnologías. </p>
                    </Col>
                </Row>
                <Row className=''>
                    <br />
                    <h2 className='tituloQuienes'>SISSA Digital</h2>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }}>
                        <p className='letrasQuienes2'>Nuestras soluciones digitales de innovación buscan optimizar y aportar valor a múltiples procesos en diferentes entornos bajo tres conceptos básicos de la transformación digital: desarrollo de tecnologías específicas, implementación de estrategias digitales e integración de soluciones digitales. Si te interesa obtener más información sobre nuestra plataforma integral para el cobro de peaje, no dudes en contactarnos por cualquiera de nuestros canales de comunicación y con gusto te atenderemos.</p>
                    </Col>
                </Row>
                <br />
                <br />
                <Footer />
            </div>
        );
    }
}

export default VSS;
