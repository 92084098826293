import React, { Component } from 'react';
import '../../../stylesheets/Pages/avisoPrivacidad.css';
import Footer from '../../Dashboard/Footer';
import Header from '../../HeaderHome';


class AvisoPrivacidad extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return(
            <div>
                <Header/>
                    <main>  
                        <section id="avisoPrivacidad" className=" align-items-center">
                            <div id="description-container" data-aos="fade-up">
                                <div className="container justify-content-center">
                                    <div className="row flex-direction-column align-items-center nf404-text pt-4">                
                                            <p className="font-white title-1">
                                                <span  > POLÍTICA </span> 
                                                <span className="font-weight-light">DE PRIVACIDAD</span>                       
                                            </p>   
                                    </div>        
                                </div>
                            </div>    

                            <div className="container" id="textContainer" >
                           
<p>Responsable del tratamiento de sus Datos Personales.</p>

<p>
Informamos al destinatario de este Aviso de Privacidad que SISSA Monitoring Integral S.A. de C.V.., con domicilio en Pilares 768, Col. Letran Valle, C.P. 03650, Ciudad de México, es responsable del uso y protección de sus Datos Personales, los cuales serán tratados con base en los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad en términos de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, (en lo sucesivo La Ley) y al respecto le informamos lo siguiente.
El presente Aviso de Privacidad que SISSA Monitoring Integral S.A. de C.V. pone a su disposición, pretende informarle sobre el tratamiento que se dará a sus Datos Personales cuando los mismos sean recabados, utilizados, almacenados, transmitidos y/o transferidos a través de medios electrónicos, físicos o por vía telefónica, de manera verbal y/o escrita. 
</p>
<p>
Finalidades del tratamiento de los Datos Personales.
</p>
<p>
SISSA Monitoring Integral S.A. de C.V. recabará, utilizará, almacenará, transmitirá y/o transferirá sus Datos Personales, con las finalidades siguientes: (Finalidades necesarias) Acreditar la identidad del proveedor y/o de su representante legal; celebrar en su caso el contrato de prestación de servicios, obra, suministro, o cualquier otro contrato; operación y registro de los proyectos que tenga contratados con nosotros, proveer los servicios requeridos por usted; dar cumplimiento a obligaciones contraídas con usted, Informar sobre cambios o nuevos servicios que estén relacionados con lo contratado por usted; gestionar la relación comercial con el proveedor incluyendo su proceso de contratación, gestión de órdenes de contratación o compra; elaboración de presupuestos o cotizaciones, cobranza, recepción y pago de facturas y/o recibos de honorarios. (Finalidades no necesarias) desarrollar nuevos productos y servicios, Realizar estudios internos sobre hábitos de consumo de nuestros clientes; verificar la información que nos proporciona e Integrar y mantener actualizada una base de datos de proveedores; tratamiento con fines de mercadotecnia, publicidad o prospección comercial.
</p>
<p>
-Si usted no manifiesta su negativa para que sus datos personales sean tratados con finalidades no necesarias, se entenderá que ha otorgado su consentimiento para ello. No obstante, lo anterior, usted puede manifestar su negativa para el tratamiento de sus datos personales para aquellas finalidades que no son necesarias en la relación jurídica con Usted, mediante el mecanismo que se describen:
</p>
<p>
Usted puede manifestar su negativa para el tratamiento de sus datos personales para aquellas finalidades que no son necesarias para la relación jurídica con usted, El mecanismo para manifestar su negativa se llevará a cabo mediante un escrito libre debidamente firmado, en el cual usted deberá manifestar su negativa u oposición a dicho tratamiento y enviarlo a  contacto@sissamx.com
</p>
<p>
La negativa para el uso de sus datos personales no será motivo para que SISSA Monitoring Integral S.A. de C.V. niegue los servicios y/o productos que solicita o contrata. Usted deberá proporcionar copia de su identificación oficial (credencial de elector, pasaporte, licencia de manejo o cédula profesional), misma que deberá enviar junto con su petición.
SISSA Monitoring Integral S.A. de C.V. podrá hacer uso de sus datos personales para otras finalidades, siempre y cuando dichas finalidades sean compatibles y puedan considerarse análogas a las anteriores.
</p>
<p>
Datos Personales que se recaban.
</p>
<p>
Para las finalidades antes señaladas, SISSA Monitoring Integral S.A. de C.V. podrá recabar y tratar los Datos Personales que a continuación se especifican, con base en el vínculo sostenido con Usted: nombre completo, identificación oficial con fotografía, domicilio, ocupación, nacionalidad, forma migratoria (en su caso), cédula profesional, correo electrónico, teléfono particular, teléfono de oficina, celular, RFC, CURP y/o cualquier otra documentación o información que se relacione con la relación que exista entre SISSA Monitoring Integral S.A. de C.V. y Usted.
</p>
<p>
Además, podrá recabar y tratar Datos Financieros como nombre del titular de la cuenta bancaria, nombre de la institución bancaria, número de cuenta bancaria, número de clabe bancaria y estados de cuenta. 
 </p>
 <p>
Integridad de sus Datos Personales. 
</p>
<p>
SISSA Monitoring Integral S.A. de C.V. implementará las medidas de seguridad, administrativas, técnicas y físicas, necesarias para procurar la integridad de sus Datos Personales y evitar su alteración, pérdida, daño, destrucción o el uso, acceso o tratamiento o divulgación a terceros no autorizado. SISSA Monitoring Integral S.A. de C.V. se asegurará de que dichos terceros mantengan medidas de seguridad, administrativas, técnicas y físicas adecuadas para resguardar sus datos personales, así como que dichos terceros únicamente utilicen sus datos personales para las finalidades para los cuales fueron recabados y de conformidad con el presente Aviso de Privacidad. 
</p>
<p>
Transferencia de Datos Personales.
</p>
<p>
SISSA Monitoring Integral S.A. de C.V. podrá compartir con terceros, nacionales o extranjeros, algunos o todos sus Datos Personales. Dichos terceros podrán ser compañías matrices, afiliadas o subsidiarias de SISSA Monitoring Integral S.A. de C.V.; asesores profesionales externos; y/u otros prestadores que ofrezcan servicios, para poder darle el servicio que usted está solicitando, elaborar contratos, convenios y documentos de carácter legal y administrativo. - Si usted no manifiesta su oposición a que sus datos personales sean transferidos, se entenderá que ha otorgado su consentimiento para ello, salvo las excepciones previstas en la Ley. El objeto de la transferencia se limita a las finalidades antes descritas, en el entendido que se hará del conocimiento de los terceros o encargados, a quien sean transferidos sus datos, las finalidades a las que usted sujetó su tratamiento. 
</p>
<p>
Derechos ARCO
</p>
<p>
Usted tiene derecho a conocer qué Datos Personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a la Ley (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, usted podrá ingresar al PROCEDIMIENTO PARA EL EJERCICIO DE DERECHOS ARCO.
</p>
<p>
Su solicitud será respondida indicándole si la solicitud de acceso, rectificación, cancelación u oposición es procedente y en su caso, SISSA Monitoring Integral S.A. de C.V. hará efectiva la determinación. 
La temporalidad del tratamiento de sus Datos Personales será por el plazo que en su caso subsista la relación comercial entre usted y SISSA Monitoring Integral S.A. de C.V., mismo que podrá ampliarse en términos de Ley.
</p>
<p>
Revocación y limitación del consentimiento.
</p>
<p>
Usted podrá revocar, en todo momento, el consentimiento que haya otorgado y que fuese necesario para el tratamiento de sus Datos Personales, con excepción de lo establecido en el artículo 10 de la Ley; siguiendo el procedimiento que se indica en el siguiente PROCEDIMIENTO PARA REVOCAR EL CONSENTIMIENTO considerar además que para ciertos fines, la revocación de su consentimiento implicará que no podamos seguir brindando el servicio solicitado, o implicará la conclusión de su relación con nosotros.
</p>

<p>
Así también podrá solicitar que se limite el uso o divulgación de sus Datos Personales. Lo anterior a través del envío de su petición a SISSA Monitoring Integral S.A. de C.V., quien resolverá lo conducente conforme al procedimiento indicado en el PROCEDIMIENTO PARA LIMITAR EL USO DE DATOS PERSONALES.
</p>
<p>
Si usted considera que su derecho de protección de datos personales ha sido lesionado o si usted presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podrá interponer la queja o denuncia correspondiente ante el Instituto Federal de Acceso a la Información y Protección de Datos, para mayor información visite  
</p>

 <p>
Atentamente
SISSA Monitoring Integral S.A. de C.V.
</p>

                            </div>                     
                        </section>
                    </main>
  
                <Footer/>
        </div>
    );
    }
}

export default AvisoPrivacidad;
