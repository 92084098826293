import React, { Component } from 'react';
import '../../../stylesheets/Pages/mainI.css';
import Footer from '../../Dashboard/Footer';
import Header from '../../HeaderHome';
import logoFB from '../../../assets/img/footer/fb.png';
import logoTW from '../../../assets/img/footer/tw.png';
import logoYT from '../../../assets/img/footer/yt.png';
import logoIN from '../../../assets/img/footer/in.png';
import logoIG from '../../../assets/img/footer/ig.png';

import '../Pages/svg-assets/vertientes/SVG_vertientes.css';
import ListSubsections from '../Pages/sissa-digital/List-desktop';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';


class Sissa_digital extends Component {




    render() {
        const scriptNosotrosBanner = document.createElement("script");
        scriptNosotrosBanner.src = "../assets/js/nosotros/banner.js";
        scriptNosotrosBanner.async = true;

        document.body.appendChild(scriptNosotrosBanner);





        return (


            <div>
                <MetaTags>
                    <meta name="description" content="Enfocados en el mundo digital, desarrollamos y trabajamos con las herramientas digitales que nuestros clientes necesitan para obtener mayor competitividad en el contexto global actual." />
                </MetaTags>

                <Header />

                <main id="sissa-digital">

                    <div id="carouselNosotros" className="carousel slide">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselNosotros" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselNosotros" data-slide-to="1" className="active"></li>
                        </ol>

                        {/* <!-- carousel content --> */}
                        <div className="carousel-inner">

                            {/* <!-- first slide --> */}
                            <div id="somos-aliados-slide" className="carousel-item active" >
                                <div className="yk-carousel-caption d-flex flex-column justify-content-center h-90vh">
                                    <div id='somos-aliados-text' className="d-flex flex-column  justify-content-center h-90vh">
                                        <span className="gray-bold-title1 mt-auto" data-animation="animated bounceInLeft">
                                            SOMOS ALIADOS
                                        </span>
                                        <span className="red-thin-title1" data-animation="animated bounceInRight" >
                                            DE LA TRANSFORMACIÓN
                                        </span>

                                        <span className="red-bold-title1" data-animation="animated bounceInLeft">
                                            DIGITAL
                                        </span>

                                        <div className="d-flex  red-rrss-container mt-auto pb-5" data-animation="animated bounceInRight">
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/SISSAMX/"><img className="footer-rrss-logo fb-logo" src={logoFB} alt="facebook" /></a>
                                            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/SISSA_MX/"><img className="footer-rrss-logo tw-logo" src={logoTW} alt="twitter" /></a>
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC6NAyWKACq8AwWuYA1B94yg"><img className="footer-rrss-logo yt-logo" src={logoYT} alt="youtube" /></a>
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/sissa-monitoring-integral-sa-de-cv/"><img className="footer-rrss-logo in-logo" src={logoIN} alt="linkedin" /></a>
                                            <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/sissa_mx"><img className="footer-rrss-logo ig-logo" src={logoIG} alt="instagram" /></a>
                                            <span className="at_sissamx"> @SISSAMX</span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* <!-- second slide --> */}
                            <div id="desarrollamos-soluciones-slide" className="carousel-item" >
                                <div className="yk-carousel-caption d-flex flex-column justify-content-center h-90vh">
                                    <div id='desarrollamos-soluciones-text' className="d-flex flex-column  justify-content-center h-90vh">
                                        <div className="title1" data-animation="animated bounceInLeft">
                                            DESARROLLAMOS SOLUCIONES
                                        </div>
                                        <div className="title2" data-animation="animated bounceInRight" >
                                            DIGITALES DE INNOVACIÓN COMO:
                                        </div>

                                        <div className="red-background-title" data-animation="animated bounceInLeft">
                                            SOFTWARE A LA MEDIDA, APPS MÓVILES Y WEB, CMS Y CRM,
                                            MIGRACIONES Y ASESORAMIENTO ESTRATÉGICO
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <!-- controls --> */}
                        <a className="carousel-control-prev" href="#carouselNosotros" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselNosotros" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>

                    </div>



                    <section id="nosotros-short-description" className="d-flex align-items-center" data-aos="fade-up">

                        <div className="container">
                            <div className="row">
                                <div id="short-description-text">
                                    <p className="mb-0 section-text">
                                        <span className="capital-text color-sissa-p-red text-uppercase">LA TRANSFORMACIÓN ES NUESTRO OBJETIVO DE NEGOCIO. </span>
                                        Enfocados en el mundo digital, desarrollamos y trabajamos con las herramientas digitales
                                        que nuestros clientes necesitan para obtener mayor competitividad en el contexto
                                        global actual.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="container separator-bottom">
                        <div className="row">
                            <div className="col-md-12 separator-line"></div>
                        </div>
                    </div>



                    <div className="container separator my-5"></div>


                    <ListSubsections />




                    <div className="call-to-action-vertientes container">
                        <Link to="/Contacto" className="btn-sissa-primary-red btn-lg">Obtener más información</Link>
                    </div>




                    <div className="container separator my-5"></div>




                </main>

                <Footer />
            </div>

        );
    }


    componentDidMount() {
        window.scrollTo(0, 0);
    }
}

export default Sissa_digital;