import React, { Component } from 'react';
import '../../stylesheets/Pages/mainI.css';
import logoFB from '../../assets/img/footer/fb.png';
import logoTW from '../../assets/img/footer/tw.png';
import logoYT from '../../assets/img/footer/yt.png';
import logoIN from '../../assets/img/footer/in.png';
import logoIG from '../../assets/img/footer/ig.png';
import imgSocios from '../../assets/img/footer/socios-footer.png';


class Footer extends Component {
  render() {
    return (
      <footer className="py-5">
        <div className="container d-flex">
          <div className="row footer-siguenos-socios d-flex" >
            <div className="d-flex siguenos col-md-6 justify-content-center">
              {/* <span><p>Síganos</p></span> */}
              <div className="d-flex  red-rrss-container">
                <a href="https://www.facebook.com/SISSAMX/" target="_blank" rel="noopener noreferrer"><img className="footer-rrss-logo fb-logo" src={logoFB} alt="facebook" /></a>
                <a href="https://twitter.com/SISSA_MX/" target="_blank" rel="noopener noreferrer"><img className="footer-rrss-logo tw-logo" src={logoTW} alt="twitter" /></a>
                <a href="https://www.youtube.com/channel/UC6NAyWKACq8AwWuYA1B94yg" target="_blank" rel="noopener noreferrer"><img className="footer-rrss-logo yt-logo" src={logoYT} alt="youtube" /></a>
                <a href="https://www.linkedin.com/company/sissa-monitoring-integral-sa-de-cv/" target="_blank" rel="noopener noreferrer"><img className="footer-rrss-logo in-logo" src={logoIN} alt="linkedin" /></a>
                <a href="https://instagram.com/sissa_mx" target="_blank" rel="noopener noreferrer"><img className="footer-rrss-logo ig-logo" src={logoIG} alt="instagram" /></a>
                <span className="at_sissamx"> @SISSAMX</span>
              </div>
            </div>
            <div className="socios-footer-container  col-md-5">
              <img className="img-fluid" alt="Socios" src={imgSocios} />
            </div>
          </div>
        </div>

        <div className="container-fluid separator-red-stripe">
          <div className="row">
            <div className="col-md-12"></div>
          </div>
        </div>
        <div className="container copyright ">
          <span>SISSA &#169;2020 Todos los derechos reservados</span>
          <span>
            <p>
              <a href="/AvisoPrivacidad">Aviso legal y política de privacidad</a>
            </p>
            <p>
              <a href="https://sissamx.com.mx/Nota/349/politicas-y-codigo-de-etica">
                Política de Seguridad y Salud
              </a>
            </p>
          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;