import React, { Component } from 'react';
import { Col, Row } from 'antd';
import '../../stylesheets/dashboard/peaje.css';
import logo from '../../assets/img/peaje/image001.jpg';
import logo2 from '../../assets/img/peaje/image002.png';
import logo3 from '../../assets/img/peaje/control-peaje.png';
import logo4 from '../../assets/img/peaje/qr-peaje.jpg';
import HeaderHome from '../../Components/HeaderHome';
import Footer from '../../Components/Dashboard/Footer';
import { Helmet } from 'react-helmet';

class QuienesSomos extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Solución Peaje</title>
                    <meta name='viewport' content='width=device-width, initial-scale=1' />
                    <meta name='theme-color' content='#000000' />
                    <meta property='og:title' content='Solución Peaje' />
                    <meta property='og:type' content='article' />
                    <meta property='og:url' content='https://www.sissamx.com.mx/peaje' />
                    <meta name='description' content='Plataforma integral para el cobro de peaje' />
                </Helmet>
                <HeaderHome />
                <br />
                <br />
                <Row className=''>
                    <br />
                    <br />
                    <br />
                    <h1 className='tituloPagina'>Plataforma integral para el cobro de peaje</h1>
                    <br />
                    <h2 className='tituloQuienes'>Objetivo</h2>
                    <br />
                    <br />
                    <Col xs={{ span: 19, offset: 2 }} lg={{ span: 6, offset: 1 }}>
                        <img alt='QuienesSomos' src={logo2} className='logo-quienes' />
                    </Col>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 15, offset: 1 }}>
                        <p className='letrasQuienes'>En SISSA Digital hemos creado y desarrollado un sistema integral de cobro de peaje, el cual permite realizar pagos electrónicos en las zonas de peaje carretero. Además, esta solución permite el envío de notificaciones de tránsito, alertas de emergencia vial, rutas y facturación.</p>
                    </Col>
                </Row>
                <Row className=''>
                    <br />
                    <br />
                    <h2 className='tituloQuienes'>Componentes que integra</h2>
                    <br />
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 13, offset: 1 }}>
                        <p className='letrasQuienes2'>Esta plataforma tecnológica cubre, de manera integral, tres principales componentes que permiten el óptimo funcionamiento de su sistema de cobro:</p>
                        <div className='letrasQuienes2'>
                            <ul>
                                <li>Control centralizado para la administración de información. </li>
                                <li>Aplicación móvil (disponible en iOS y Android).</li>
                                <li>Lectores físicos de códigos electrónicos (QR). </li>
                                <li>Tableros de información</li>
                                <li>Bitácoras de cada paso y cobro de peaje por usuario.</li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={{ span: 19, offset: 2 }} lg={{ span: 7, offset: 1 }}>
                        <img alt='QuienesSomos' src={logo} className='logo-quienes2' />
                    </Col>
                </Row>
                <Row className=''>
                    <br />
                    <br />
                    <h2 className='tituloQuienes'>¿Cómo funciona nuestro sistema de cobro de peaje?</h2>
                    <br />
                    <Col xs={{ span: 22, offset: 0 }} lg={{ span: 13, offset: 1 }}>
                        <div className='letrasQuienes2'>
                            <ul>
                                <li>El usuario conductor dispuesto a pagar peaje deberá descargar nuestra aplicación móvil desarrollada con tecnología Web App (compatible con smartphones, tabletas digitales, equipos de escritorio y laptops), la cual se ejecuta dentro del propio navegador web a través de una URL que permite crear un acceso directo en los dispositivos.</li>
                                <br />
                                <li>Luego de registrarse, registrar su vehículo y su método de pago, el usuario conductor generará, de manera intuitiva, un código QR personal que será mostrado en la zona de peaje (estación o caseta).</li>
                                <br />
                                <li>De esta manera, los lectores físicos capturarán e interpretarán el código mostrado por el usuario conductor, enviando la información de las transacciones y datos operacionales recabados por cada estación a un servidor de procesamiento para efectuar el cobro del peaje correspondiente y generar una bitácora sobre cada movimiento realizado.</li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={{ span: 16, offset: 3 }} lg={{ span: 8, offset: 1 }}>
                        <img alt='QuienesSomos' src={logo3} className='logo-quienes3' />
                    </Col>
                </Row>
                <Row className=''>
                    <br />
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                        <h2 className='tituloQuienes'>Módulo de alertas de tránsito</h2>
                        <p className='letrasQuienes2'>Asimismo, nuestra plataforma cuenta con un módulo de notificaciones y alertas de tránsito, el cual permite a los usuarios conductores recibir y generar alertas relacionadas con su viaje carretero, como accidentes, fallas mecánicas, cierres carreteros e inclemencias naturales, entre otros problemas de carácter vial.</p>
                    </Col>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                        <h2 className='tituloQuienes'>Perfiles de la plataforma</h2>
                        <p className='letrasQuienes2'>Nuestra plataforma integral para el cobro de peaje cuenta con tres perfiles distintos que facilitan las tareas que desempeña cada involucrado en la operación. Dichos perfiles funcionan a partir de interfaces independientes, lo que mejora su usabilidad y permite llevar un adecuado control sobre los procesos.</p>
                    </Col>
                </Row>
                <Row className=''>
                    <br />
                    <br />
                    <h2 className='tituloQuienes'>Perfiles de la plataforma</h2>
                    <Row>
                        <Col xs={{ span: 16, offset: 3 }} lg={{ span: 4, offset: 1 }}>
                            <img alt='QuienesSomos' src={logo4} className='logo-quienes4' />
                        </Col>
                        <Col xs={{ span: 22, offset: 0 }} lg={{ span: 17, offset: 1 }}>
                            <p className='letrasQuienes2'>Nuestra plataforma integral para el cobro de peaje cuenta con tres perfiles distintos que facilitan las tareas que desempeña cada involucrado en la operación. Dichos perfiles funcionan a partir de interfaces independientes, lo que mejora su usabilidad y permite llevar un adecuado control sobre los procesos.</p>
                            <div className='letrasQuienes2'>
                                <p>Administrador del sistema</p>
                                <ul>
                                    <li>Súper usuario del sistema.</li>
                                    <br />
                                </ul>
                                <p>Operador carretero</p>
                                <ul>
                                    <li>Accesos a los mapas carreteros.</li>
                                    <li>Acceso al aforo vehicular.</li>
                                    <li>Control de tránsito.</li>
                                    <li>Control de semáforos.</li>
                                    <li>Control de barreras electromecánicas.</li>
                                    <li>Control de alertas viales y notificaciones.</li>
                                    <br />
                                </ul>
                                <p>Usuarios conductores</p>
                                <ul>
                                    <li>Registro de acceso al sistema.</li>
                                    <li>Registro de vehículos.</li>
                                    <li>Registro de métodos de pago.</li>
                                    <br />
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Row>
                <Row className=''>
                    <br />
                    <h2 className='tituloQuienes'>SISSA Digital</h2>
                    <Col xs={{ span: 22, offset: 1 }} lg={{ span: 22, offset: 1 }}>
                        <p className='letrasQuienes2'>Nuestras soluciones digitales de innovación buscan optimizar y aportar valor a múltiples procesos en diferentes entornos bajo tres conceptos básicos de la transformación digital: desarrollo de tecnologías específicas, implementación de estrategias digitales e integración de soluciones digitales. Si te interesa obtener más información sobre nuestra plataforma integral para el cobro de peaje, no dudes en contactarnos por cualquiera de nuestros canales de comunicación y con gusto te atenderemos.</p>
                    </Col>
                </Row>
                <br />
                <br />
                <Footer />
            </div>
        );
    }
}

export default QuienesSomos;
