import React, { Component } from 'react'

class ConoceNuestroBlog extends Component {
  render() {
    return (
      <section id='home-blog' className=' align-items-center parallax-background'>
        <div id='description-container' data-aos='fade-up'>
          <div className='container d-flex justify-content-center'>
            <div className='row flex-direction-column align-items-center home-blog-text pt-4'>
              <h3 className='font-white'>
                <span className='font-weight-light'> Conozca más en nuestro blog</span>
              </h3>
            </div>
          </div>
        </div>
        <div className='container img-container' data-aos='fade-up'>
          <div className='row'>
            <div className='col-sm-4'>
              <figure>
                <a href='https://sissamx.com.mx/Nota/275/sissa-monitoring-integral:-integramos-soluciones,-entregamos-resultados'>
                  <img
                    src='assets/img/home/sissa-monitoring_2.png'
                    className='img-fluid hexagon-button-image'
                    alt='Integramos soluciones'
                  />
                </a>
                <figcaption className='sissa-font-gray'>
                  Integrador de sistemas tecnológicos dedicado a la creación de soluciones totalmente personalizadas
                  para el desarrollo de proyectos únicos en su clase.
                  <span className='ml-1'>
                    <a
                      className='font-italic color-sissa-p-red font-weight-bold'
                      href='https://sissamx.com.mx/Nota/275/sissa-monitoring-integral:-integramos-soluciones,-entregamos-resultados'
                      title='SISSA Monitoring Integral: Integradora de soluciones'
                    >
                      Leer más
                    </a>
                  </span>
                </figcaption>
              </figure>
              <div className='sissa-paragraph-end d-flex align-items-center'>
                <div className='h-line'></div>
                <span className='sissa-paragraph-end-hexagon'>&#x2B22;</span>
                <div className='h-line'></div>
              </div>
            </div>
            <div className='col-sm-4'>
              <figure>
                <a href='https://sissamx.com.mx/Nota/277/sissa-digital:-aliados-de-la-transformacion-digital'>
                  <img
                    src='assets/img/home/sissa-digital_2.png'
                    className='img-fluid hexagon-button-image'
                    alt='Enfocados en la transformacion digital'
                  />
                </a>
                <figcaption className='sissa-font-gray '>
                  Centro de desarrollo de soluciones digitales multi-industria especializado en la fabricación de
                  software a medida con base en tecnologías abiertas y de licencia.
                  <span className='ml-1'>
                    <a
                      className='font-italic color-sissa-p-red font-weight-bold'
                      href='https://sissamx.com.mx/Nota/277/sissa-digital:-aliados-de-la-transformacion-digital'
                      title='SISSA Digital: impulsando la transformación digital'
                    >
                      Leer más
                    </a>
                  </span>
                </figcaption>
              </figure>
              <div className='sissa-paragraph-end d-flex align-items-center'>
                <div className='h-line'></div>
                <span className='sissa-paragraph-end-hexagon'>&#x2B22;</span>
                <div className='h-line'></div>
              </div>
            </div>
            <div className='col-sm-4'>
              <figure>
                <a
                  href='https://sissamx.com.mx/Nota/279/sissa-infraestructura:-protegemos,-construimos-y-equipamos-tus-ideas'
                  title='SISSA Infraestructura: soluciones de protección'
                >
                  <img
                    src='assets/img/home/sissa-infraestructura_2.png'
                    className='img-fluid hexagon-button-image'
                    alt='Soluciones de proteccion y optimizacion'
                  />
                </a>
                <figcaption className='sissa-font-gray'>
                  Desarrolladora de proyectos llave en mano de infraestructura e ingeniería multidisciplinar para el
                  sector público y privado.
                  <span className='ml-1'>
                    <a
                      className='font-italic color-sissa-p-red font-weight-bold'
                      href='https://sissamx.com.mx/Nota/279/sissa-infraestructura:-protegemos,-construimos-y-equipamos-tus-ideas'
                      title='SISSA Infraestructura: soluciones de protección'
                    >
                      Leer más
                    </a>
                  </span>
                </figcaption>
              </figure>
              <div className='sissa-paragraph-end d-flex align-items-center'>
                <div className='h-line'></div>
                <span className='sissa-paragraph-end-hexagon'>&#x2B22;</span>
                <div className='h-line'></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ConoceNuestroBlog
