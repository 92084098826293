import React, { Component } from 'react';
import { Row, Col } from 'antd';
import '../stylesheets/dashboard/dashboard.css';
import Header from "../Components/HeaderHome";
import Footer from '../Components/Dashboard/Footer';
import BlogEspecifico from '../Components/Blog/BlogEspecifico';

class BlogDashboard extends Component {

    render() {

        return (
            <div style={{ background: '#f1f1f1' }}>
                <Header />
                <br />
                <br />
                <br />
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col style={{ marginLeft: 20 }} xs={23} md={23}>
                        <BlogEspecifico />
                    </Col>
                </Row>
                <br />
                <Footer />
            </div>
        );
    }
}

export default BlogDashboard;    