import React, { Component } from 'react';
import '../stylesheets/Login/Login.css';
import { Row, Col, Alert } from 'antd';

import LoginCmp from '../Components/Login/LoginForm';
import logo from '../assets/img/logo.png';

import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

class Login extends Component {
    render() {
        const { isAuthenticated, errorLogin } = this.props;

        if (isAuthenticated) {
            return <Redirect to='/dashboard' />;
        }

        function ErrorLoginAlert(props) {
            if (!props.errorLogin) {
                return null;
            }

            return <Alert message='Inicio de sesión inválida. Por favor intente de nuevo.' type='error' showIcon />;
        }

        return (
            <div className='login-form'>
                <Helmet>
                    <title>SISSA Monitoring Integral</title>
                    <meta name='viewport' content='width=device-width, initial-scale=1' />
                    <meta name='theme-color' content='#000000' />
                    <meta property='og:title' content='SISSA Monitoring Integral' />
                    <meta property='og:type' content='article' />
                    <meta property='og:url' content='https://www.sissamx.com.mx/login' />
                    <meta property='og:description' content='SISSA Monitoring Integral SAPI de CV. Soluciones integrales en el campo de las tecnologías de la información y seguridad electrónica.' />
                    <meta name='description' content='SISSA Monitoring Integral SAPI de CV. Soluciones integrales en el campo de las tecnologías de la información y seguridad electrónica.' />
                </Helmet>
                <br />
                <br />
                <br />
                <Row type='flex' justify='space-around'>
                    <Col xs={23} md={23}>
                        <img alt='Kali' src={logo} className='login-logo' />
                    </Col>
                </Row>
                <br />
                <br />
                <Row type='flex' justify='space-around'>
                    <Col span={9}>
                        <ErrorLoginAlert errorLogin={errorLogin} />
                    </Col>
                </Row>

                <Row type='flex' justify='space-around'>
                    <Col xs={23} sm={9}>
                        <LoginCmp />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.LoginReducer.isAuthenticated,
        errorLogin: state.LoginReducer.errorLogin,
    };
};

export default connect(mapStateToProps)(Login);
