import React, { Component } from 'react';
import { Form, Icon, Input, Row, Col, Modal, Spin } from 'antd';
import '../../stylesheets/dashboard/dashboard.css';

import { connect } from "react-redux";

const FormItem = Form.Item;

class ModalNuevoEmpleado extends Component {
    state = {
        confirmDirty: false,
    };

    normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    }
    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }
    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Las contraseñas no coinciden!');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { onShowNuevoEmpleadoModal,
            openNuevoEmpleadoModal,
            onRequestNuevoEmpleado,
            fetchingNuevoEmpleado,
        } = this.props;

        //console.log(perfiles);
        const handleOk = (e) => {
            e.preventDefault();
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    if (!fetchingNuevoEmpleado) {
                        onRequestNuevoEmpleado(values);
                        this.props.form.resetFields();
                    }
                }
            });
        }

        const handleCancel = () => {
            this.props.form.resetFields();
            onShowNuevoEmpleadoModal();
        }

        const buttonProps = {
            htmlType: "submit",
            loading: fetchingNuevoEmpleado,
        };

        const formItemLayout = {
            labelCol: {
                sm: { span: 0 },
            },
            wrapperCol: {
                sm: { span: 22 },
            },
        };

        return (
            <div>
                <Modal title="Nuevo Operador"
                    visible={openNuevoEmpleadoModal}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okButtonProps={buttonProps}
                    width={900}
                    okText="Ok"
                    cancelText="Cancelar"
                >
                    <div >
                        <Spin spinning={fetchingNuevoEmpleado}>
                            <Form>
                                <Row>
                                    <Col span={12}>
                                        <FormItem {...formItemLayout}
                                            extra=""
                                            label="Correo"
                                        >
                                            {getFieldDecorator('mail', {
                                                rules: [{
                                                    type: 'email', message: '¡No es un correo válido!',
                                                }, {
                                                    required: true, message: 'Favor de llenar el campo.',
                                                }],
                                            })(
                                                <div>
                                                    <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} placeholder="Email" />
                                                </div>
                                            )}
                                        </FormItem>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item {...formItemLayout}
                                            label="Nombre operador"
                                        >
                                            {getFieldDecorator('empleado', {
                                                rules: [{
                                                    required: true,
                                                    message: "Favor de llenar el campo."
                                                }]
                                            })(
                                                <div>
                                                    <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} placeholder={"Empleado"} />
                                                </div>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item {...formItemLayout}
                                            label="Contraseña"
                                        >
                                            {getFieldDecorator('password', {
                                                rules: [{
                                                    required: true,
                                                    message: "Favor de llenar el campo."
                                                }, {
                                                    validator: this.validateToNextPassword,
                                                }],
                                            })(
                                                <div>
                                                    <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} type="password" placeholder="Contraseña" />
                                                </div>

                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item {...formItemLayout}
                                            label="Confirmar contraseña"
                                        >
                                            {getFieldDecorator('confirm', {
                                                rules: [{
                                                    required: true,
                                                    message: 'Favor de confirmar la contraseña.'
                                                }, {
                                                    validator: this.compareToFirstPassword,
                                                }],
                                            })(
                                                <div>
                                                    <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={50} type="password" placeholder="Confirmar contraseña" onBlur={this.handleConfirmBlur} />
                                                </div>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {

    return {
        openNuevoEmpleadoModal: state.AdministradorReducer.openNuevoEmpleadoModal,
        fetchingNuevoEmpleado: state.AdministradorReducer.fetchingNuevoEmpleado,
        perfiles: state.AdministradorReducer.perfiles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowNuevoEmpleadoModal: () => {
            dispatch({ type: "OPEN_NUEVO_EMPLEADO_MODAL", openNuevoEmpleadoModal: false });
        },
        onRequestNuevoEmpleado: (formulario) => {
            dispatch({ type: "NUEVO_EMPLEADO_REQUEST", formulario: formulario });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ModalNuevoEmpleado));
