import React, { Component } from 'react'
import { Col, Row } from 'antd'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import '../../stylesheets/dashboard/casosExito.css'
import LOGO_GIA from '../../assets/logos/guia-logo-sin-fondo.png'
import LOGO_IDEAL from '../../assets/logos/ideal-sin-fondo.png'
import LOGO_PEMEX from '../../assets/logos/pemex-sin-fondo.png'
import LOGO_PRODEMEX from '../../assets/logos/prodemex-logo-sin-fondo.png'
import ITW from '../../assets/logos/ ITW Poly Mex.jpeg'
import BANCOMEXT from '../../assets/logos/Bancomext.gif'
import CANAL_ONCE from '../../assets/logos/canal-11-ipn.jpeg'
import GALLERIAS from '../../assets/logos/gallerias.jpeg'
import HGM from '../../assets/logos/hgm.jpeg'
import HONEYWELL from '../../assets/logos/honeywell.jpeg'
import MOTORDRIVE from '../../assets/logos/Motodrive.png'
import RC from '../../assets/logos/Refaccionarias California.jpeg'
import SCOTIABANK from '../../assets/logos/Scotiabank.png'
import SURMAN from '../../assets/logos/surman.png'
import UI from '../../assets/logos/UI.png'
import UNAM from '../../assets/logos/unam.png'

class CasosExito extends Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
      },
      mobile: {
        breakpoint: { max: 464, min: 376 },
        items: 2
      },
      mobileM: {
        breakpoint: { max: 375, min: 321 },
        items: 1.5
      },
      mobileS: {
        breakpoint: { max: 320, min: 0 },
        items: 1
      }
    }

    const images = [
      {
        alt: 'gia',
        src: LOGO_GIA
      },
      {
        alt: 'ideal',
        src: LOGO_IDEAL
      },
      {
        alt: 'pemex',
        src: LOGO_PEMEX
      },
      {
        alt: 'prodemex',
        src: LOGO_PRODEMEX
      },
      {
        alt: 'itw',
        src: ITW
      },
      {
        alt: 'bancomext',
        src: BANCOMEXT
      },
      {
        alt: 'canal-once',
        src: CANAL_ONCE
      },
      {
        alt: 'gallerias',
        src: GALLERIAS
      },
      {
        alt: 'hgm',
        src: HGM
      },
      {
        alt: 'honeywell',
        src: HONEYWELL
      },
      {
        alt: 'motordrive',
        src: MOTORDRIVE
      },
      {
        alt: 'rc',
        src: RC
      },
      {
        alt: 'scotiabank',
        src: SCOTIABANK
      },
      {
        alt: 'surman',
        src: SURMAN
      },
      {
        alt: 'ui',
        src: UI
      },
      {
        alt: 'unam',
        src: UNAM
      }
    ]

    return (
      <Row align='middle'>
        <Carousel
          transitionDuration={100}
          keyboard={false}
          responsive={responsive}
          infinite={true}
          autoPlay={this.props.deviceType !== 'mobile' ? true : false}
          autoPlaySpeed={3000}
          deviceType={this.props.deviceType}
        >
          {images.map(({ src, alt }) => (
            <Col lg={{ span: 18, offset: 1 }}>
              <article className='article-caso-exito'>
                <img alt={alt} src={src} className={alt} />
              </article>
            </Col>
          ))}
          {/* <Col lg={{ span: 18, offset: 1 }}>
            <img alt='img' src={LOGO_GIA} className='imagenCasoExito' />
          </Col>

          <Col lg={{ span: 18, offset: 1 }}>
            <img alt='img' src={LOGO_IDEAL} className='imagenCasoExito' />
          </Col>
          <Col lg={{ span: 18, offset: 1 }}>
            <img alt='img' src={LOGO_PEMEX} className='imagenCasoExito' />
          </Col>

          <Col lg={{ span: 18, offset: 1 }}>
            <img alt='img' src={LOGO_PRODEMEX} className='imagenCasoExito' />
          </Col> */}
        </Carousel>
      </Row>
    )
  }
}

export default CasosExito
