import React, { Component } from 'react';
import '../stylesheets/LandingPage/SISSALandingPage.css';
import Header from '../Components/HeaderHome';
import Footer from '../Components/Dashboard/Footer';
import SISSAMonitoring from '../Components/Info/SISSAMonitoring'
import { Col } from 'antd';

class SISSAMonitoringLandingPage extends Component {
  render() {
    return (
      <div className="dashboardFondo">
        <div>
          <Header />
          <br />
          <br />
          <Col span={24}>
            <SISSAMonitoring />
          </Col>
          <br />
          <Footer />
        </div>
      </div>
    );
  }
}


export default SISSAMonitoringLandingPage;

