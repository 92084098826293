import React, { Component } from 'react'

import lottie from 'lottie-web'
import vertientes_sd_json from '../../../animations/home/vertientes_sd.json'
import vertientes_sm_json from '../../../animations/home/vertientes_sm.json'
import vertientes_si_json from '../../../animations/home/vertientes_si.json'

class vertientes extends Component {
  componentDidMount() {
    var vertientes_sm_anim_container = document.getElementById('vertientes_sm_anim')
    var vertientes_sd_anim_container = document.getElementById('vertientes_sd_anim')
    var vertientes_si_anim_container = document.getElementById('vertientes_si_anim')

    vertientes_sm_anim_container.addEventListener('mouseenter', fEnter_sm)
    vertientes_sm_anim_container.addEventListener('mouseleave', fLeave_sm)

    vertientes_sd_anim_container.addEventListener('mouseenter', fEnter_sd)
    vertientes_sd_anim_container.addEventListener('mouseleave', fLeave_sd)

    vertientes_si_anim_container.addEventListener('mouseenter', fEnter_si)
    vertientes_si_anim_container.addEventListener('mouseleave', fLeave_si)

    var vertientes_sm_anim = lottie.loadAnimation({
      container: vertientes_sm_anim_container,
      animationData: vertientes_sm_json,
      renderer: 'svg',
      loop: false,
      autoplay: false
    })

    var vertientes_sd_anim = lottie.loadAnimation({
      container: vertientes_sd_anim_container,
      animationData: vertientes_sd_json,
      renderer: 'svg',
      loop: false,
      autoplay: false
    })

    var vertientes_si_anim = lottie.loadAnimation({
      container: vertientes_si_anim_container,
      animationData: vertientes_si_json,
      renderer: 'svg',
      loop: false,
      autoplay: false
    })

    // On Enter behavior
    function fEnter_sm() {
      vertientes_sm_anim.removeEventListener('mouseenter', fEnter_sm)
      vertientes_sm_anim.playSegments([0, 30], true)
      vertientes_sm_anim.addEventListener('mouseleave', fLeave_sm)
    }
    // On Leave behavior
    function fLeave_sm() {
      vertientes_sm_anim.removeEventListener('mouseleave', fLeave_sm)
      vertientes_sm_anim.playSegments([30, 60], true)
      vertientes_sm_anim.addEventListener('mouseenter', fEnter_sm)
    }

    // On Enter behavior
    function fEnter_sd() {
      vertientes_sd_anim.removeEventListener('mouseenter', fEnter_sd)
      vertientes_sd_anim.playSegments([0, 30], true)
      vertientes_sd_anim.addEventListener('mouseleave', fLeave_sd)
    }
    // On Leave behavior
    function fLeave_sd() {
      vertientes_sd_anim.removeEventListener('mouseleave', fLeave_sd)
      vertientes_sd_anim.playSegments([30, 60], true)
      vertientes_sd_anim.addEventListener('mouseenter', fEnter_sd)
    }

    // On Enter behavior
    function fEnter_si() {
      vertientes_si_anim.removeEventListener('mouseenter', fEnter_si)
      vertientes_si_anim.playSegments([0, 30], true)
      vertientes_si_anim.addEventListener('mouseleave', fLeave_si)
    }
    // On Leave behavior
    function fLeave_si() {
      vertientes_si_anim.removeEventListener('mouseleave', fLeave_si)
      vertientes_si_anim.playSegments([30, 60], true)
      vertientes_si_anim.addEventListener('mouseenter', fEnter_si)
    }
  }
  render() {
    return (
      <section id='vertientes' className=' align-items-center parallax-background'>
        <article id='description-container' data-aos='fade-up'>
          <div className='container justify-content-center'>
            <div className='row flex-direction-column align-items-center vertientes-text pt-4'>
              <div>
                <p className='text-white font-italic section-text'>
                  Conoce nuestro amplio abanico de servicios, divididos en tres líneas de negocio.
                </p>
              </div>
            </div>
          </div>
        </article>
        <div className='container img-container' data-aos='fade-up'>
          <div className='row'>
            <div className='col-sm-4 d-flex'>
              <a href='/SISSAMonitoring'>
                <div id='vertientes_sm_anim'></div>
              </a>

              <a href='/SISSAMonitoring' className='btn-sissa-secondary' data-animation='animated zoomInUp'>
                IR
              </a>
            </div>
            <div className='col-sm-4 d-flex'>
              <a href='/SISSADigital'>
                <div id='vertientes_sd_anim'></div>
              </a>

              <a href='/SISSADigital' className='btn-sissa-secondary' data-animation='animated zoomInUp'>
                IR
              </a>
            </div>
            <div className='col-sm-4 d-flex'>
              <a href='/SISSAInfraestructura'>
                <div id='vertientes_si_anim'></div>
              </a>

              <a href='/SISSAInfraestructura' className='btn-sissa-secondary' data-animation='animated zoomInUp'>
                IR
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default vertientes
