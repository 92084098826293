import React, { Component } from 'react';
import { Col, Card, Tabs } from 'antd';
import '../../stylesheets/dashboard/dashboard.css';
import ReactPlayer from 'react-player/youtube';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import 'moment/locale/es';
import { connect } from 'react-redux';

const { TabPane } = Tabs;

class BlogUno extends Component {
    render() {
        const { notaInfo, notasRelacionadaPalabraClave, notasRelacionadaCategoria } = this.props;
        moment.locale('es');
        let fecha = moment(notaInfo.fechaPub).format('LLLL');

        return (
            <div>
                {console.log('notaInfo.video >> ', notaInfo)}
                <Helmet>
                    <title>{notaInfo.titulo}</title>
                    <link rel='icon' type='image/jpg' href={notaInfo.imagen} />
                    <meta name='description' content={notaInfo.descripcion} />
                    <meta name='robots' content='noindex,nofollow' />
                    <meta property='og:title' content={notaInfo.titulo} />
                    <meta property='og:type' content='article' />
                    <meta property='og:url' content='https://www.sissamx.com.mx/blogPrincipal' />
                    <meta property='og:image' content={notaInfo.imagen} />
                    <meta name='viewport' content='width=device-width' />
                    <meta property='og:description' content={notaInfo.descripcion} />
                </Helmet>
                {notaInfo.categoriaByIdCategoria ? (
                    <Card>
                        <Col xs={24} md={17}>
                            <p>Autor: {notaInfo.autor}</p>
                            <h5 className='categoriaNota'>{notaInfo.categoriaByIdCategoria.descripcion}</h5>
                            <h1 className='tituloNota'>{notaInfo.titulo}</h1>
                            <p>{fecha}</p>
                            {notaInfo.video !== '' ? (
                                <div className='wrapper' style={{ marginBottom: 20 }}>
                                    <ReactPlayer className='player' playing url={notaInfo.video} width='100%' height='100%' />
                                </div>
                            ) : (
                                false
                            )}
                            <div className='textoEnNota' dangerouslySetInnerHTML={{ __html: notaInfo.texto }} style={{ fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif', fontSize: 20, color: 'black' }}></div>
                            <br />
                            {/*  <img alt='img' className="fotoNotaEspecifica" src={notaInfo.imagen}/> */}
                        </Col>

                        <Col xs={24} md={6} offset={1}>
                            <br />
                            <Card>
                                <Tabs defaultActiveKey='1' size={'large'} style={{ textAlign: 'center', marginLeft: 0, marginRight: 0 }}>
                                    <TabPane style={{ fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif' }} tab='Relacionados Categoría' key='1'>
                                        {notasRelacionadaCategoria[0] ? (
                                            <div>
                                                {notasRelacionadaCategoria.map((item) => (
                                                    <a href={'/Nota/' + item.idNota + '/' + item.listaUrl.tituloURL} key={item.idNota}>
                                                        {item.imagen === '' ? (
                                                            <Card bordered={false}>
                                                                <Col span={12}>
                                                                    <ReactPlayer className='imagenBlogRelacionado' width='100%' height='100%' url={item.video} />
                                                                </Col>
                                                                <Col span={11} offset={1}>
                                                                    <p className='descripcionBlogRelacionado'>{moment(item.fechaPub).calendar()}</p>
                                                                    <p className='tituloBlogRelacionado'>{item.titulo}</p>
                                                                </Col>
                                                            </Card>
                                                        ) : (
                                                            <Card bordered={false}>
                                                                <Col span={12}>
                                                                    <img alt='img' className='imagenBlogRelacionado' src={item.imagen} />
                                                                </Col>
                                                                <Col span={11} offset={1}>
                                                                    <p className='descripcionBlogRelacionado'>{moment(item.fechaPub).calendar()}</p>
                                                                    <p className='tituloBlogRelacionado'>{item.titulo}</p>
                                                                </Col>
                                                            </Card>
                                                        )}
                                                    </a>
                                                ))}
                                            </div>
                                        ) : (
                                            <div>
                                                <Card bordered={false}>
                                                    <p className='noHayRelacionados'>Por el momento no se encuentran notas relacionadas de esta categoría</p>
                                                </Card>
                                            </div>
                                        )}
                                    </TabPane>
                                    <TabPane style={{ fontFamily: 'Dosis-Light' }} tab='Relacionados Palabras Clave' key='2'>
                                        <Card bordered={false}>
                                            {notasRelacionadaPalabraClave[0] ? (
                                                <div>
                                                    {notasRelacionadaPalabraClave.map((item) => (
                                                        <a href={'/Nota/' + item.idNota + '/' + item.listaUrl.tituloURL} key={item.idNota}>
                                                            {item.imagen === '' ? (
                                                                <Card bordered={false}>
                                                                    <Col span={12}>
                                                                        <ReactPlayer className='imagenBlogRelacionado' width='100%' height='100%' url={item.video} />
                                                                    </Col>
                                                                    <Col span={11} offset={1}>
                                                                        <p className='descripcionBlogRelacionado'>{moment(item.fechaPub).calendar()}</p>
                                                                        <p className='tituloBlogRelacionado'>{item.titulo}</p>
                                                                    </Col>
                                                                </Card>
                                                            ) : (
                                                                <Card bordered={false}>
                                                                    <Col span={12}>
                                                                        <img alt='img' className='imagenBlogRelacionado' src={item.imagen} />
                                                                    </Col>
                                                                    <Col span={11} offset={1}>
                                                                        <p className='descripcionBlogRelacionado'>{moment(item.fechaPub).calendar()}</p>
                                                                        <p className='tituloBlogRelacionado'>{item.titulo}</p>
                                                                    </Col>
                                                                </Card>
                                                            )}
                                                        </a>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div>
                                                    <Card bordered={false}>
                                                        <p className='noHayRelacionados'>Por el momento no se encuentran notas relacionadas por palabras clave.</p>
                                                    </Card>
                                                </div>
                                            )}
                                        </Card>
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </Col>
                    </Card>
                ) : (
                    false
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notaInfo: state.AdministradorReducer.notaInfo,
        notasRelacionadaPalabraClave: state.AdministradorReducer.notasRelacionadaPalabraClave,
        notasRelacionadaCategoria: state.AdministradorReducer.notasRelacionadaCategoria,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRequestNota: (idNota, pagePalabraClave, pageCategoria) => {
            dispatch({ type: 'GET_NOTA_ESPECIFICA_REQUEST', idNota, pagePalabraClave, pageCategoria });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogUno);
