import React, {Component} from 'react';

import Redes from '../sissa-monitoring/Redes';
import ProteccionPerimetral from '../sissa-monitoring/ProteccionPerimetral';
import CCTV from '../sissa-monitoring/CCTV';
import ControlesAcceso from '../sissa-monitoring/ControlesAcceso';
import TecnologiasIdentificacion from '../sissa-monitoring/TecnologiasIdentificacion';
import RFID from '../sissa-monitoring/RFID';
import DataCenter from '../sissa-monitoring/DataCenter';
import SistemasGeolocalizacion from '../sissa-monitoring/SistemasGeolocalizacion';
import InhibicionCelular from '../sissa-monitoring/InhibicionCelular';
import AutomatizacionBMS from '../sissa-monitoring/AutomatizacionBMS';
import Particles from 'react-tsparticles';



class SissaInfraestructura_Contenido extends Component
{

    constructor() {    
        super();    
        this.state = {          
      
          showRedes: true,
          showProteccionPerimetral: false,
          showCCTV: false,
          showControlesAcceso: false,
          showTecnologiasIdentificacion: false,
          showRFID:false,
          showDataCenter:false,
          showSistemasGeolocalizacion:false,
          showInhibicionCelular: false,
          showAutomatizacionBMS: false       

        };
        this.showComponent = this.showComponent.bind(this);
      }

    

    
      showComponent = (name) => {
       

        switch (name) {
          case "redes":
            this.setState({                     
                showRedes: true,
                showProteccionPerimetral: false,
                showCCTV: false,
                showControlesAcceso: false,
                showTecnologiasIdentificacion: false,
                showRFID:false,
                showDataCenter:false,
                showSistemasGeolocalizacion:false,
                showInhibicionCelular: false,
                showAutomatizacionBMS: false       
                });
            break;
          case "proteccion":
            this.setState({
                showRedes: false,
                showProteccionPerimetral: true,
                showCCTV: false,
                showControlesAcceso: false,
                showTecnologiasIdentificacion: false,
                showRFID:false,
                showDataCenter:false,
                showSistemasGeolocalizacion:false,
                showInhibicionCelular: false,
                showAutomatizacionBMS: false  
                });
            break;
          case "cctv":
            this.setState({
                showRedes: false,
                showProteccionPerimetral: false,
                showCCTV: true,
                showControlesAcceso: false,
                showTecnologiasIdentificacion: false,
                showRFID:false,
                showDataCenter:false,
                showSistemasGeolocalizacion:false,
                showInhibicionCelular: false,
                showAutomatizacionBMS: false  
                });
            break;

            case "controlAcceso":
            this.setState({                     
                showRedes: false,
                showProteccionPerimetral: false,
                showCCTV: false,
                showControlesAcceso: true,
                showTecnologiasIdentificacion: false,
                showRFID:false,
                showDataCenter:false,
                showSistemasGeolocalizacion:false,
                showInhibicionCelular: false,
                showAutomatizacionBMS: false  
                });
            break;

            case "identificacion":
            this.setState({                     
                showRedes: false,
                showProteccionPerimetral: false,
                showCCTV: false,
                showControlesAcceso: false,
                showTecnologiasIdentificacion: true,
                showRFID:false,
                showDataCenter:false,
                showSistemasGeolocalizacion:false,
                showInhibicionCelular: false,
                showAutomatizacionBMS: false  
                });
            break;

            case "rfid":
            this.setState({                     
                showRedes: false,
                showProteccionPerimetral: false,
                showCCTV: false,
                showControlesAcceso: false,
                showTecnologiasIdentificacion: false,
                showRFID:true,
                showDataCenter:false,
                showSistemasGeolocalizacion:false,
                showInhibicionCelular: false,
                showAutomatizacionBMS: false  
                });
            break;

            case "dataCenter":
            this.setState({                     
                showRedes: false,
                showProteccionPerimetral: false,
                showCCTV: false,
                showControlesAcceso: false,
                showTecnologiasIdentificacion: false,
                showRFID:false,
                showDataCenter:true,
                showSistemasGeolocalizacion:false,
                showInhibicionCelular: false,
                showAutomatizacionBMS: false  
                });
            break;

            case "geolocalizacion":
            this.setState({                     
                showRedes: false,
                showProteccionPerimetral: false,
                showCCTV: false,
                showControlesAcceso: false,
                showTecnologiasIdentificacion: false,
                showRFID:false,
                showDataCenter:false,
                showSistemasGeolocalizacion:true,
                showInhibicionCelular: false,
                showAutomatizacionBMS: false  
                });
            break;

            case "inhibicionCelular":
            this.setState({                     
                showRedes: false,
                showProteccionPerimetral: false,
                showCCTV: false,
                showControlesAcceso: false,
                showTecnologiasIdentificacion: false,
                showRFID:false,
                showDataCenter:false,
                showSistemasGeolocalizacion:false,
                showInhibicionCelular: true,
                showAutomatizacionBMS: false  
                });
            break;

            case "automatizacionBMS":
            this.setState({                     
                showRedes: false,
                showProteccionPerimetral: false,
                showCCTV: false,
                showControlesAcceso: false,
                showTecnologiasIdentificacion: false,
                showRFID:false,
                showDataCenter:false,
                showSistemasGeolocalizacion:false,
                showInhibicionCelular: false,
                showAutomatizacionBMS: true  
                });
            break;

          default:
            this.setState({
                                 
                showRedes: true,
                showProteccionPerimetral: false,
                showCCTV: false,
                showControlesAcceso: false,
                showTecnologiasIdentificacion: false,
                showRFID:false,
                showDataCenter:false,
                showSistemasGeolocalizacion:false,
                showInhibicionCelular: false,
                showAutomatizacionBMS: false          
            
            });
        }
      }   


    
    render(){
        const { 
            showRedes, 
            showProteccionPerimetral, 
            showCCTV, 
            showControlesAcceso, 
            showTecnologiasIdentificacion,
            showRFID, 
            showDataCenter, 
            showSistemasGeolocalizacion, 
            showInhibicionCelular, 
            showAutomatizacionBMS      

         } = this.state;  

        return(


            
            



            <section className="honeycomb-section container" id="desarrollo-de-tecnologias" data-aos="fade-up">

                <h1 hidden>Soluciones de infraestructura</h1>
                    
            <Particles
                id="tsparticles"
                options={{
                background: {
                    color: {
                    value: "#ffffff",
                    },
                },
                fpsLimit: 60,
                interactivity: {
                    detectsOn: "canvas",
                    events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                    resize: true,
                    },
                    modes: {
                    bubble: {
                        distance: 400,
                        duration: 2,
                        opacity: 0.8,
                        size: 40,
                    },
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                    },
                },
                particles: {
                    color: {
                    value: "#9c9ca2",
                    },
                    links: {
                    color: "#9c9ca2",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1,
                    },
                    collisions: {
                    enable: true,
                    },
                    move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: false,
                    speed: 6,
                    straight: false,
                    },
                    number: {
                    density: {
                        enable: true,
                        value_area: 800,
                    },
                    value: 80,
                    },
                    opacity: {
                    value: 0.5,
                    },
                    shape: {
                    type: "circle",
                    },
                    size: {
                    random: true,
                    value: 5,
                    },
                },
                detectRetina: true,
                }}
            />

                <div id="accordion" className="section-content-mobile">

                    <div className="card">
                        <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseRedes" aria-expanded="true" aria-controls="collapseOne">
                                REDES Y TELECOMUNICACIONES
                            </button>
                        </h2>
                        </div>

                        <div id="collapseRedes" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                                                       
                                    <li> <i className="fas fa-globe"></i> Diseño, ingeniería e implementación de redes LAN y WAN</li>
                                    <li> <i className="fas fa-globe"></i> Administración de infraestructura de red</li>
                                    <li> <i className="fas fa-globe"></i> Monitoreo de infraestructura de red multiplataforma</li>
                                    <li> <i className="fas fa-globe"></i> Routing & Switching</li>
                                    <li> <i className="fas fa-globe"></i> Seguridad perimetral</li>
                                    <li> <i className="fas fa-globe"></i> Data Center</li>
                                    <li> <i className="fas fa-globe"></i> Servidores de Cómputo Unificado</li>
                                    <li> <i className="fas fa-globe"></i> Virtualización</li>
                                    <li> <i className="fas fa-globe"></i> Colaboración</li>
                                    <li> <i className="fas fa-globe"></i> Telepresencia</li>
                                    <li> <i className="fas fa-globe"></i> Wireless</li>
                                    <li> <i className="fas fa-globe"></i> Dispositivos por gestión en la nube</li>
                                    <li> <i className="fas fa-globe"></i> Cableado estructurado UTP y Fibra óptica</li>
                                    <li> <i className="fas fa-globe"></i> Telefonía IP</li>
                                    <li> <i className="fas fa-globe"></i> Comunicaciones unificadas</li>
                                    <li> <i className="fas fa-globe"></i> Mantenimiento menor y mayor a todo tipo de redes (preventivo y correctivo a infraestructura física y lógica)</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="heading2">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseProteccPerimetral" aria-expanded="false" aria-controls="collapseOne">
                                PROTECCIÓN PERIMETRAL
                            </button>
                        </h2>
                        </div>

                        <div id="collapseProteccPerimetral" className="collapse" aria-labelledby="heading2" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li> <i className="fas fa-minus-square"></i> Diseño, ingeniería e implementación de sistemas de protección perimetral</li>
                                    <li> <i className="fas fa-minus-square"></i> Sensores montados en cerca, malla, concertina...</li>
                                    <li> <i className="fas fa-minus-square"></i> Sensores de microondas</li>
                                    <li> <i className="fas fa-minus-square"></i> Sistemas de monitoreo y alarmas</li>
                                    <li> <i className="fas fa-minus-square"></i> Barreras sensores</li>
                                    <li> <i className="fas fa-minus-square"></i> Sensores portátiles y relocalizables</li>
                                    <li> <i className="fas fa-minus-square"></i> Integración con sistema de control de acceso, luminarias, voceo...</li>
                                    <li> <i className="fas fa-minus-square"></i> Mantenimiento preventivo y correctivo</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <div className="card">
                        <div className="card-header" id="heading3">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseCCTV" aria-expanded="false" aria-controls="collapseOne">
                                CCTV
                            </button>
                        </h2>
                        </div>

                        <div id="collapseCCTV" className="collapse" aria-labelledby="heading3" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li> <i className="fas fa-video"></i> Diseño, ingeniería e implementación de sistemas de circuito cerrado de televisión (CCTV)</li>
                                    <li> <i className="fas fa-video"></i> Analógico e híbrido</li>
                                    <li> <i className="fas fa-video"></i> IP y Megapixel</li>
                                    <li> <i className="fas fa-video"></i> Soluciones para ambientes extremos</li>
                                    <li> <i className="fas fa-video"></i> Diseño e implementación de cuartos de monitoreo y control</li>
                                    <li> <i className="fas fa-video"></i> Reconocimiento facial</li>
                                    <li> <i className="fas fa-video"></i> Identificación de placas (LPR)</li>
                                    <li> <i className="fas fa-video"></i> Escáner vehicular (Auvis)</li>
                                    <li> <i className="fas fa-video"></i> Cámaras térmicas</li>
                                    <li> <i className="fas fa-video"></i> Exclusión de áreas (caminos perimetrales)</li>
                                    <li> <i className="fas fa-video"></i> Video analíticos (cruce de línea, objeto removido, objeto olvidado, cambio de sentido...)</li>
                                    <li> <i className="fas fa-video"></i> Video forense</li>
                                    <li> <i className="fas fa-video"></i> Mantenimiento preventivo y correctivo</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>


                    <div className="card">
                        <div className="card-header" id="heading4">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseControlesAcceso" aria-expanded="false" aria-controls="collapseOne">
                                CONTROLES DE ACCESO VEHICULAR Y PEATONAL
                            </button>
                        </h2>
                        </div>

                        <div id="collapseControlesAcceso" className="collapse" aria-labelledby="heading4" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li><i className="fas fa-key"></i>Diseño, ingeniería e implementación de sistemas de control de acceso vehicular y              peatonal</li>
                                    <li><i className="fas fa-key"></i>Puertas giratorias, corredizas y torniquetes</li>
                                    <li><i className="fas fa-key"></i>Esclusas unipersonales</li>
                                    <li><i className="fas fa-key"></i>Barreras ópticas y vehiculares</li>
                                    <li><i className="fas fa-key"></i>Pedestales y monturas</li>
                                    <li><i className="fas fa-key"></i>Electroimanes, sensores y contactos</li>
                                    <li><i className="fas fa-key"></i>Chapas magnéticas y botones de pulso y liberación</li>
                                    <li><i className="fas fa-key"></i>Fuentes de poder y de respaldo</li>
                                    <li><i className="fas fa-key"></i>Llaves y cerraduras electrónicas</li>
                                    <li><i className="fas fa-key"></i>Racks y gabinetes</li>
                                    <li><i className="fas fa-key"></i>Pilonas, bolardos y ponchallantas</li>
                                    <li><i className="fas fa-key"></i>Mantenimiento preventivo y correctivo</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>


                    <div className="card">
                        <div className="card-header" id="heading5">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseAlarma" aria-expanded="false" aria-controls="collapseOne">
                                TECNOLOGÍAS DE IDENTIFICACIÓN
                            </button>
                        </h2>
                        </div>

                        <div id="collapseAlarma" className="collapse" aria-labelledby="heading5" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li> IXMAKI es un Sistema de Gestión de Identidad que permite
                  el registro de información general y de las diferentes biometrías
                  humanas para la administración de identidades y el intercambio de datos biométricos. </li>
                                    <li> <i className="fas fa-fingerprint"></i> Pase de lista</li>
                                    <li> <i className="fas fa-fingerprint"></i> Control de tiempo y asistencia</li>
                                    <li> <i className="fas fa-fingerprint"></i> Control de presencia</li>
                                    <li> <i className="fas fa-fingerprint"></i> Verificador de documentos</li>
                                    <li> <i className="fas fa-fingerprint"></i> Control de visitantes</li>
                                    <li> <i className="fas fa-fingerprint"></i> Generación de archivos NIST</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>


                    <div className="card">
                        <div className="card-header" id="heading6">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseRFID" aria-expanded="false" aria-controls="collapseOne">
                                RFID
                            </button>
                        </h2>
                        </div>

                        <div id="collapseRFID" className="collapse" aria-labelledby="heading6" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li> <i className="fas fa-id-card-alt"></i> Tarjetas, tags, etiquetas... (de diferentes frecuencias) </li>                
                                    <li> <i className="fas fa-id-card-alt"></i> Manos libres, 2.45 Ghz, 900 Mhz (UHF)</li>
                                    <li> <i className="fas fa-id-card-alt"></i> Antenas de diferentes frecuencias</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>



                    <div className="card">
                        <div className="card-header" id="heading7">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseDataCenter" aria-expanded="false" aria-controls="collapseOne">
                                DATA CENTER
                            </button>
                        </h2>
                        </div>

                        <div id="collapseDataCenter" className="collapse" aria-labelledby="heading7" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li> <i className="fas fa-server"></i>  Consultoría, auditoría, ingeniería y diseño de Centros de Datos</li>
                                    <li> <i className="fas fa-server"></i>  Infraestructura de distribución de energía eléctrica</li>
                                    <li> <i className="fas fa-server"></i>  Sistemas de protección antidescargas y piso antiestático</li>
                                    <li> <i className="fas fa-server"></i>  Infraestructura de climatización</li>
                                    <li> <i className="fas fa-server"></i>  Sistemas de telecomunicación en entornos CPD</li>
                                    <li> <i className="fas fa-server"></i>  Sistemas de detección y extinción de incendios</li>
                                    <li> <i className="fas fa-server"></i>  Sistemas de seguridad perimetral</li>
                                    <li> <i className="fas fa-server"></i>  Sistemas de videovigilancia</li>
                                    <li> <i className="fas fa-server"></i>  Control de accesos</li>
                                    <li> <i className="fas fa-server"></i>  Integración de herramientas de gestión (DCIM)</li>
                                    <li> <i className="fas fa-server"></i>  Tareas de explotación y mantenimiento integral</li>
                                    <li> <i className="fas fa-server"></i>  Cerramientos de RACK</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>



                    <div className="card">
                        <div className="card-header" id="heading8">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseGeolocalizacion" aria-expanded="false" aria-controls="collapseOne">
                            SISTEMA DE GEOLOCALIZACIÓN  (CENTROS DE MONITOREO)
                            </button>
                        </h2>
                        </div>

                        <div id="collapseGeolocalizacion" className="collapse" aria-labelledby="heading8" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li>  <i className="fas fa-map-marked-alt"></i> Adquisición y procesamiento de datos de la unidad</li>
                                    <li> <i className="fas fa-map-marked-alt"></i> Control total y regulación de todo tipo de
                                    unidades vehiculares: Velocidad, apertura, potencia activa,
                                    limitador de apertura, potencia reactiva, factor de potencia y tensión, arranque y paro </li>
                                    <li> <i className="fas fa-map-marked-alt"></i> Supervisión, operación y monitoreo de
                                    los equipos de la planta mediante el registro
                                    de alarmas, eventos, gráficos y reportes </li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>



                    <div className="card">
                        <div className="card-header" id="heading9">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseInhibicionCelular" aria-expanded="false" aria-controls="collapseOne">
                                INHIBICIÓN CELULAR
                            </button>
                        </h2>
                        </div>

                        <div id="collapseInhibicionCelular" className="collapse" aria-labelledby="heading9" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li> <i className="fas fa-phone-slash"></i> Llamadas de celular (frecuencias comerciales)</li>
                                    <li> <i className="fas fa-phone-slash"></i> Mensajes de texto</li>
                                    <li> <i className="fas fa-phone-slash"></i> Señales de posicionamiento GPS</li>
                                    <li> <i className="fas fa-phone-slash"></i> Servicios de datos</li>
                                    <li> <i className="fas fa-phone-slash"></i> Redes de Wi-Fi y Bluetooth</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>


                    <div className="card">
                        <div className="card-header" id="heading10">
                        <h2 className="mb-0">
                            <button className="btn btn-link w-100" data-toggle="collapse" data-target="#collapseAutomatizacionBMS" aria-expanded="false" aria-controls="collapseOne">
                                SISTEMA DE AUTOMATIZACIÓN BMS
                            </button>
                        </h2>
                        </div>

                        <div id="collapseAutomatizacionBMS" className="collapse" aria-labelledby="heading10" data-parent="#accordion">
                        <div className="card-body">
                        <div className="section-text-list" >
                                <ul>
                                    <li> <i className="fas fa-building"></i> Diseño, ingeniería e implementación de
                                    sistemas de administración, gestión, supervisión
                                    y control para edificios (automatización integral
                                    de sistemas de soporte a la operación basado en
                                    software y hardware) </li>
                                    <li> <i className="fas fa-building"></i> Cuadros eléctricos, iluminación, grupos
                                    electrógenos... </li>
                                    <li> <i className="fas fa-building"></i> Ascensores, montacargas, rampas o escaleras
                                    mecánicas</li>
                                    <li> <i className="fas fa-building"></i> Sistemas de climatización o aire
                                    acondicionado (HVAC)</li>
                                    <li> <i className="fas fa-building"></i> Sistemas hidráulicos, captación de lluvia
                                    y riego</li>
                                    <li> <i className="fas fa-building"></i> Contra incendio</li>
                                    <li> <i className="fas fa-building"></i> Control de acceso, CCTV y seguridad perimetral</li>
                                </ul>
                                
                            </div>
                        </div>
                        </div>
                    </div>


                   
               
                </div>

           
            <div className="section-content">

                <div className="hexagon-icons col-sm-3" id="desarrollo_info">


                <div className="row"   >
                    <div className="vertientes-vertical-bar"></div>

                    <div className="col-sm-6 vertientes-left-column-icon">
                            
                            <svg version="1.1" onMouseEnter={() => this.showComponent('automatizacionBMS')}  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={` vertientes-subsection-icon hexagon  ${this.state.showAutomatizacionBMS ? 'hexagon_active': ''}`}
                                viewBox="0 0 105 119" >
                    
                                <g id="Shape">
                                    <g>
                                        <g>
                                            <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                            <polygon id="_x3C_HexagonShape_x3E_" class="st1" points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                                52.5,19.41 			"/>
                                        </g>
                                    </g>
                                </g>
                                <g id="Icon">
                                    <g>
                                        <g>
                                            <g>
                                                <path class="st2" d="M48.43,78.21c-11.8,0-21.4-9.6-21.4-21.4c0-11.8,9.6-21.4,21.4-21.4c11.8,0,21.4,9.6,21.4,21.4
                                                    C69.83,68.62,60.23,78.21,48.43,78.21z M48.43,37.05c-10.9,0-19.77,8.87-19.77,19.77c0,10.9,8.87,19.77,19.77,19.77
                                                    s19.77-8.87,19.77-19.77C68.19,45.92,59.33,37.05,48.43,37.05z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path class="st2" d="M63.93,58.43l-0.38-1.54c0,0-0.11-0.43-0.26-1.17l-0.12-0.6l-0.23-0.79c-0.12-0.45-0.27-0.96-0.47-1.48
                                                    l-0.34-0.92l-0.42-0.91c-0.15-0.38-0.35-0.74-0.57-1.12c-0.16-0.27-0.31-0.54-0.45-0.83l-0.29-0.43
                                                    c-0.32-0.47-0.64-0.97-1.01-1.42c-0.34-0.49-0.74-0.91-1.15-1.35c-0.14-0.15-0.28-0.3-0.42-0.45c-0.12-0.11-0.27-0.25-0.41-0.38
                                                    c-0.45-0.42-0.87-0.82-1.35-1.16c-0.46-0.38-0.95-0.71-1.42-1.02c-0.16-0.11-0.33-0.22-0.49-0.33
                                                    c-0.25-0.12-0.52-0.27-0.79-0.43c-0.37-0.22-0.73-0.42-1.09-0.56l-0.95-0.44l-0.89-0.32c-0.52-0.21-1-0.34-1.46-0.47l-0.84-0.24
                                                    l-0.59-0.12c-0.75-0.16-1.18-0.26-1.18-0.26l-1.56-0.39l1.59-0.23c0,0,0.44-0.06,1.2-0.14l0.65-0.06l0.84,0.02
                                                    c0.54,0.01,1.15,0.03,1.8,0.16l1.02,0.17l1.11,0.32c0.61,0.15,1.2,0.41,1.83,0.68l0.47,0.2c0.24,0.14,0.46,0.25,0.67,0.37
                                                    c0.55,0.3,1.11,0.6,1.63,1.02c0.59,0.39,1.14,0.88,1.67,1.36l0.41,0.37l0.36,0.4c0.49,0.55,1,1.11,1.42,1.74
                                                    c0.4,0.49,0.71,1.06,1.01,1.61c0.12,0.21,0.23,0.42,0.35,0.63l0.23,0.52c0.27,0.62,0.53,1.21,0.68,1.83l0.3,1.06l0.18,1.07
                                                    c0.13,0.64,0.15,1.24,0.16,1.78l0.02,0.81l-0.06,0.67c-0.08,0.78-0.14,1.2-0.14,1.2L63.93,58.43z M48.44,39.51l0.68,0.2
                                                    c0.45,0.13,0.97,0.27,1.51,0.49l0.9,0.33l0.96,0.44c0.39,0.15,0.78,0.38,1.16,0.59c0.26,0.15,0.52,0.3,0.79,0.43
                                                    c0.2,0.13,0.36,0.24,0.52,0.35c0.48,0.32,0.98,0.65,1.45,1.04c0.49,0.34,0.95,0.78,1.4,1.2c0.14,0.13,0.28,0.26,0.42,0.39
                                                    c0.16,0.17,0.29,0.32,0.43,0.46c0.41,0.44,0.84,0.89,1.2,1.4c0.38,0.46,0.71,0.96,1.04,1.45l0.31,0.47
                                                    c0.16,0.31,0.31,0.57,0.45,0.83c0.22,0.38,0.44,0.77,0.6,1.18l0.52,1.2l0.24,0.65c0.22,0.54,0.36,1.07,0.49,1.54l0.19,0.67
                                                    l-0.02-0.63c-0.01-0.51-0.02-1.08-0.15-1.68l-0.17-1.04l-0.3-1.04c-0.14-0.58-0.39-1.14-0.65-1.74l-0.21-0.49
                                                    c-0.1-0.18-0.22-0.39-0.34-0.61c-0.28-0.53-0.58-1.07-0.96-1.54c-0.41-0.61-0.88-1.13-1.38-1.69l-0.33-0.37l-0.39-0.34
                                                    c-0.54-0.48-1.05-0.94-1.62-1.32c-0.49-0.4-1.03-0.69-1.56-0.97c-0.22-0.12-0.44-0.24-0.65-0.36l-0.44-0.19
                                                    c-0.6-0.26-1.17-0.51-1.74-0.65l-1.08-0.31l-0.99-0.16c-0.6-0.12-1.19-0.14-1.7-0.15L48.44,39.51z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path class="st2" d="M52.59,56.82c0,2.3-1.86,4.17-4.17,4.17c-2.3,0-4.17-1.87-4.17-4.17c0-2.3,1.87-4.17,4.17-4.17
                                                    C50.73,52.65,52.59,54.51,52.59,56.82z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <rect x="33.78" y="77.52" class="st2" width="29.3" height="6.06"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path class="st2" d="M63.08,81.31V79.8c2.52,0,4.61-0.84,6.21-2.51c4.73-4.91,4.33-15.55,4.18-19.55
                                                    c-0.02-0.51-0.03-0.91-0.03-1.17h1.52c0,0.25,0.02,0.63,0.03,1.12c0.16,4.18,0.58,15.28-4.6,20.66
                                                    C68.48,80.31,66.02,81.31,63.08,81.31z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path class="st2" d="M74.19,60.35c-2.09,0-3.78-1.7-3.78-3.78s1.7-3.78,3.78-3.78c2.09,0,3.78,1.7,3.78,3.78
                                                    S76.27,60.35,74.19,60.35z M74.19,53.78c-1.54,0-2.78,1.25-2.78,2.78s1.25,2.78,2.78,2.78c1.53,0,2.78-1.25,2.78-2.78
                                                    S75.72,53.78,74.19,53.78z"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>

                                </svg>

                            <svg version="1.1" onMouseEnter={() => this.showComponent('inhibicionCelular')}  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 105 119" className={` vertientes-subsection-icon hexagon  ${this.state.showInhibicionCelular ? 'hexagon_active': ''}`}>

                                <g id="Shape">
                                    <g>
                                        <g>
                                            <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                            <polygon id="_x3C_HexagonShape_x3E_" class="st1" points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                                52.5,19.41 			"/>
                                        </g>
                                    </g>
                                </g>
                                        
                                <g id="Icon">
                                    <g>
                                        <g>
                                            <path class="st2" d="M60.63,42.77h-14.4c-1.23,0-2.23,1-2.23,2.23v29.2c0,1.23,1,2.23,2.23,2.23h14.4c1.23,0,2.23-1,2.23-2.23
                                                v-29.2C62.86,43.77,61.86,42.77,60.63,42.77z M50.96,44.09c0-0.13,0.11-0.23,0.23-0.23h4.47c0.13,0,0.23,0.11,0.23,0.23v0.05
                                                c0,0.13-0.11,0.23-0.23,0.23h-4.47c-0.13,0-0.23-0.11-0.23-0.23V44.09z M53.43,75.84c-0.87,0-1.58-0.71-1.58-1.58
                                                c0-0.87,0.71-1.58,1.58-1.58c0.87,0,1.58,0.71,1.58,1.58C55.01,75.13,54.3,75.84,53.43,75.84z M61.01,71.87
                                                c0,0.02-0.02,0.04-0.04,0.04h-15.1c-0.02,0-0.04-0.02-0.04-0.04V45.43c0-0.02,0.02-0.04,0.04-0.04h15.1
                                                c0.02,0,0.04,0.02,0.04,0.04V71.87z"/>
                                        </g>
                                        <path class="st3" d="M52.5,32.14c-15.11,0-27.36,12.25-27.36,27.36c0,15.11,12.25,27.36,27.36,27.36
                                            c15.11,0,27.36-12.25,27.36-27.36C79.86,44.39,67.61,32.14,52.5,32.14L52.5,32.14z M38.3,42.32L38.3,42.32
                                            c3.86-3.19,8.81-5.11,14.2-5.11c12.31,0,22.29,9.98,22.29,22.29c0,5.4-1.92,10.35-5.11,14.2L38.3,42.32L38.3,42.32z M66.7,76.68
                                            L66.7,76.68c-3.86,3.19-8.81,5.11-14.2,5.11c-12.31,0-22.29-9.98-22.29-22.29c0-5.4,1.92-10.35,5.11-14.2L66.7,76.68z"/>
                                    </g>
                                </g>

                            </svg>

                            <svg version="1.1" onMouseEnter={() => this.showComponent('geolocalizacion')}  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"   viewBox="0 0 105 119" className={` vertientes-subsection-icon hexagon  ${this.state.showSistemasGeolocalizacion ? 'hexagon_active': ''}`}>
                           
                            <g id="Shape">
                                <g>
                                    <g>
                                        <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                        <polygon id="_x3C_HexagonShape_x3E_" class="st1" points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                            52.5,19.41 			"/>
                                    </g>
                                </g>
                            </g>
                            <g id="IconGeolocalizacion">
                                    <g>
                                        <path class="st2" d="M54.41,79.64L54.41,79.64l-1.91,3.18l-1.9-3.18c-0.35-0.71-0.92-1.55-1.41-2.26
                                            c-2.4-4.3-5.93-10.51-8.32-16.15c-1.69-4.09-2.89-7.9-2.89-10.51c0-3.95,1.62-7.62,4.3-10.23c2.61-2.68,6.28-4.3,10.23-4.3
                                            c4.02,0,7.69,1.62,10.37,4.3c2.61,2.61,4.16,6.28,4.16,10.23c0,3.25-1.76,8.18-4.02,13.12c-2.33,4.94-5.08,9.88-7.27,13.54
                                            C55.39,78.09,54.9,78.93,54.41,79.64L54.41,79.64z M52.5,79l1.62-2.61c2.05-3.53,4.8-8.54,7.05-13.33
                                            c2.33-4.73,3.95-9.45,3.95-12.34c0-3.46-1.41-6.56-3.67-8.82c-2.26-2.33-5.43-3.67-8.96-3.67c-3.46,0-6.63,1.34-8.89,3.67
                                            c-2.26,2.26-3.74,5.36-3.74,8.82c0,2.4,1.2,5.93,2.75,9.73c2.4,5.5,5.78,11.71,8.32,15.94L52.5,79z"/>
                                        <path class="st2" d="M57.3,45.36L57.3,45.36c-1.27-1.2-2.89-1.97-4.8-1.97c-1.76,0-3.53,0.78-4.73,1.97s-1.98,2.96-1.98,4.73
                                            c0,1.91,0.78,3.53,1.98,4.8c1.2,1.13,2.96,1.91,4.73,1.91c1.91,0,3.53-0.78,4.8-1.91c1.13-1.27,1.9-2.89,1.9-4.8
                                            C59.2,48.32,58.43,46.56,57.3,45.36L57.3,45.36z"/>
                                        <path class="st3" d="M52.46,41.48L52.46,41.48c2.4,0,4.52,0.99,6.21,2.54c1.48,1.48,2.47,3.67,2.47,6.07
                                            c0,2.4-0.99,4.52-2.47,6.21c-1.69,1.48-3.81,2.54-6.21,2.54c-2.4,0-4.58-1.06-6.07-2.54c-1.55-1.69-2.54-3.81-2.54-6.21
                                            c0-2.4,0.99-4.58,2.54-6.07C47.88,42.46,50.07,41.48,52.46,41.48L52.46,41.48z"/>
                                    </g>
                                </g>
                            </svg>
                           
                            <svg version="1.1" onMouseEnter={() => this.showComponent('dataCenter')} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={` vertientes-subsection-icon hexagon  ${this.state.showDataCenter ? 'hexagon_active': ''}`}
                                viewBox="0 0 105 119">                           
                            <g id="Shape">
                                <g>
                                    <g>
                                        <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                        <polygon id="_x3C_HexagonShape_x3E_" class="st1" points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                            52.5,19.41 			"/>
                                    </g>
                                </g>
                            </g>
                            <g id="IconDataCenter">
                                    <g>
                                        <path class="st2" d="M39.47,35.95h-0.8v0.8v43.79v0.8h0.8h26.05h0.8v-0.8V36.75v-0.8h-0.8H39.47z M65.53,43.01v6.26v6.26v6.25
                                            v6.26v6.26v6.26H52.5H39.47v-6.26v-6.26v-6.26v-6.25v-6.26v-6.26v-6.26H52.5h13.03V43.01z"/>
                                        <g>
                                            <polygon class="st3 " points="39.47,35.95 38.67,35.95 38.67,36.75 38.67,80.54 38.67,81.34 39.47,81.34 65.53,81.34 66.33,81.34 
                                                66.33,80.54 66.33,36.75 66.33,35.95 65.53,35.95 			"/>
                                            <path class="st3 " d="M62.02,82.01c-0.46,0-0.83,0.37-0.83,0.83c0,0.46,0.37,0.83,0.83,0.83c0.46,0,0.83-0.37,0.83-0.83
                                                C62.85,82.38,62.48,82.01,62.02,82.01z"/>
                                            <path class="st4" d="M65.93,34.65H39.07c-0.88,0-1.6,0.72-1.6,1.6v46.5c0,0.88,0.72,1.6,1.6,1.6h26.85c0.88,0,1.6-0.72,1.6-1.6
                                                v-46.5C67.53,35.37,66.81,34.65,65.93,34.65z M59.48,83.67c-0.46,0-0.83-0.37-0.83-0.83c0-0.46,0.37-0.83,0.83-0.83
                                                c0.46,0,0.83,0.37,0.83,0.83C60.31,83.3,59.94,83.67,59.48,83.67z M62.02,83.67c-0.46,0-0.83-0.37-0.83-0.83
                                                c0-0.46,0.37-0.83,0.83-0.83c0.46,0,0.83,0.37,0.83,0.83C62.85,83.3,62.48,83.67,62.02,83.67z M64.63,83.67
                                                c-0.46,0-0.83-0.37-0.83-0.83c0-0.46,0.37-0.83,0.83-0.83c0.46,0,0.83,0.37,0.83,0.83C65.46,83.3,65.09,83.67,64.63,83.67z
                                                M65.75,37.66v41.97v0.77h-0.77H40.02h-0.77v-0.77V37.66V36.9h0.77h24.97h0.77V37.66z"/>
                                        </g>
                                        <path class="st2" d="M52.5,36.75H39.47v6.26H52.5h13.03v-6.26H52.5z M41.48,41.54c0,0.23-0.18,0.41-0.4,0.41s-0.4-0.19-0.4-0.41
                                            v-3.31c0-0.23,0.18-0.42,0.4-0.42s0.4,0.19,0.4,0.42V41.54z M43.15,41.54c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.19-0.4-0.41
                                            v-3.31c0-0.23,0.18-0.42,0.4-0.42c0.22,0,0.4,0.19,0.4,0.42V41.54z M44.82,41.54c0,0.23-0.18,0.41-0.4,0.41
                                            c-0.22,0-0.4-0.19-0.4-0.41v-3.31c0-0.23,0.18-0.42,0.4-0.42c0.22,0,0.4,0.19,0.4,0.42V41.54z M58.06,40.89H52.5h-5.56v-2.01h5.56
                                            h5.56V40.89z M60.98,41.54c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.19-0.4-0.41v-3.31c0-0.23,0.18-0.42,0.4-0.42
                                            c0.22,0,0.4,0.19,0.4,0.42V41.54z M62.65,41.54c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.19-0.4-0.41v-3.31
                                            c0-0.23,0.18-0.42,0.4-0.42c0.22,0,0.4,0.19,0.4,0.42V41.54z M64.32,41.54c0,0.23-0.18,0.41-0.4,0.41s-0.4-0.19-0.4-0.41v-3.31
                                            c0-0.23,0.18-0.42,0.4-0.42s0.4,0.19,0.4,0.42V41.54z"/>
                                        <path class="st2" d="M52.5,43.01H39.47v6.26H52.5h13.03v-6.26H52.5z M41.48,47.79c0,0.23-0.18,0.41-0.4,0.41s-0.4-0.18-0.4-0.41
                                            v-3.31c0-0.23,0.18-0.41,0.4-0.41s0.4,0.19,0.4,0.41V47.79z M43.15,47.79c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.18-0.4-0.41
                                            v-3.31c0-0.23,0.18-0.41,0.4-0.41c0.22,0,0.4,0.19,0.4,0.41V47.79z M44.82,47.79c0,0.23-0.18,0.41-0.4,0.41
                                            c-0.22,0-0.4-0.18-0.4-0.41v-3.31c0-0.23,0.18-0.41,0.4-0.41c0.22,0,0.4,0.19,0.4,0.41V47.79z M58.06,47.14H52.5h-5.56v-2.01h5.56
                                            h5.56V47.14z M60.98,47.79c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.18-0.4-0.41v-3.31c0-0.23,0.18-0.41,0.4-0.41
                                            c0.22,0,0.4,0.19,0.4,0.41V47.79z M62.65,47.79c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.18-0.4-0.41v-3.31
                                            c0-0.23,0.18-0.41,0.4-0.41c0.22,0,0.4,0.19,0.4,0.41V47.79z M64.32,47.79c0,0.23-0.18,0.41-0.4,0.41s-0.4-0.18-0.4-0.41v-3.31
                                            c0-0.23,0.18-0.41,0.4-0.41s0.4,0.19,0.4,0.41V47.79z"/>
                                        <path class="st2" d="M52.5,49.26H39.47v6.26H52.5h13.03v-6.26H52.5z M41.48,54.04c0,0.23-0.18,0.42-0.4,0.42s-0.4-0.18-0.4-0.42
                                            v-3.31c0-0.23,0.18-0.41,0.4-0.41s0.4,0.18,0.4,0.41V54.04z M43.15,54.04c0,0.23-0.18,0.42-0.4,0.42c-0.22,0-0.4-0.18-0.4-0.42
                                            v-3.31c0-0.23,0.18-0.41,0.4-0.41c0.22,0,0.4,0.18,0.4,0.41V54.04z M44.82,54.04c0,0.23-0.18,0.42-0.4,0.42
                                            c-0.22,0-0.4-0.18-0.4-0.42v-3.31c0-0.23,0.18-0.41,0.4-0.41c0.22,0,0.4,0.18,0.4,0.41V54.04z M58.06,53.4H52.5h-5.56v-2.02h5.56
                                            h5.56V53.4z M60.98,54.04c0,0.23-0.18,0.42-0.4,0.42c-0.22,0-0.4-0.18-0.4-0.42v-3.31c0-0.23,0.18-0.41,0.4-0.41
                                            c0.22,0,0.4,0.18,0.4,0.41V54.04z M62.65,54.04c0,0.23-0.18,0.42-0.4,0.42c-0.22,0-0.4-0.18-0.4-0.42v-3.31
                                            c0-0.23,0.18-0.41,0.4-0.41c0.22,0,0.4,0.18,0.4,0.41V54.04z M64.32,54.04c0,0.23-0.18,0.42-0.4,0.42s-0.4-0.18-0.4-0.42v-3.31
                                            c0-0.23,0.18-0.41,0.4-0.41s0.4,0.18,0.4,0.41V54.04z"/>
                                        <path class="st2" d="M52.5,55.52H39.47v6.25H52.5h13.03v-6.25H52.5z M41.48,60.3c0,0.23-0.18,0.41-0.4,0.41s-0.4-0.19-0.4-0.41
                                            v-3.31c0-0.23,0.18-0.42,0.4-0.42s0.4,0.18,0.4,0.42V60.3z M43.15,60.3c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.19-0.4-0.41
                                            v-3.31c0-0.23,0.18-0.42,0.4-0.42c0.22,0,0.4,0.18,0.4,0.42V60.3z M44.82,60.3c0,0.23-0.18,0.41-0.4,0.41
                                            c-0.22,0-0.4-0.19-0.4-0.41v-3.31c0-0.23,0.18-0.42,0.4-0.42c0.22,0,0.4,0.18,0.4,0.42V60.3z M58.06,59.66H52.5h-5.56v-2.02h5.56
                                            h5.56V59.66z M60.98,60.3c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.19-0.4-0.41v-3.31c0-0.23,0.18-0.42,0.4-0.42
                                            c0.22,0,0.4,0.18,0.4,0.42V60.3z M62.65,60.3c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.19-0.4-0.41v-3.31
                                            c0-0.23,0.18-0.42,0.4-0.42c0.22,0,0.4,0.18,0.4,0.42V60.3z M64.32,60.3c0,0.23-0.18,0.41-0.4,0.41s-0.4-0.19-0.4-0.41v-3.31
                                            c0-0.23,0.18-0.42,0.4-0.42s0.4,0.18,0.4,0.42V60.3z"/>
                                        <path class="st2" d="M52.5,61.78H39.47v6.26H52.5h13.03v-6.26H52.5z M41.48,66.56c0,0.23-0.18,0.41-0.4,0.41s-0.4-0.19-0.4-0.41
                                            v-3.31c0-0.23,0.18-0.41,0.4-0.41s0.4,0.19,0.4,0.41V66.56z M43.15,66.56c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.19-0.4-0.41
                                            v-3.31c0-0.23,0.18-0.41,0.4-0.41c0.22,0,0.4,0.19,0.4,0.41V66.56z M44.82,66.56c0,0.23-0.18,0.41-0.4,0.41
                                            c-0.22,0-0.4-0.19-0.4-0.41v-3.31c0-0.23,0.18-0.41,0.4-0.41c0.22,0,0.4,0.19,0.4,0.41V66.56z M58.06,65.91H52.5h-5.56V63.9h5.56
                                            h5.56V65.91z M60.98,66.56c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.19-0.4-0.41v-3.31c0-0.23,0.18-0.41,0.4-0.41
                                            c0.22,0,0.4,0.19,0.4,0.41V66.56z M62.65,66.56c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.19-0.4-0.41v-3.31
                                            c0-0.23,0.18-0.41,0.4-0.41c0.22,0,0.4,0.19,0.4,0.41V66.56z M64.32,66.56c0,0.23-0.18,0.41-0.4,0.41s-0.4-0.19-0.4-0.41v-3.31
                                            c0-0.23,0.18-0.41,0.4-0.41s0.4,0.19,0.4,0.41V66.56z"/>
                                        <path class="st2" d="M52.5,68.03H39.47v6.26H52.5h13.03v-6.26H52.5z M41.48,72.82c0,0.23-0.18,0.41-0.4,0.41s-0.4-0.18-0.4-0.41
                                            V69.5c0-0.23,0.18-0.41,0.4-0.41s0.4,0.19,0.4,0.41V72.82z M43.15,72.82c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.18-0.4-0.41
                                            V69.5c0-0.23,0.18-0.41,0.4-0.41c0.22,0,0.4,0.19,0.4,0.41V72.82z M44.82,72.82c0,0.23-0.18,0.41-0.4,0.41
                                            c-0.22,0-0.4-0.18-0.4-0.41V69.5c0-0.23,0.18-0.41,0.4-0.41c0.22,0,0.4,0.19,0.4,0.41V72.82z M58.06,72.17H52.5h-5.56v-2.02h5.56
                                            h5.56V72.17z M60.98,72.82c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.18-0.4-0.41V69.5c0-0.23,0.18-0.41,0.4-0.41
                                            c0.22,0,0.4,0.19,0.4,0.41V72.82z M62.65,72.82c0,0.23-0.18,0.41-0.4,0.41c-0.22,0-0.4-0.18-0.4-0.41V69.5
                                            c0-0.23,0.18-0.41,0.4-0.41c0.22,0,0.4,0.19,0.4,0.41V72.82z M64.32,72.82c0,0.23-0.18,0.41-0.4,0.41s-0.4-0.18-0.4-0.41V69.5
                                            c0-0.23,0.18-0.41,0.4-0.41s0.4,0.19,0.4,0.41V72.82z"/>
                                        <path class="st2" d="M52.5,74.29H39.47v6.26H52.5h13.03v-6.26H52.5z M41.48,79.07c0,0.23-0.18,0.42-0.4,0.42s-0.4-0.19-0.4-0.42
                                            v-3.31c0-0.23,0.18-0.42,0.4-0.42s0.4,0.19,0.4,0.42V79.07z M43.15,79.07c0,0.23-0.18,0.42-0.4,0.42c-0.22,0-0.4-0.19-0.4-0.42
                                            v-3.31c0-0.23,0.18-0.42,0.4-0.42c0.22,0,0.4,0.19,0.4,0.42V79.07z M44.82,79.07c0,0.23-0.18,0.42-0.4,0.42
                                            c-0.22,0-0.4-0.19-0.4-0.42v-3.31c0-0.23,0.18-0.42,0.4-0.42c0.22,0,0.4,0.19,0.4,0.42V79.07z M58.06,78.42H52.5h-5.56v-2.02h5.56
                                            h5.56V78.42z M60.98,79.07c0,0.23-0.18,0.42-0.4,0.42c-0.22,0-0.4-0.19-0.4-0.42v-3.31c0-0.23,0.18-0.42,0.4-0.42
                                            c0.22,0,0.4,0.19,0.4,0.42V79.07z M62.65,79.07c0,0.23-0.18,0.42-0.4,0.42c-0.22,0-0.4-0.19-0.4-0.42v-3.31
                                            c0-0.23,0.18-0.42,0.4-0.42c0.22,0,0.4,0.19,0.4,0.42V79.07z M64.32,79.07c0,0.23-0.18,0.42-0.4,0.42s-0.4-0.19-0.4-0.42v-3.31
                                            c0-0.23,0.18-0.42,0.4-0.42s0.4,0.19,0.4,0.42V79.07z"/>
                                    </g>
                                </g>
                            </svg>
                        
                            <svg version="1.1" onMouseEnter={() => this.showComponent('identificacion')} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={` vertientes-subsection-icon hexagon  ${this.state.showTecnologiasIdentificacion ? 'hexagon_active': ''}`}
                                    viewBox="0 0 105 119" >                                
                                <g id="Shape">
                                    <g>
                                        <g>
                                            <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                            <polygon id="_x3C_HexagonShape_x3E_" class="st1" points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                                52.5,19.41 			"/>
                                        </g>
                                    </g>
                                </g>
                                <g id="IconIdentificacion">
                                    <g>
                                        <path class="st2" d="M69.13,68.49c-0.33-0.25-0.69-0.45-1.06-0.65c-0.58,1.42-1.32,2.81-2.23,4.16c-1.8,2.67-3.77,4.74-5.82,6.28
                                            l1.3,1.56c1.91-1.24,3.93-2.89,6.02-5.11c0.24-0.25,0.64-0.27,0.89-0.03c0.07,0.06,0.07,0.15,0.11,0.24l2.7-4.5
                                            c-0.34-0.49-0.74-0.94-1.17-1.34C69.63,68.87,69.38,68.68,69.13,68.49z"/>
                                        <path class="st2" d="M62.49,74.27l-2.06-2.17c-0.9,1.05-1.89,2.07-2.99,3.05l1.78,2.14C60.34,76.47,61.43,75.47,62.49,74.27z"/>
                                        <path class="st2" d="M62.12,80.8l1.56,1.88l3.76-6.27C65.61,78.25,63.83,79.69,62.12,80.8z"/>
                                        <path class="st2" d="M56.63,74.19c0.97-0.87,1.86-1.78,2.66-2.7l-3.13,2.14L56.63,74.19z"/>
                                        <path class="st2 " d="M48.09,76.25C42.18,73,38.04,63.76,39.23,56.48c0.95-5.78,5.02-9.6,11.48-10.76c7.01-1.08,10,2.08,11.28,4.92
                                            c2.29,5.08,0.72,12.31-2.36,17.16c0.9-0.5,1.89-0.84,2.95-1c2.48-4.58,3.09-9.48,1.66-14.18c-1.69-5.52-5.99-9.52-10.96-10.19
                                            c-5.15-0.7-10.09,2.24-13.9,8.27c-6.03,9.53-2,22.24,5.63,27.24c3.55,2.32,7.31,2.67,10.93,1.21c-0.36-0.77-0.6-1.61-0.73-2.48
                                            C52.97,77.71,50.51,77.58,48.09,76.25z"/>
                                        <path class="st2 " d="M48.04,71.92c-5.24-3.52-6.63-11.08-4.9-16.07c1.51-4.35,5.01-6.65,9.61-6.29c0.75,0.01,4.73,0.17,6,1.51
                                            c0.3,0.32,0.46,0.7,0.45,1.11c-0.01,0.46-0.21,0.87-0.56,1.19c-0.87,0.79-2.77,1.06-6.18,0.85c-3-0.18-4.48,1.6-5.2,3.12
                                            c-1.3,2.8-0.88,6.49,0.35,7.79c1.83,1.94,3.81,2.61,5.72,1.95c2.87-1,5.08-4.87,5.49-9.63c0.03-0.35,0.34-0.6,0.68-0.57
                                            c0.35,0.03,0.6,0.34,0.57,0.68c-0.46,5.33-2.95,9.54-6.33,10.72c-2.38,0.83-4.88,0.02-7.05-2.27c-1.74-1.83-2-6.13-0.58-9.19
                                            c1.24-2.65,3.51-4.03,6.41-3.84c3.63,0.22,4.86-0.16,5.25-0.52c0.1-0.09,0.14-0.17,0.14-0.29c0-0.03,0-0.09-0.1-0.2
                                            c-0.71-0.75-3.53-1.08-5.15-1.12c-5.49-0.42-7.59,3.23-8.36,5.45C42.76,60.81,44,67.7,48.75,70.88c2.92,2.01,5.49,0.79,7.14-0.58
                                            c4.44-3.71,7.6-13.27,4.95-19.14c-1.58-3.5-5.01-4.95-9.92-4.2c-7.53,1.36-9.84,6.04-10.44,9.72c-1.09,6.69,2.82,15.49,8.22,18.46
                                            c1.62,0.89,3.95,1.51,6.4,0.14c0.02-1.2,0.28-2.35,0.74-3.39C53.28,73.62,50.54,73.65,48.04,71.92z M52.31,64.85
                                            c-0.39,0.08-0.77,0.12-1.13,0.12c-1.21,0-2.22-0.46-2.74-1.31c-0.91-1.32-1.21-2.66-0.86-3.95c0.65-2.46,3.4-3.81,3.51-3.86
                                            c0.32-0.15,0.69-0.02,0.84,0.29c0.15,0.31,0.02,0.69-0.29,0.84c-0.02,0.01-2.35,1.16-2.85,3.06c-0.24,0.92-0.01,1.91,0.7,2.93
                                            c0.41,0.65,1.41,0.89,2.55,0.64c1.72-0.37,3.87-1.87,4.29-4.86c0.04-0.58-0.2-0.75-0.28-0.8c-0.52-0.34-1.74-0.16-2.91,0.44
                                            c-1.15,0.59-1.77,1.33-1.78,1.76c-0.02,0.54,0.24,1.09,0.56,1.17c0.33,0.09,0.97-0.32,1.72-1.75c0.16-0.31,0.54-0.43,0.85-0.27
                                            c0.31,0.16,0.43,0.54,0.27,0.85c-1.22,2.34-2.39,2.59-3.16,2.39c-0.9-0.24-1.54-1.28-1.5-2.43c0.04-1.01,0.96-2.07,2.47-2.84
                                            c1.29-0.66,3.07-1.1,4.18-0.36c0.43,0.29,0.92,0.86,0.83,1.98C57.11,62.33,54.59,64.35,52.31,64.85z"/>
                                        <path class="st3" d="M39.88,35.69c0.09-0.07,9.17-7.08,21.97,0.06c0.1,0.05,0.2,0.08,0.31,0.08c0.22,0,0.44-0.12,0.55-0.32
                                            c0.17-0.3,0.06-0.69-0.24-0.86c-13.53-7.55-23.27-0.02-23.37,0.06c-0.27,0.22-0.32,0.61-0.1,0.89
                                            C39.21,35.86,39.61,35.91,39.88,35.69z"/>
                                        <path class="st3" d="M37.19,41.46c0.07-0.06,7.2-5.63,13.63-5.63c9.39,0,15.64,6.01,16.35,9.24c0.06,0.29,0.33,0.49,0.61,0.49
                                            c0.04,0,0.09,0,0.14-0.01c0.34-0.07,0.55-0.41,0.48-0.75c-0.82-3.67-7.39-10.23-17.58-10.23c-6.87,0-14.11,5.66-14.41,5.9
                                            c-0.27,0.22-0.32,0.61-0.1,0.88C36.52,41.63,36.92,41.67,37.19,41.46z"/>
                                        <path class="st3" d="M45.01,77.94c-7.64-5-11.67-17.7-5.63-27.24c3.81-6.03,8.75-8.97,13.9-8.27c4.97,0.67,9.27,4.67,10.96,10.19
                                            c1.44,4.69,0.82,9.59-1.66,14.18c0.42-0.06,0.83-0.13,1.26-0.13c0.07,0,0.14,0.01,0.22,0.01c2.32-4.67,2.85-9.64,1.38-14.43
                                            c-1.84-5.99-6.55-10.34-12-11.07c-3.28-0.44-9.61,0.12-15.14,8.84C31.89,60.16,36.19,73.68,44.32,79
                                            c2.39,1.57,4.87,2.34,7.34,2.34c1.65,0,3.28-0.38,4.89-1.05c-0.24-0.36-0.43-0.74-0.61-1.13C52.32,80.61,48.55,80.26,45.01,77.94z
                                            "/>
                                        <path class="st3" d="M55.1,75.29c-2.46,1.37-4.78,0.75-6.4-0.14c-5.4-2.97-9.32-11.76-8.22-18.46c0.6-3.69,2.91-8.37,10.44-9.72
                                            c4.91-0.75,8.34,0.7,9.92,4.2c2.65,5.87-0.51,15.43-4.95,19.14c-1.65,1.37-4.22,2.59-7.14,0.58C44,67.7,42.76,60.81,44.34,56.26
                                            c0.77-2.22,2.87-5.87,8.36-5.45c1.61,0.04,4.44,0.37,5.15,1.12c0.1,0.11,0.1,0.17,0.1,0.2c0,0.12-0.04,0.2-0.14,0.29
                                            c-0.39,0.36-1.63,0.74-5.25,0.52c-2.9-0.19-5.18,1.19-6.41,3.84c-1.43,3.06-1.16,7.35,0.58,9.19c2.17,2.29,4.67,3.1,7.05,2.27
                                            c3.38-1.18,5.87-5.38,6.33-10.72c0.03-0.35-0.23-0.65-0.57-0.68c-0.35-0.03-0.65,0.23-0.68,0.57c-0.41,4.76-2.62,8.63-5.49,9.63
                                            c-1.9,0.67-3.88-0.01-5.72-1.95c-1.23-1.3-1.66-4.99-0.35-7.79c0.71-1.52,2.19-3.3,5.2-3.12c3.41,0.21,5.31-0.06,6.18-0.85
                                            c0.35-0.32,0.54-0.73,0.56-1.19c0.01-0.4-0.14-0.79-0.45-1.11c-1.27-1.34-5.25-1.5-6-1.51c-4.61-0.35-8.11,1.94-9.61,6.29
                                            c-1.73,4.99-0.35,12.56,4.9,16.07c2.5,1.72,5.23,1.69,7.8-0.02c0.78-1.75,2.12-3.18,3.79-4.1c3.07-4.85,4.65-12.08,2.36-17.16
                                            c-1.28-2.84-4.27-6-11.28-4.92c-6.46,1.16-10.53,4.99-11.48,10.76c-1.19,7.28,2.95,16.52,8.86,19.76
                                            c2.43,1.33,4.89,1.46,7.12,0.42c-0.06-0.41-0.12-0.81-0.12-1.23C55.08,75.39,55.1,75.34,55.1,75.29z"/>
                                        <path class="st3" d="M68.76,65.83h0.02c2.85-9.35-0.74-19.33-6.94-24.53c-7.48-6.28-17.01-5.14-25.48,3.05
                                            c-0.25,0.24-0.26,0.64-0.01,0.89c0.24,0.25,0.64,0.26,0.89,0.01c7.96-7.7,16.85-8.81,23.79-2.99c6.26,5.25,9.7,15.67,5.9,25.01
                                            c0.25,0.1,0.51,0.2,0.75,0.32L68.76,65.83z"/>
                                        <path class="st3" d="M56.75,56.9c-1.11-0.74-2.89-0.3-4.18,0.36c-1.51,0.77-2.43,1.83-2.47,2.84c-0.04,1.15,0.6,2.2,1.5,2.43
                                            c0.77,0.2,1.94-0.05,3.16-2.39c0.16-0.31,0.04-0.69-0.27-0.85c-0.31-0.16-0.69-0.04-0.85,0.27c-0.74,1.43-1.39,1.84-1.72,1.75
                                            c-0.32-0.08-0.58-0.63-0.56-1.17c0.01-0.43,0.63-1.17,1.78-1.76c1.17-0.6,2.39-0.78,2.91-0.44c0.08,0.05,0.32,0.21,0.28,0.8
                                            c-0.41,3-2.57,4.49-4.29,4.86c-1.15,0.25-2.15,0-2.55-0.64c-0.71-1.02-0.94-2.01-0.7-2.93c0.5-1.9,2.82-3.05,2.85-3.06
                                            c0.31-0.15,0.44-0.53,0.29-0.84c-0.15-0.31-0.52-0.44-0.84-0.29c-0.12,0.06-2.86,1.4-3.51,3.86c-0.34,1.3-0.05,2.64,0.86,3.95
                                            c0.53,0.84,1.54,1.31,2.74,1.31c0.36,0,0.74-0.04,1.13-0.12c2.28-0.49,4.8-2.52,5.27-5.96C57.67,57.77,57.18,57.19,56.75,56.9z"/>
                                        <path class="st3" d="M45.99,82.06c-19.96-9.44-10.04-32.56-9.61-33.54c0.14-0.32,0-0.69-0.32-0.83c-0.32-0.14-0.69,0-0.83,0.32
                                            c-0.11,0.25-10.85,25.23,10.2,35.18c0.05,0.02,2.23,1.14,5.73,1.14c2.13,0,4.74-0.41,7.66-1.72c-0.38-0.27-0.74-0.56-1.07-0.88
                                            C50.99,84.55,46.29,82.21,45.99,82.06z"/>
                                        <path class="st3" d="M37.32,79.4c-0.2-0.28-0.59-0.36-0.88-0.16c-0.29,0.2-0.36,0.59-0.16,0.88c0.07,0.1,5.41,7.59,15.31,7.59
                                            c3.42,0,7.4-0.9,11.88-3.3c0.09-0.05,0.12-0.14,0.18-0.22c-0.69-0.01-1.34-0.12-1.98-0.28C46.01,91.51,37.67,79.91,37.32,79.4z"/>
                                        <path class="st2" d="M70.79,67.52h-1.08l-0.59,0.96c0.25,0.19,0.5,0.38,0.73,0.6L70.79,67.52z"/>
                                        <path class="st3" d="M68.33,74.93c0.09,0.22,0.1,0.47-0.08,0.66c-0.27,0.29-0.54,0.55-0.81,0.83l-3.76,6.27l-1.56-1.88
                                            c-1.13,0.74-2.24,1.33-3.3,1.8c0.85,0.6,1.82,1.03,2.86,1.3c0.4-0.19,0.8-0.39,1.2-0.61c0.31-0.16,0.69-0.05,0.85,0.26
                                            c0.12,0.22,0.05,0.45-0.08,0.63c0.06,0,0.12,0.02,0.19,0.02c4.84,0,8.77-3.92,8.77-8.77c0-1.87-0.59-3.59-1.59-5.01L68.33,74.93z"
                                            />
                                        <path class="st3" d="M62.49,74.27c0.79-0.9,1.56-1.89,2.3-2.99c0.88-1.3,1.59-2.65,2.14-4.01c-0.9-0.35-1.86-0.56-2.87-0.58
                                            c-0.93,1.86-2.14,3.68-3.64,5.42L62.49,74.27z"/>
                                        <path class="st3" d="M56.7,71.27c-0.28,0.24-0.57,0.44-0.86,0.63c-0.46,1.04-0.72,2.18-0.74,3.39c0.48-0.27,0.96-0.58,1.44-1.01
                                            c0.03-0.03,0.07-0.06,0.1-0.09l-0.47-0.56l3.13-2.14c1.32-1.51,2.42-3.07,3.28-4.68c-1.06,0.15-2.05,0.5-2.95,1
                                            C58.75,69.18,57.76,70.38,56.7,71.27z"/>
                                        <path class="st3" d="M60.02,78.27c-1.13,0.85-2.29,1.52-3.47,2.02c0.35,0.52,0.75,1,1.2,1.44c1.13-0.47,2.32-1.09,3.56-1.89
                                            L60.02,78.27z"/>
                                        <path class="st3" d="M57.37,75.22c-0.69,0.62-1.42,1.1-2.17,1.45c0.12,0.88,0.37,1.71,0.73,2.48c1.11-0.45,2.21-1.06,3.28-1.85
                                            l-1.78-2.14C57.42,75.18,57.39,75.2,57.37,75.22z"/>
                                        <path class="st3" d="M56.53,74.28c-0.48,0.43-0.96,0.74-1.44,1.01c0,0.05-0.01,0.1-0.01,0.15c0,0.42,0.07,0.83,0.12,1.23
                                            c0.75-0.35,1.48-0.83,2.17-1.45c0.02-0.02,0.04-0.04,0.07-0.06l-0.81-0.97C56.6,74.22,56.57,74.25,56.53,74.28z"/>
                                        <path class="st3" d="M62.58,66.8c-0.87,1.61-1.97,3.17-3.28,4.68l0.33-0.22l0.8,0.84c1.49-1.74,2.71-3.55,3.64-5.42
                                            c-0.07,0-0.14-0.01-0.22-0.01C63.41,66.67,63,66.74,62.58,66.8z"/>
                                        <path class="st3" d="M55.84,71.9c0.29-0.19,0.58-0.39,0.86-0.63c1.06-0.89,2.05-2.09,2.93-3.47
                                            C57.96,68.72,56.62,70.15,55.84,71.9z"/>
                                        <path class="st3" d="M59.21,77.3c-1.07,0.79-2.17,1.41-3.28,1.85c0.18,0.39,0.38,0.78,0.61,1.13c1.18-0.49,2.34-1.17,3.47-2.02
                                            L59.21,77.3z"/>
                                        <path class="st3" d="M62.49,74.27l0.69,0.72l4.51-7.4c-0.24-0.12-0.5-0.22-0.75-0.32c-0.56,1.37-1.26,2.71-2.14,4.01
                                            C64.05,72.38,63.28,73.38,62.49,74.27z"/>
                                        <path class="st3" d="M61.31,79.83c-1.24,0.8-2.43,1.42-3.56,1.89c0.33,0.32,0.69,0.61,1.07,0.88c1.06-0.47,2.16-1.07,3.3-1.8
                                            L61.31,79.83z"/>
                                        <path class="st3" d="M68.25,75.58c0.17-0.19,0.17-0.43,0.08-0.66l-0.89,1.48C67.71,76.14,67.98,75.87,68.25,75.58z"/>
                                        <path class="st3" d="M63.73,83.55c-0.16-0.31-0.55-0.42-0.85-0.26c-0.41,0.22-0.81,0.41-1.2,0.61c0.64,0.16,1.3,0.27,1.98,0.28
                                            C63.79,84,63.85,83.77,63.73,83.55z"/>
                                    </g>
                                </g>

                            </svg>  

                    </div>

                    <div className="col-sm-6 vertientes-right-column-icon">
                       
                            <svg version="1.1" onMouseEnter={() => this.showComponent('redes')}  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={` vertientes-subsection-icon hexagon  ${this.state.showRedes ? 'hexagon_active': ''}`}
                                viewBox="0 0 105 119" >
                    
                                <g id="Shape">
                                    <g>
                                        <g>
                                            <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                            <polygon id="_x3C_HexagonShape_x3E_" class="st1" points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                                52.5,19.41 			"/>
                                        </g>
                                    </g>
                                </g>
                                <g id="Icon">
                                    <g>
                                        <g>
                                            <g>
                                                <path class="st2" d="M52.5,83.14c-12.99,0-23.57-10.57-23.57-23.57c0-12.99,10.57-23.57,23.57-23.57
                                                    c13,0,23.57,10.57,23.57,23.57C76.07,72.57,65.49,83.14,52.5,83.14z M52.5,36.78c-12.57,0-22.79,10.22-22.79,22.79
                                                    c0,12.57,10.22,22.79,22.79,22.79c12.57,0,22.79-10.22,22.79-22.79C75.29,47.01,65.07,36.78,52.5,36.78z"/>
                                            </g>
                                            <g>
                                                <g>
                                                    <path class="st2" d="M52.5,83.14c-4.05,0-7.83-3.82-10.11-10.22c-0.04-0.1-0.03-0.21,0.02-0.3c0.05-0.09,0.13-0.17,0.23-0.2
                                                        c0.72-0.22,1.26-0.85,1.39-1.6c0.09-0.58-0.07-1.18-0.46-1.64c-0.38-0.45-0.94-0.71-1.54-0.71c-0.17,0-0.32,0.02-0.47,0.05
                                                        c-0.1,0.02-0.21,0.01-0.3-0.05c-0.09-0.06-0.15-0.15-0.17-0.25c-0.56-2.76-0.85-5.67-0.85-8.65c0-3.19,0.32-6.26,0.95-9.12
                                                        c0.02-0.1,0.08-0.19,0.17-0.25c0.09-0.06,0.19-0.07,0.3-0.05c1.2,0.28,2.31-0.51,2.49-1.63c0.02-0.11,0.03-0.21,0.03-0.32
                                                        c0-0.88-0.56-1.65-1.38-1.92c-0.1-0.03-0.18-0.11-0.23-0.2c-0.05-0.1-0.05-0.21-0.01-0.3c2.32-6.2,5.95-9.76,9.94-9.76
                                                        c4.19,0,8.04,4.02,10.3,10.75c0.14,0.41,0.27,0.83,0.39,1.26c0.03,0.05,0.05,0.12,0.05,0.18c0.99,3.46,1.51,7.39,1.51,11.38
                                                        c0,2.98-0.28,5.88-0.84,8.64c-0.02,0.1-0.08,0.2-0.17,0.25c-0.09,0.06-0.2,0.07-0.3,0.05c-0.14-0.03-0.29-0.05-0.46-0.05
                                                        c-1.11,0-2.02,0.9-2.02,2.02c0,0.89,0.57,1.66,1.42,1.93c0.1,0.03,0.18,0.1,0.23,0.2c0.05,0.09,0.05,0.2,0.02,0.3
                                                        C60.34,79.31,56.55,83.14,52.5,83.14z M43.25,73.01c2.17,5.86,5.61,9.35,9.25,9.35c3.64,0,7.08-3.49,9.26-9.36
                                                        c-0.96-0.46-1.58-1.44-1.58-2.53c0-1.54,1.26-2.79,2.8-2.79c0.08,0,0.17,0,0.24,0.01c0.5-2.59,0.76-5.32,0.76-8.12
                                                        c0-3.82-0.49-7.59-1.41-10.92c-3.28,0.59-6.61,0.89-9.89,0.89c-0.03,0-0.07,0-0.1,0h0h0l0,0l0,0l0,0c0,0,0,0,0,0
                                                        c0,0-0.01,0-0.01,0l0,0l0,0l0,0c0,0,0,0,0,0l0,0c-2.56,0-5.15-0.18-7.72-0.53c-0.38,1.25-1.59,2.09-2.96,1.96
                                                        c-0.57,2.7-0.85,5.6-0.85,8.6c0,2.8,0.25,5.53,0.76,8.13c0.08-0.01,0.16-0.01,0.25-0.01c0.82,0,1.6,0.36,2.13,0.98
                                                        c0.53,0.63,0.77,1.45,0.64,2.27C44.64,71.84,44.05,72.62,43.25,73.01z M52.56,48.77c3.25,0,6.54-0.29,9.79-0.87
                                                        c-0.09-0.31-0.19-0.6-0.29-0.9c-2.15-6.4-5.72-10.22-9.56-10.22c-3.53,0-6.91,3.32-9.08,8.91c0.94,0.47,1.54,1.43,1.54,2.51
                                                        c0,0.02,0,0.04,0,0.05C47.48,48.6,50.04,48.77,52.56,48.77L52.56,48.77z"/>
                                                </g>
                                            </g>
                                            <g>
                                                <path class="st2" d="M75.68,59.96H29.32c-0.22,0-0.39-0.17-0.39-0.39s0.17-0.39,0.39-0.39h46.36c0.22,0,0.39,0.17,0.39,0.39
                                                    S75.89,59.96,75.68,59.96z"/>
                                            </g>
                                            <g>
                                                <path class="st2" d="M52.5,83.14c-0.22,0-0.39-0.17-0.39-0.39V36.4c0-0.22,0.17-0.39,0.39-0.39c0.22,0,0.39,0.17,0.39,0.39
                                                    v46.36C52.89,82.97,52.71,83.14,52.5,83.14z"/>
                                            </g>
                                            <g>
                                                <path class="st2" d="M42.16,50.99c-1.54,0-2.8-1.25-2.8-2.79c0-0.06,0-0.13,0.01-0.19c-1.92-0.45-3.86-1-5.78-1.64
                                                    c-0.2-0.07-0.31-0.29-0.25-0.49c0.07-0.2,0.29-0.32,0.49-0.25c1.89,0.63,3.8,1.18,5.69,1.62c0.39-1.09,1.44-1.85,2.63-1.85
                                                    c1.54,0,2.8,1.26,2.8,2.8c0,0.02,0,0.04,0,0.05c8.73,1.2,17.55,0.31,26.21-2.62c0.2-0.07,0.42,0.04,0.49,0.24
                                                    c0.07,0.2-0.04,0.42-0.24,0.49c-8.78,2.98-17.72,3.87-26.58,2.66C44.48,50.18,43.41,50.99,42.16,50.99z M40.18,47.79
                                                    c-0.03,0.14-0.04,0.27-0.04,0.41c0,1.11,0.9,2.01,2.02,2.01c0.98,0,1.84-0.73,1.99-1.69c0,0,0,0,0,0
                                                    c0.02-0.11,0.03-0.21,0.03-0.32c0-1.12-0.91-2.02-2.02-2.02C41.2,46.18,40.37,46.85,40.18,47.79
                                                    C40.18,47.79,40.18,47.79,40.18,47.79z"/>
                                            </g>
                                            <g>
                                                <path class="st2" d="M71.29,73.06c-0.04,0-0.08-0.01-0.13-0.02c-2-0.68-3.99-1.25-5.92-1.69c-0.21-0.05-0.34-0.26-0.29-0.47
                                                    c0.05-0.21,0.26-0.34,0.47-0.29c1.95,0.45,3.97,1.02,6,1.71c0.2,0.07,0.31,0.29,0.24,0.49C71.6,72.96,71.45,73.06,71.29,73.06z
                                                    M33.71,73.06c-0.16,0-0.31-0.1-0.37-0.27c-0.07-0.2,0.04-0.42,0.24-0.49c2.01-0.67,4.03-1.25,5.99-1.7
                                                    c0.21-0.05,0.42,0.08,0.47,0.29c0.05,0.21-0.08,0.42-0.29,0.47c-1.94,0.45-3.93,1.01-5.92,1.68
                                                    C33.79,73.05,33.75,73.06,33.71,73.06z M44.4,70.48c-0.19,0-0.36-0.14-0.38-0.33c-0.03-0.21,0.12-0.41,0.33-0.44
                                                    c5.44-0.79,10.92-0.8,16.31-0.01c0.21,0.03,0.36,0.23,0.33,0.44c-0.03,0.21-0.23,0.35-0.44,0.33
                                                    c-5.31-0.77-10.72-0.77-16.08,0.01C44.44,70.48,44.42,70.48,44.4,70.48z"/>
                                            </g>
                                            <g>
                                                <path class="st2" d="M62.97,73.27c-1.54,0-2.8-1.26-2.8-2.8c0-1.54,1.26-2.79,2.8-2.79c0.82,0,1.6,0.36,2.13,0.99
                                                    c0.53,0.63,0.76,1.45,0.63,2.26C65.52,72.27,64.33,73.27,62.97,73.27z M62.97,68.46c-1.11,0-2.02,0.9-2.02,2.02
                                                    c0,1.12,0.91,2.02,2.02,2.02c0.98,0,1.83-0.73,1.99-1.69c0.1-0.59-0.07-1.18-0.46-1.64C64.13,68.72,63.57,68.46,62.97,68.46z"/>
                                            </g>
                                            <g>
                                                <path class="st2" d="M42.03,73.29c-1.54,0-2.8-1.26-2.8-2.8c0-1.54,1.26-2.79,2.8-2.79c0.82,0,1.6,0.36,2.13,0.98
                                                    c0.53,0.63,0.77,1.45,0.64,2.27C44.57,72.3,43.41,73.29,42.03,73.29z M42.03,68.47c-1.11,0-2.02,0.9-2.02,2.02
                                                    c0,1.11,0.91,2.02,2.02,2.02c0.99,0,1.83-0.71,2-1.69c0.09-0.59-0.07-1.18-0.46-1.64C43.18,68.73,42.62,68.47,42.03,68.47z"/>
                                            </g>
                                            <g>
                                                <path class="st2" d="M72.72,36.6H32.28c-0.22,0-0.39-0.17-0.39-0.39c0-0.22,0.17-0.39,0.39-0.39h40.44
                                                    c0.22,0,0.39,0.17,0.39,0.39C73.11,36.43,72.94,36.6,72.72,36.6z"/>
                                            </g>
                                            <g>
                                                <path class="st2" d="M72.72,83.18H32.28c-0.22,0-0.39-0.17-0.39-0.39c0-0.22,0.17-0.39,0.39-0.39h40.44
                                                    c0.22,0,0.39,0.17,0.39,0.39C73.11,83,72.94,83.18,72.72,83.18z"/>
                                            </g>
                                        </g>
                                    </g>

                                </g>
                            </svg>

                            <svg version="1.1" onMouseEnter={() => this.showComponent('proteccion')}  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 105 119" className={` vertientes-subsection-icon hexagon  ${this.state.showProteccionPerimetral ? 'hexagon_active': ''}`}>

                                <g id="Shape">
                                    <g>
                                        <g>
                                            <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                            <polygon id="_x3C_HexagonShape_x3E_" class="st1" points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                                52.5,19.41 			"/>
                                        </g>
                                    </g>
                                </g>
                                        
                                <g id="Icon">
                                        <g>
                                            <g>
                                                <path class="st2" d="M79.14,51.59H25.86c-0.11,0-0.2-0.09-0.2-0.2s0.09-0.2,0.2-0.2h53.28c0.11,0,0.2,0.09,0.2,0.2
                                                    S79.25,51.59,79.14,51.59z"/>
                                            </g>
                                            <g>
                                                <path class="st2" d="M79.14,73.57H25.86c-0.11,0-0.2-0.09-0.2-0.2c0-0.11,0.09-0.2,0.2-0.2h53.28c0.11,0,0.2,0.09,0.2,0.2
                                                    C79.35,73.48,79.25,73.57,79.14,73.57z"/>
                                            </g>
                                            <g>
                                                <polygon class="st2" points="32.75,81.56 30.31,81.56 30.31,41.09 27.97,39.42 29.39,37.44 32.75,39.84 			"/>
                                            </g>
                                            <g>
                                                <polygon class="st2" points="74.26,81.56 71.82,81.56 71.82,41.09 69.48,39.42 70.9,37.44 74.26,39.84 			"/>
                                            </g>
                                            <g>
                                                <g>
                                                    <rect x="33.57" y="50.24" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="35.19" y="50.24" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="36.82" y="50.24" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="66.53" y="50.24" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="68.15" y="50.24" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="69.78" y="50.24" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <rect x="33.57" y="61.23" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="35.19" y="61.23" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="36.82" y="61.23" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="66.53" y="61.23" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="68.15" y="61.23" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="69.78" y="61.23" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                            </g>
                                            <g>
                                                <g>
                                                    <rect x="33.57" y="72.22" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="35.19" y="72.22" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="36.82" y="72.22" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="66.53" y="72.22" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="68.15" y="72.22" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                                <g>
                                                    <rect x="69.78" y="72.22" class="st2" width="0.81" height="2.3"/>
                                                </g>
                                            </g>
                                            <g>
                                                <polygon class="st3" points="47.81,60.96 48.26,62.17 51.2,62.17 			"/>
                                                <path class="st2" d="M79.14,62.17H62.46v0.41h16.68c0.11,0,0.2-0.09,0.2-0.2S79.25,62.17,79.14,62.17z"/>
                                                <path class="st2" d="M25.86,62.17c-0.11,0-0.2,0.09-0.2,0.2s0.09,0.2,0.2,0.2h14.22v-0.41H25.86z"/>
                                                <path class="st4" d="M47.81,60.96l3.39,1.22h0.79l-1.7-5.44h3.32l0.68,5.44h8.16V60.4c0-2.69-2.2-4.88-4.88-4.88H44.96
                                                    c-2.69,0-4.88,2.2-4.88,4.88v1.77h8.18L47.81,60.96z"/>
                                                <path class="st4" d="M54.73,65.6l-4.5-1.83l0.99,6.41l-2.81-7.6h-8.33v3.93c0,2.69,2.2,4.88,4.88,4.88h12.61
                                                    c2.69,0,4.88-2.2,4.88-4.88v-3.93h-8.11L54.73,65.6z"/>
                                                <polygon class="st4" points="48.26,62.17 40.08,62.17 40.08,62.58 48.41,62.58 			"/>
                                                <polygon class="st4" points="54.3,62.17 54.35,62.58 62.46,62.58 62.46,62.17 			"/>
                                                <polygon class="st4" points="51.99,62.17 51.2,62.17 52.09,62.49 			"/>
                                            </g>
                                        </g>
                                    </g>


                                </svg>

                            <svg version="1.1" onMouseEnter={() => this.showComponent('cctv')}  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"   viewBox="0 0 105 119" className={` vertientes-subsection-icon hexagon  ${this.state.showCCTV ? 'hexagon_active': ''}`}>
                           
                            <g id="Shape">
                                <g>
                                    <g>
                                        <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                        <polygon id="_x3C_HexagonShape_x3E_" class="st1" points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                            52.5,19.41 			"/>
                                    </g>
                                </g>
                            </g>
                            <g id="Icon">
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <path class="st2" d="M39.72,55.68c-1.25,2.44-0.22,5.45,2.3,6.6c2.37,1.08,5.19-0.01,6.37-2.34l0.17-0.33
                                                    c0.01-0.03,0-0.06-0.02-0.07l-8.51-4.35c-0.03-0.01-0.06,0-0.07,0.02L39.72,55.68z"/>
                                                <path class="st2" d="M43.93,62.82c-0.67,0-1.33-0.14-1.96-0.42c-1.25-0.57-2.18-1.59-2.63-2.88c-0.45-1.29-0.35-2.67,0.27-3.89
                                                    l0.24-0.46c0.05-0.09,0.16-0.12,0.24-0.08l8.51,4.35c0.09,0.05,0.12,0.15,0.08,0.24L48.5,60
                                                    C47.6,61.77,45.78,62.82,43.93,62.82z M40.03,55.34l-0.2,0.4c-0.59,1.16-0.68,2.47-0.25,3.69c0.43,1.22,1.31,2.2,2.5,2.74
                                                    c2.26,1.03,5.04,0.01,6.21-2.28l0.14-0.27L40.03,55.34z"/>
                                            </g>
                                            <g>
                                                <g>
                                                    <path class="st2" d="M62.98,65.73c-0.36,0-0.73-0.08-1.07-0.26L32.41,50.4c-1.16-0.59-1.63-2.02-1.03-3.19l4.41-8.63
                                                        c0.59-1.16,2.02-1.63,3.19-1.03l29.49,15.07c1.16,0.59,1.63,2.02,1.03,3.19l-4.41,8.63C64.68,65.26,63.84,65.73,62.98,65.73z
                                                        M37.9,38.21c-0.53,0-1.04,0.29-1.29,0.79l-4.41,8.63c-0.36,0.71-0.08,1.59,0.63,1.95l29.49,15.07
                                                        c0.71,0.36,1.59,0.08,1.95-0.63l4.41-8.63c0.36-0.71,0.08-1.59-0.63-1.95L38.56,38.37C38.35,38.27,38.13,38.21,37.9,38.21z"/>
                                                    <path class="st2" d="M62.98,65.86c-0.39,0-0.78-0.09-1.13-0.27L32.36,50.52c-1.22-0.63-1.71-2.13-1.09-3.35l4.41-8.63
                                                        c0.3-0.59,0.82-1.03,1.45-1.24c0.63-0.21,1.31-0.15,1.9,0.15l29.49,15.07c1.22,0.63,1.71,2.13,1.09,3.35l-4.41,8.63
                                                        C64.78,65.34,63.93,65.86,62.98,65.86z M37.91,37.42c-0.23,0-0.47,0.04-0.7,0.11c-0.57,0.19-1.04,0.58-1.31,1.11l-4.41,8.63
                                                        c-0.27,0.53-0.32,1.14-0.14,1.71c0.19,0.57,0.58,1.04,1.11,1.31l29.49,15.07c0.31,0.16,0.67,0.25,1.02,0.25
                                                        c0.85,0,1.62-0.47,2-1.22l4.41-8.63c0.56-1.1,0.12-2.46-0.98-3.02L38.92,37.67C38.6,37.5,38.26,37.42,37.91,37.42z
                                                        M62.98,64.94c-0.25,0-0.49-0.06-0.71-0.17L32.78,49.7c-0.77-0.4-1.08-1.35-0.69-2.12l4.41-8.63c0.27-0.53,0.81-0.86,1.4-0.86
                                                        c0.25,0,0.49,0.06,0.71,0.17l29.49,15.07c0.77,0.4,1.08,1.35,0.69,2.12l-4.41,8.63c-0.19,0.37-0.52,0.65-0.92,0.78
                                                        C63.31,64.91,63.15,64.94,62.98,64.94z M37.9,38.34c-0.5,0-0.96,0.28-1.18,0.72l-4.41,8.63c-0.33,0.65-0.07,1.45,0.58,1.78
                                                        l29.49,15.07c0.31,0.16,0.67,0.19,1.01,0.08c0.34-0.11,0.61-0.34,0.77-0.66l4.41-8.63c0.33-0.65,0.07-1.45-0.58-1.78
                                                        L38.5,38.48C38.32,38.39,38.11,38.34,37.9,38.34z"/>
                                                </g>
                                            </g>
                                            <g>
                                                
                                                    <rect x="65.42" y="59.95" transform="matrix(0.455 -0.8905 0.8905 0.455 -16.6265 94.5537)" class="st2" width="7.01" height="1.81"/>
                                                <path class="st2" d="M68.2,64.56l-1.84-0.94l3.3-6.47l1.83,0.94L68.2,64.56z M66.7,63.52l1.39,0.71l3.08-6.02l-1.39-0.71
                                                    L66.7,63.52z"/>
                                            </g>
                                            <g>
                                                <path class="st2" d="M71.49,58.93l-2.67,5.22c0.57,0.29,1.26,0.06,1.55-0.5l1.62-3.17C72.28,59.91,72.05,59.22,71.49,58.93z"/>
                                                <path class="st2" d="M69.34,64.4c-0.2,0-0.4-0.05-0.58-0.14l-0.11-0.06l2.78-5.44l0.11,0.06c0.3,0.16,0.53,0.42,0.63,0.74
                                                    c0.11,0.32,0.08,0.67-0.08,0.97l-1.62,3.17c-0.16,0.3-0.42,0.53-0.74,0.63C69.61,64.38,69.47,64.4,69.34,64.4z M68.99,64.09
                                                    c0.21,0.08,0.45,0.08,0.67,0.01c0.26-0.08,0.47-0.27,0.6-0.51l1.62-3.17c0.12-0.24,0.15-0.52,0.06-0.78
                                                    c-0.07-0.22-0.21-0.41-0.4-0.54L68.99,64.09z"/>
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <path class="st2" d="M43.28,64.19c-0.72,0.03-1.45-0.09-2.15-0.41c-1.4-0.64-2.33-1.85-2.68-3.21l-7.29,4.4v-7.02h-2.71v24.94
                                            h2.71v-7.4L43.28,64.19z"/>
                                        <path class="st2" d="M31.29,83h-2.96V57.82h2.96v6.93l7.24-4.37l0.04,0.16c0.35,1.39,1.31,2.53,2.61,3.13
                                            c0.65,0.3,1.35,0.43,2.09,0.4l0.34-0.01L31.29,75.53V83z M28.58,82.75h2.46v-7.33l0.04-0.04l11.88-11.07
                                            c-0.66-0.01-1.29-0.16-1.88-0.43c-1.32-0.6-2.3-1.74-2.71-3.13l-7.34,4.43v-7.12h-2.46V82.75z"/>
                                    </g>
                                    <g>
                                        <g>
                                            <g>
                                                <path class="st2" d="M67.48,44.52c-0.91,0-1.65,0.74-1.65,1.65s0.74,1.65,1.65,1.65c0.91,0,1.65-0.74,1.65-1.65
                                                    S68.39,44.52,67.48,44.52z"/>
                                                <path class="st2" d="M67.48,47.94c-0.98,0-1.77-0.79-1.77-1.77s0.79-1.77,1.77-1.77c0.98,0,1.77,0.79,1.77,1.77
                                                    S68.46,47.94,67.48,47.94z M67.48,44.65c-0.84,0-1.52,0.68-1.52,1.52s0.68,1.52,1.52,1.52c0.84,0,1.52-0.68,1.52-1.52
                                                    S68.32,44.65,67.48,44.65z"/>
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path class="st2" d="M67.48,36.12c-3.82,0-7.26,2.12-8.98,5.54c-0.18,0.36-0.04,0.81,0.33,0.99c0.18,0.09,0.38,0.1,0.56,0.04
                                                    c0.19-0.06,0.34-0.19,0.43-0.37c1.47-2.92,4.4-4.73,7.66-4.73c3.26,0,6.2,1.81,7.66,4.73c0.13,0.25,0.38,0.41,0.66,0.41
                                                    c0.12,0,0.23-0.03,0.33-0.08c0.18-0.09,0.31-0.24,0.37-0.43c0.06-0.19,0.05-0.39-0.04-0.56v0
                                                    C74.75,38.25,71.31,36.12,67.48,36.12z"/>
                                                <path class="st2" d="M59.16,42.86c-0.13,0-0.26-0.03-0.39-0.09c-0.43-0.21-0.6-0.73-0.38-1.16c1.74-3.46,5.22-5.61,9.09-5.61
                                                    s7.35,2.15,9.09,5.61c0.1,0.21,0.12,0.44,0.05,0.66c-0.07,0.22-0.22,0.4-0.43,0.5c-0.43,0.21-0.95,0.03-1.16-0.38
                                                    c-1.45-2.88-4.34-4.66-7.55-4.66s-6.11,1.79-7.55,4.66c-0.1,0.21-0.28,0.36-0.5,0.43C59.34,42.85,59.25,42.86,59.16,42.86z
                                                    M67.48,36.25c-3.77,0-7.17,2.1-8.87,5.48c-0.15,0.3-0.03,0.67,0.27,0.82c0.15,0.07,0.31,0.09,0.47,0.03s0.28-0.16,0.36-0.31
                                                    c1.49-2.96,4.46-4.8,7.77-4.8c3.31,0,6.29,1.84,7.77,4.8c0.15,0.29,0.52,0.42,0.82,0.27c0.15-0.07,0.25-0.2,0.3-0.35
                                                    c0.05-0.16,0.04-0.32-0.03-0.47C74.65,38.35,71.26,36.25,67.48,36.25z"/>
                                            </g>
                                        </g>
                                        <g>
                                                <g>
                                                    <path class="st2" d="M67.48,41.73c1.69,0,3.21,0.94,3.97,2.45c0.09,0.18,0.24,0.31,0.43,0.37c0.19,0.06,0.39,0.05,0.56-0.04
                                                        c0.18-0.09,0.31-0.24,0.37-0.43c0.06-0.19,0.05-0.39-0.04-0.56c-1.01-2.01-3.04-3.26-5.28-3.26c-2.25,0-4.27,1.25-5.28,3.26
                                                        c-0.18,0.36-0.04,0.81,0.33,0.99c0.1,0.05,0.21,0.08,0.33,0.08c0.28,0,0.53-0.16,0.66-0.41C64.28,42.67,65.8,41.73,67.48,41.73
                                                        z"/>
                                                    <path class="st2" d="M72.11,44.71c-0.09,0-0.18-0.01-0.27-0.04c-0.22-0.07-0.4-0.23-0.5-0.43c-0.74-1.47-2.21-2.38-3.86-2.38
                                                        c-1.64,0-3.12,0.91-3.85,2.38c-0.15,0.29-0.44,0.48-0.77,0.48c-0.13,0-0.26-0.03-0.39-0.09c-0.21-0.1-0.36-0.28-0.43-0.5
                                                        c-0.07-0.22-0.06-0.45,0.05-0.66c1.03-2.06,3.1-3.33,5.4-3.33c2.3,0,4.36,1.28,5.4,3.33c0.1,0.21,0.12,0.44,0.05,0.66
                                                        c-0.07,0.22-0.23,0.4-0.43,0.5C72.37,44.68,72.24,44.71,72.11,44.71z M67.48,41.61c1.74,0,3.3,0.96,4.08,2.52
                                                        c0.07,0.15,0.2,0.26,0.35,0.31c0.16,0.05,0.32,0.04,0.47-0.03c0.15-0.07,0.25-0.2,0.31-0.35c0.05-0.15,0.04-0.32-0.04-0.47
                                                        c-0.99-1.97-2.97-3.19-5.17-3.19s-4.18,1.22-5.17,3.19c-0.07,0.15-0.08,0.31-0.03,0.47c0.05,0.16,0.16,0.28,0.31,0.35
                                                        c0.3,0.15,0.67,0.02,0.82-0.27C64.18,42.57,65.75,41.61,67.48,41.61z"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>

                            </svg>
                           
                            <svg version="1.1" onMouseEnter={() => this.showComponent('controlAcceso')} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={` vertientes-subsection-icon hexagon  ${this.state.showControlesAcceso ? 'hexagon_active': ''}`}
                                viewBox="0 0 105 119">                           
                            <g id="Shape">
                                <g>
                                    <g>
                                        <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                        <polygon id="_x3C_HexagonShape_x3E_" class="st1" points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                            52.5,19.41 			"/>
                                    </g>
                                </g>
                            </g>
                            <g id="Icon">
                                <g>
                                    <path class="st2" d="M76.33,79.13H64.95c-0.45,0-0.81-0.36-0.81-0.81V42.78c0-1.6,1.3-2.91,2.91-2.91h7.19
                                        c1.6,0,2.91,1.3,2.91,2.91v35.54C77.14,78.77,76.78,79.13,76.33,79.13z M65.75,77.51h9.77v-2.42h-9.77V77.51z M65.75,73.47h9.77
                                        V48.6h-9.77V73.47z M65.75,46.98h9.77v-4.2c0-0.71-0.58-1.29-1.29-1.29h-7.19c-0.71,0-1.29,0.58-1.29,1.29L65.75,46.98
                                        L65.75,46.98z M40.05,79.13H28.67c-0.45,0-0.81-0.36-0.81-0.81V42.78c0-1.6,1.3-2.91,2.91-2.91h7.19c1.6,0,2.91,1.3,2.91,2.91
                                        v2.79h18.39c0.45,0,0.81,0.36,0.81,0.81s-0.36,0.81-0.81,0.81H42.41l13.33,10.38c0.35,0.27,0.42,0.78,0.14,1.14
                                        c-0.28,0.35-0.78,0.42-1.14,0.14L41.36,48.42l3.48,14.88c0.1,0.44-0.17,0.87-0.6,0.97c-0.43,0.1-0.87-0.17-0.97-0.6l-2.4-10.27
                                        v24.92C40.86,78.77,40.5,79.13,40.05,79.13z M29.48,77.51h9.77v-2.42h-9.77V77.51z M29.48,73.47h9.77V48.6h-9.77V73.47z
                                        M29.48,46.98h9.77v-0.58c0-0.01,0-0.02,0-0.03v-3.58c0-0.71-0.58-1.29-1.29-1.29h-7.19c-0.71,0-1.29,0.58-1.29,1.29V46.98z"/>
                                </g>
                            </g>
                            </svg>

                            <svg version="1.1" onMouseEnter={() => this.showComponent('rfid')} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={` vertientes-subsection-icon hexagon  ${this.state.showRFID ? 'hexagon_active': ''}`}
                                viewBox="0 0 105 119">                           
                            <g id="Shape">
                                <g>
                                    <g>
                                        <polygon class="st0" points="4.77,31.95 4.77,87.05 52.5,114.61 100.23,87.05 100.23,31.95 52.5,4.39 			"/>
                                        <polygon id="_x3C_HexagonShape_x3E_" class="st1" points="17.78,39.46 17.78,79.54 52.5,99.59 87.22,79.54 87.22,39.46 
                                            52.5,19.41 			"/>
                                    </g>
                                </g>
                            </g>
                            <g id="Icon">
                                <g>
                                <g>
                                <path class="st2" d="M69.26,80.42H53.53v-1.31h-0.8v1.31h-17.4l-2.96-3V42.03l3.04-3h34.04L72.34,42v35.43L69.26,80.42z
                                M54.2,79.75h14.78l2.68-2.61V42.27l-2.5-2.57H35.68l-2.64,2.61v34.83l2.57,2.61h16.44v-1.31h1.48v-0.43h-2.21v1.1H35.97
                                l-2.33-2.25V42.63L36,40.19h32.7l2.44,2.48v34.11l-2.29,2.33H54.2V79.75z M54.2,78.44h14.37l1.9-1.93V42.94l-2.05-2.08H36.28
                                l-1.97,2.04v33.67l1.93,1.86h14.41v-1.1h2.88v-0.56h-3.55v1.23H36.71l-1.82-1.88V43.54l1.94-1.94h31.08l2.03,1.95v32.47
                                l-1.8,1.98H54.2V78.44z M54.2,77.34h13.65l1.42-1.57V43.85l-1.63-1.56H37.1l-1.54,1.54v32.03l1.43,1.48H49.3V76.1h4.23v-0.56
                                h-5.12v1.23H37.27l-1.26-1.26V44.05l1.14-1.21h30.42l1.08,1.28v31.45l-1.27,1.2H54.2V77.34z M54.2,76.1h12.92l0.86-0.82V44.37
                                l-0.72-0.85H37.45l-0.76,0.81v30.91l0.87,0.87h10.18v-1.23h5.8V62.44h0.67v12.43h12.43V44.97H38.2v29.89h8.13v-1.23h4.26V62.42
                                h0.67v11.89H47v1.23h-9.48V44.3h29.78v31.24H54.2V76.1z"/>
                                </g>
                                <g>
                                <path class="st2" d="M55.92,62.03c0,0.77-0.63,1.4-1.4,1.4h-3.97c-0.77,0-1.4-0.63-1.4-1.4v-4.2c0-0.77,0.63-1.4,1.4-1.4h3.97
                                c0.77,0,1.4,0.63,1.4,1.4V62.03z"/>
                                </g>
                                <g>
                                <path class="st2" d="M71.96,83H33.04C30.81,83,29,81.19,29,78.96V40.04c0-2.23,1.81-4.04,4.04-4.04h38.92
                                c2.23,0,4.04,1.81,4.04,4.04v38.92C76,81.19,74.19,83,71.96,83z M33.04,37.35c-1.48,0-2.69,1.21-2.69,2.69v38.92
                                c0,1.48,1.21,2.69,2.69,2.69h38.92c1.48,0,2.69-1.21,2.69-2.69V40.04c0-1.48-1.21-2.69-2.69-2.69H33.04z"/>
                                </g>
                                <g>
                                <path class="st2" d="M47.65,49.38c0.27-0.04,0.66-0.08,1.1-0.08c0.54,0,0.92,0.08,1.19,0.29c0.22,0.17,0.34,0.43,0.34,0.77
                                c0,0.47-0.33,0.79-0.65,0.9v0.02c0.26,0.1,0.4,0.35,0.49,0.69c0.11,0.41,0.23,0.89,0.3,1.03h-0.85c-0.06-0.11-0.15-0.4-0.26-0.85
                                c-0.1-0.46-0.26-0.58-0.59-0.59h-0.25v1.44h-0.82V49.38z M48.47,50.95h0.33c0.41,0,0.66-0.21,0.66-0.53
                                c0-0.34-0.23-0.51-0.61-0.51c-0.2,0-0.32,0.02-0.38,0.03V50.95z"/>
                                <path class="st2" d="M51.53,49.33h2.24v0.68h-1.41v0.84h1.32v0.67h-1.32v1.47h-0.83V49.33z"/>
                                <path class="st2" d="M55.88,49.33v3.67h-0.83v-3.67H55.88z"/>
                                <path class="st2" d="M57.25,49.38c0.3-0.05,0.7-0.08,1.12-0.08c0.7,0,1.15,0.13,1.5,0.39c0.38,0.28,0.62,0.73,0.62,1.38
                                c0,0.7-0.26,1.19-0.61,1.48c-0.39,0.32-0.97,0.47-1.69,0.47c-0.43,0-0.73-0.03-0.94-0.05V49.38z M58.08,52.37
                                c0.07,0.02,0.18,0.02,0.29,0.02c0.75,0.01,1.24-0.41,1.24-1.28c0.01-0.76-0.44-1.16-1.15-1.16c-0.18,0-0.3,0.02-0.38,0.03V52.37z
                                "/>
                                </g>
                                </g>
                                </g>
                            </svg>



                    </div>
                                       
                </div> 
                    
                </div>
                <div className="section-image d-flex col-sm-9">
                    {showRedes && <Redes/> }
                    {showProteccionPerimetral && <ProteccionPerimetral/>}
                    {showCCTV && <CCTV/>}
                    {showControlesAcceso && <ControlesAcceso/>}
                    {showTecnologiasIdentificacion && <TecnologiasIdentificacion/>}
                    {showRFID && <RFID/>}
                    {showSistemasGeolocalizacion && <SistemasGeolocalizacion/>}
                    {showDataCenter && <DataCenter/>}
                    {showInhibicionCelular && <InhibicionCelular/>}
                    {showAutomatizacionBMS && <AutomatizacionBMS/>}

                </div>
            </div>
            
        </section>

        );
    }
    componentDidMount(){
        window.scrollTo(0,0);
    }
}
export default SissaInfraestructura_Contenido;

