import React, { Component } from 'react';
import { List, Modal, Button, Icon } from 'antd';
import '../../stylesheets/dashboard/dashboard.css';

import { connect } from "react-redux";
import ModalEditarEmpleado from './ModalEditarEmpleado';
import ModalNuevoEmpleado from './ModalNuevoEmpleado';

const confirm = Modal.confirm;

class ListaEmpleados extends Component {
    componentDidMount() {
        this.props.onRequestListEmpleadosActivos();
        this.props.onRequestPerfiles();
    }
    
    render() {
        const {fetchingEmpleadosActivos,
            listEmpleadosActivos,
            onRequestEliminaEmpleado,
            onRequestOpenModal,
            onRequestInfoEmpleado,
            onShowNuevoEmpleadoModal,
        } = this.props;


        const showInfoEmpleado = (idUsuario) => {
            //console.log(idUsuario);
            onRequestInfoEmpleado(idUsuario);
        }

        const showDeleteConfirm = (idEmpleado, empleado) => {
            confirm({
                title: '¿Estás seguro que deseas eliminar el usuario '+ empleado +'?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onRequestEliminaEmpleado(idEmpleado);
                },
                onCancel() {
                //console.log('Cancel');
                },
            });	 
        };

        const showEditConfirmPromo = (empleado) => {
            //console.log('empleado');
            //console.log(empleado);
            onRequestInfoEmpleado(empleado.idUsuario);
            onRequestOpenModal();
        };
        
        return (
            <div>
                 <Button onClick={onShowNuevoEmpleadoModal} className="btnNuevoAdmin">
                    Nuevo Operador
                </Button>
            <div className="PM-itemsDiv">
                <List
                header={<div className="header-list">OPERADORES</div>}
                className="demo-loadmore-list"
                loading={fetchingEmpleadosActivos}
                locale={{ emptyText: 'Empty' }}
                itemLayout="horizontal"
                dataSource={listEmpleadosActivos}
                renderItem={item => (
                    <List.Item actions={[
                        <div className="pointer" onClick={(e) => { e.stopPropagation(); showInfoEmpleado(item.idUsuario); } }><Icon type="info-circle" /></div>,
                        <div className="pointer" onClick={(e) => { e.stopPropagation(); showDeleteConfirm(item.idEmpleado, item.empleado); } }><Icon type="delete" /></div>,
                        <div className="pointer" onClick={(e) => { e.stopPropagation(); showEditConfirmPromo(item); } }><Icon type="edit" /></div>]}>
                    <List.Item.Meta
                        title={<p>ID Empleado: {item.idEmpleado} | {item.empleado}</p>}
                        description={item.mail}
                    />
                    </List.Item>
                )}
                /> 
                <ModalEditarEmpleado/>
                <ModalNuevoEmpleado/>
            </div>
            </div>
        );
    }
}

const mapStateToProps = state => {

	return {
        fetchingEmpleadosActivos: state.AdministradorReducer.fetchingEmpleadosActivos,
        listEmpleadosActivos: state.AdministradorReducer.listEmpleadosActivos,
	};
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestListEmpleadosActivos: () => {
            dispatch({type: "EMPLEADOS_ACTIVOS_REQUEST"});
        },
        onRequestOpenModal: () => {
            dispatch({type: "OPEN_EDITAR_EMPLEADO_MODAL", openEditarEmpleadoModal:true})
        },
        onRequestInfoEmpleado: (idUsuario) => {
            dispatch({type: "EMPLEADO_DETALLE_REQUEST", idUsuario:idUsuario})
        },
        onRequestEliminaEmpleado: (idEmpleado) => {
            dispatch({ type: "ELIMINAR_EMPLEADO_REQUEST" ,idEmpleado:idEmpleado });
        },
        onRequestPerfiles: () => {
            dispatch({ type: 'GET_PERFILES_REQUEST'})
        },
        onShowNuevoEmpleadoModal: () => {
            dispatch({ type: "OPEN_NUEVO_EMPLEADO_MODAL" , openNuevoEmpleadoModal:true});
        },
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(ListaEmpleados);

