import React, { Component } from 'react';

import img from '../../../../assets/img/nosotros/sissa-infraestructura/instalaciones-hidraulicas.png';


class InstalacionesHidraulicas extends Component {

 
    render() {    

        
    return(        
            <div>
                <img className="img-fluid" alt="Instalaciones hidráulicas" src={img} />                
            </div>                       
    );
    }
}

export default InstalacionesHidraulicas;