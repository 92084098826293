import React, { Component } from 'react';
import { Row, Col, Icon, Card, Button, Tooltip, Spin, Modal } from 'antd';
import '../../stylesheets/Administrator/Administrator.css';
import { connect } from "react-redux";
import ModalAgregarFoto from './ModalAgregarFoto';
import ModalEditarFoto from './ModalEditarFoto';

const confirm = Modal.confirm;

class Galeria extends Component {
    componentDidMount(){
        this.props.onRequestFotos();
    }
    render() {
        const { galeria,
            showAgregarFotoModal, 
            fetchingGaleria, 
            onshowEditarFotoModal,
            onRequestEliminaElemento
        } = this.props;

        const showDeleteConfirm = (idGaleria, nombre) => {
            confirm({
                title: '¿Estás seguro que deseas eliminar la imagen: "'+ nombre +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onRequestEliminaElemento(idGaleria);
                },
                onCancel() {
                //console.log('Cancel');
                },
            });	  
        };
        
        return (
            <div className="PM-itemsDiv">
            <ModalAgregarFoto/>
            <ModalEditarFoto/>
                <br/>
                <Row>
                    <Col span={4} offset={10}> 
                        <h1 className="tituloGaleria">Galería</h1>
                    </Col>
                    <Col span={2}>
                        <Tooltip placement="top" title="Agregar una imagen a la galería.">
                            <Button onClick={showAgregarFotoModal} className="btnAgregar">+</Button>
                        </Tooltip>
                    </Col>
                </Row>
                <Row>
                    <Spin spinning={fetchingGaleria}>
                        {galeria.map(item =>
                            <Col lg={{span:7, offset:1}}>
                                <Card
                                className="cardGaleria"
                                actions={[
                                    <Icon type="delete" key="delete" onClick={(e) => { e.stopPropagation(); showDeleteConfirm(item.idGaleria, item.nombre); } } />,
                                    <Icon type="edit" key="edit"  onClick={(e) => { e.stopPropagation(); onshowEditarFotoModal(item)}} />,
                                ]}
                                cover={<img className="imagenGaleria" alt={"imagen-galeria"+item.idGaleria} src={item.imagen} />}
                                >
                                </Card>
                            </Col>
                        )}
                    </Spin>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {

    return {
        galeria: state.AdministradorReducer.galeria,
        cantidadFotos:state.AdministradorReducer.cantidadFotos,
        fetchingGaleria: state.AdministradorReducer.fetchingGaleria
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestFotos: () => {
            dispatch({ type: "GET_GALERIA_REQUEST" });
        },
        showAgregarFotoModal: () => {
            dispatch({ type: "SHOW_AGREGAR_FOTO_MODAL", showAgregarFotoModal:true});
        },
        onshowEditarFotoModal: (fotoInfo) => {
            dispatch({ type: "SHOW_EDITAR_FOTO_MODAL", showEditarFotoModal:true, fotoInfo});
        },
        onRequestEliminaElemento:(idGaleria) =>{
            dispatch({type: "ELIMINAR_FOTO_REQUEST", idGaleria})
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Galeria);

