import React, { Component } from 'react';
import '../../stylesheets/LandingPage/SISSALandingPage.css';
import imagenLogo from '../../assets/img/info/logo_sissa_digital.png';
import ixmakiLogo from '../../assets/img/info/logo_ixmaki.png';
import VSSLogo from '../../assets/img/info/logo_vss.png';
import KaliLogo from '../../assets/img/info/logo_kaliconnect.png';
import appController from '../../assets/img/info/logo_app_controller-removebg-preview.png';
//import videoIxmaki from '../../assets/videos/IXMAKI-2021.mp4'
import IxmakiFoto from '../../assets/img/info/IXMAKIVideo.png';
import ixmakiLinea from '../../assets/img/info/ixmakiLinea.png';

//import videoVSS from '../../assets/videos/VSS.mp4'
import VSSFoto from '../../assets/img/info/VSSFoto.png';
import VSSLinea from '../../assets/img/info/LineaVSS.png';

//import videoKali from '../../assets/videos/KALICONNECT.mp4';
import KaliFoto from '../../assets/img/info/KaliFoto.png';
import KaliLinea from '../../assets/img/info/LineaKali.png';

//import videoApp from '../../assets/videos/video_app_controller.mp4';
import AppFoto from '../../assets/img/info/app_video.png';
import AppLinea from '../../assets/img/info/LineaApp.png';

import pdfVSS from '../../assets/img/info/VSS-2021.pdf';
import pdfApp from '../../assets/img/info/APP-CONTROLLER.pdf';

import { Card, Col, Row, Button, Divider } from 'antd';

class SISSAMonitoringLandingPage extends Component {
    state = {
        width: 0,
    };

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    render() {
        return (
            <div>
                {this.state.width >= 1000 ? (
                    <>
                        <Col className='textoImagen' span={24}>
                            <Col lg={{ span: 18, offset: 4 }} xs={{ span: 20, offset: 2 }}>
                                <Row>
                                    <Col lg={{ span: 10 }} xs={{ span: 24 }}>
                                        <img alt='img' className='imagenLogo' src={imagenLogo} />
                                    </Col>
                                    <Col lg={{ span: 24 }}>
                                        <div style={{ marginTop: '1.5em' }} />
                                        <p className='textoFoto'>Desarrollamos</p>
                                        <p className='textoFoto2'>soluciones digitales de innovación</p>
                                        <div style={{ marginBottom: '4em' }} />
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Row>
                            <Col lg={{ span: 14, offset: 5 }} xs={{ span: 24 }}>
                                <Card className='cardProductos'>
                                    <Row className='lineaDerecha2'>
                                        <Col lg={{ span: 6 }} className='lineaDerecha'>
                                            <Col span={18} offset={2}>
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href='https://www.ixmaki.com.mx/'>
                                                    <img alt='img' className='imagenLogo2' src={ixmakiLogo} />
                                                </a>
                                            </Col>
                                        </Col>
                                        <Col lg={{ span: 12 }} className='lineaDerecha'>
                                            <Row>
                                                <Col lg={{ span: 24 }}>
                                                    <p className='textoCentroProductos'>Nuestros Productos</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={{ span: 13 }} className='lineaDerecha'>
                                                    <Col span={20} offset={2}>
                                                        <a style={{ color: 'white', textDecorationLine: 'none' }} href={pdfVSS} download='VSS_SISSA_DIGITAL'>
                                                            <img alt='img' className='imagenLogo2' src={VSSLogo} />
                                                        </a>
                                                    </Col>
                                                </Col>
                                                <Col lg={{ span: 11 }}>
                                                    <Col span={14} offset={5}>
                                                        <a style={{ color: 'white', textDecorationLine: 'none' }} href={pdfApp} download='APP_CONTROLLER_SISSA_DIGITAL'>
                                                            <img alt='img' className='imagenLogo2' src={KaliLogo} />
                                                        </a>
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={{ span: 6 }}>
                                            <Col lg={{ span: 18, offset: 4 }} xs={{ span: 18, offset: 2 }}>
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href='https://kali.painani.mx/'>
                                                    <img alt='img' className='imagenLogo2' src={appController} />
                                                </a>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 18, offset: 4 }} xs={{ span: 20, offset: 2 }} className='EspacioArriba'>
                                <Row>
                                    <Col lg={{ span: 12 }}>
                                        {/* <video controls className='videoPresentacion' poster={IxmakiFoto}>
                                            <source src={videoIxmaki} type='video/mp4' />
                                            Tu navegador no soporta HTML5 video.
                                        </video> */}
                                    </Col>
                                    <Col lg={{ span: 11, offset: 1 }}>
                                        <Col lg={{ span: 13 }}>
                                            <br />
                                            <h2>
                                                <span className='tituloAzul'>IXMAKI</span>
                                                <span className='letraNegritaP'> es un</span>
                                                <span className='letraNegritaG'> SOFTWARE</span>
                                                <span className='letraSinNegritas'> hecho en México</span>
                                            </h2>
                                        </Col>
                                        <Col lg={{ span: 11 }}>
                                            <img alt='img' className='imagenLinea' src={ixmakiLinea} />
                                        </Col>
                                        <Col span={24}>
                                            <p className='textoVideo'>Integra la mejor tecnología biométrica del mundo, siendo una solución ya probada en Centros Federales de Readaptación Social que permite hacer búsquedas 1:N en bases de datos con millones de usuarios.</p>
                                            <Button className='btnDescargar'>
                                                {' '}
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href='https://www.ixmaki.com.mx/'>
                                                    Ir a página
                                                </a>{' '}
                                            </Button>
                                        </Col>
                                    </Col>
                                </Row>
                                <Divider />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 20, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                                <Row>
                                    <Col lg={{ span: 12 }}>
                                        <Col lg={{ span: 13 }}>
                                            <img alt='img' className='imagenLinea2' src={VSSLinea} />
                                        </Col>
                                        <Col lg={{ span: 11 }}>
                                            <br />
                                            <h2>
                                                <span className='tituloAzul'>PLATAFORMA</span>
                                                <span className='letraSinNegritas'> de Integración</span>
                                                <span className='letraNegritaP'> de Sistemas</span>
                                            </h2>
                                        </Col>
                                        <Col span={24}>
                                            <p className='textoVideo'>Permite el monitoreo, automatización y control de los sistemas electrónicos de seguridad, sistemas de tecnologías de la información y sistemas de soporte a la operación y de cualquier infraestructura crítica.</p>
                                            <Button className='btnDescargar'>
                                                {' '}
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href={pdfVSS} download='VSS_SISSA_DIGITAL'>
                                                    Descargar PDF
                                                </a>{' '}
                                            </Button>
                                        </Col>
                                    </Col>
                                    <Col lg={{ span: 11, offset: 1 }}>
                                        {/* <video controls className='videoPresentacion' poster={VSSFoto}>
                                            <source src={videoVSS} type='video/mp4' />
                                            Tu navegador no soporta HTML5 video.
                                        </video> */}
                                    </Col>
                                </Row>
                                <Divider />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 18, offset: 4 }} xs={{ span: 20, offset: 2 }}>
                                <Row>
                                    <Col lg={{ span: 12 }}>
                                        {/* <video controls className='videoPresentacion' poster={KaliFoto}>
                                            <source src={videoKali} type='video/mp4' />
                                            Tu navegador no soporta HTML5 video.
                                        </video> */}
                                    </Col>
                                    <Col lg={{ span: 11, offset: 1 }}>
                                        <Col lg={{ span: 13 }}>
                                            <br />
                                            <h2>
                                                <span className='tituloAzul'>Aplicación</span>
                                                <span className='letraNegritaG'> MÓVIL</span>
                                                <span className='letraSinNegritas'> multifuncional</span>
                                            </h2>
                                        </Col>
                                        <Col lg={{ span: 11 }}>
                                            <img alt='img' className='imagenLinea' src={KaliLinea} />
                                        </Col>
                                        <Col span={24}>
                                            <p className='textoVideo'>Permite que sus usuarios se comuniquen de manera directa, confidencial y segura con el administrador Web o con la empresa prestadora del servicio de monitoreo 24/7, en tiempo real y desde cualquier lugar donde se encuentre el Smartphone.</p>
                                            <Button className='btnDescargar'>
                                                {' '}
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href='https://kali.painani.mx/'>
                                                    Ir a página
                                                </a>{' '}
                                            </Button>
                                        </Col>
                                    </Col>
                                </Row>
                                <Divider />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 20, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                                <Row>
                                    <Col lg={{ span: 12 }}>
                                        <Col lg={{ span: 13 }}>
                                            <img alt='img' className='imagenLinea3' src={AppLinea} />
                                        </Col>
                                        <Col lg={{ span: 11 }}>
                                            <br />
                                            <h2>
                                                <span className='tituloAzul'>SISTEMA</span>
                                                <span className='letraSinNegritas'> de Gestión</span>
                                            </h2>
                                        </Col>
                                        <Col span={24}>
                                            <p className='textoVideo'>Permite al personal operativo, mediante un simple botón, obtener un cálculo estimado de las solicitudes realizadas en un estado de deductiva.</p>
                                            <Button className='btnDescargar'>
                                                {' '}
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href={pdfApp} download='APP_CONTROLLER_SISSA_DIGITAL'>
                                                    Descargar PDF
                                                </a>{' '}
                                            </Button>
                                        </Col>
                                    </Col>
                                    <Col lg={{ span: 11, offset: 1 }}>
                                        {/* <video controls className='videoPresentacion' poster={AppFoto}>
                                            <source src={videoApp} type='video/mp4' />
                                            Tu navegador no soporta HTML5 video.
                                        </video> */}
                                    </Col>
                                </Row>
                                <Divider />
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <Col className='textoImagen' span={24}>
                            <Col lg={{ span: 18, offset: 4 }} xs={{ span: 20, offset: 2 }}>
                                <Row>
                                    <Col lg={{ span: 10 }} xs={{ span: 15, offset: 1 }}>
                                        <img alt='img' className='imagenLogo' src={imagenLogo} />
                                    </Col>
                                    <Col lg={{ span: 24 }}>
                                        <p className='textoFoto'>Desarrollamos</p>
                                        <p className='textoFoto2'>soluciones digitales de innovación</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Row>
                            {/* <Col lg={{span:14, offset:5}} xs={{span:24}}>
                        <Card className="cardProductos">
                            <Row className="lineaDerecha2">
                                <Col lg={{span:6}} className="lineaDerecha">
                                    <Col span={18} offset={2}>
                                    <a style={{color:'white', textDecorationLine:'none'}} href="https://www.ixmaki.com.mx/"><img alt='img' className="imagenLogo2" src={ixmakiLogo}/></a>
                                    </Col>
                                </Col> */}
                            <Col lg={{ span: 12 }} xs={{ span: 24 }} className='lineaDerecha'>
                                <Row>
                                    <Col lg={{ span: 24 }}>
                                        <p className='textoCentroProductos'>Nuestros Productos</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ span: 22, offset: 1 }}>
                                        <Row>
                                            <Col xs={{ span: 11, offset: 1 }}>
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href='https://www.ixmaki.com.mx/'>
                                                    <img alt='img' className='imagenLogo2' src={ixmakiLogo} />
                                                </a>
                                            </Col>
                                            <Col xs={{ span: 11, offset: 1 }}>
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href={pdfApp} download='APP_CONTROLLER_SISSA_DIGITAL'>
                                                    <img alt='img' className='imagenLogo2' src={appController} />
                                                </a>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col xs={{ span: 11, offset: 1 }}>
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href={pdfVSS} download='VSS_SISSA_DIGITAL'>
                                                    <img alt='img' className='imagenLogo2' src={VSSLogo} />
                                                </a>
                                            </Col>
                                            <Col xs={{ span: 11, offset: 1 }}>
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href='https://kali.painani.mx/'>
                                                    <img alt='img' className='imagenLogo2' src={KaliLogo} />
                                                </a>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Divider />
                            </Col>
                            {/*  <Col lg={{span:6}}>
                                    <Col lg={{span:18, offset:4}} xs={{span:18, offset:2}}>
                                    <a style={{color:'white', textDecorationLine:'none'}} href="https://kali.painani.mx/"><img alt='img' className="imagenLogo2" src={appController}/></a>
                                    </Col>
                                </Col>
                            </Row>
                        </Card>
                    </Col> */}
                        </Row>
                        <Row>
                            <Col lg={{ span: 18, offset: 4 }} xs={{ span: 20, offset: 2 }} className='EspacioArriba'>
                                <Row>
                                    <Col lg={{ span: 12 }}>
                                        {/* <video controls className='videoPresentacion' poster={IxmakiFoto}>
                                            <source src={videoIxmaki} type='video/mp4' />
                                            Tu navegador no soporta HTML5 video.
                                        </video> */}
                                    </Col>
                                    <Col lg={{ span: 11, offset: 1 }}>
                                        <Col lg={{ span: 13 }}>
                                            <br />
                                            <h2>
                                                <span className='tituloAzul'>IXMAKI</span>
                                                <span className='letraNegritaP'> es un</span>
                                                <span className='letraNegritaG'> SOFTWARE</span>
                                                <span className='letraSinNegritas'> hecho en México</span>
                                            </h2>
                                        </Col>
                                        <Col lg={{ span: 11 }}>
                                            <img alt='img' className='imagenLinea' src={ixmakiLinea} />
                                        </Col>
                                        <Col span={24}>
                                            <p className='textoVideo'>Integra la mejor tecnología biométrica del mundo, siendo una solución ya probada en Centros Federales de Readaptación Social que permite hacer búsquedas 1:N en bases de datos con millones de usuarios.</p>
                                            <Button className='btnDescargar'>
                                                {' '}
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href='https://www.ixmaki.com.mx/'>
                                                    Ir a página
                                                </a>{' '}
                                            </Button>
                                        </Col>
                                    </Col>
                                </Row>
                                <Divider />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 20, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                                <Row>
                                    <Col lg={{ span: 11, offset: 1 }}>
                                        {/* <video controls className='videoPresentacion' poster={VSSFoto}>
                                            <source src={videoVSS} type='video/mp4' />
                                            Tu navegador no soporta HTML5 video.
                                        </video> */}
                                    </Col>
                                    <Col lg={{ span: 12 }}>
                                        <Col lg={{ span: 13 }}>
                                            <img alt='img' className='imagenLinea2' src={VSSLinea} />
                                        </Col>
                                        <Col lg={{ span: 11 }}>
                                            <br />
                                            <h2>
                                                <span className='tituloAzul'>PLATAFORMA</span>
                                                <span className='letraSinNegritas'> de Integración</span>
                                                <span className='letraNegritaP'> de Sistemas</span>
                                            </h2>
                                        </Col>
                                        <Col span={24}>
                                            <p className='textoVideo'>Permite el monitoreo, automatización y control de los sistemas electrónicos de seguridad, sistemas de tecnologías de la información y sistemas de soporte a la operación y de cualquier infraestructura crítica.</p>
                                            <Button className='btnDescargar'>
                                                {' '}
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href={pdfVSS} download='VSS_SISSA_DIGITAL'>
                                                    Descargar PDF
                                                </a>{' '}
                                            </Button>
                                        </Col>
                                    </Col>
                                </Row>
                                <Divider />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 18, offset: 4 }} xs={{ span: 20, offset: 2 }}>
                                <Row>
                                    <Col lg={{ span: 12 }}>
                                        {/* <video controls className='videoPresentacion' poster={KaliFoto}>
                                            <source src={videoKali} type='video/mp4' />
                                            Tu navegador no soporta HTML5 video.
                                        </video> */}
                                    </Col>
                                    <Col lg={{ span: 11, offset: 1 }}>
                                        <Col lg={{ span: 13 }}>
                                            <br />
                                            <h2>
                                                <span className='tituloAzul'>Aplicación</span>
                                                <span className='letraNegritaG'> MÓVIL</span>
                                                <span className='letraSinNegritas'> multifuncional</span>
                                            </h2>
                                        </Col>
                                        <Col lg={{ span: 11 }}>
                                            <img alt='img' className='imagenLinea' src={KaliLinea} />
                                        </Col>
                                        <Col span={24}>
                                            <p className='textoVideo'>Permite que sus usuarios se comuniquen de manera directa, confidencial y segura con el administrador Web o con la empresa prestadora del servicio de monitoreo 24/7, en tiempo real y desde cualquier lugar donde se encuentre el Smartphone.</p>
                                            <Button className='btnDescargar'>
                                                {' '}
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href='https://kali.painani.mx/'>
                                                    Ir a página
                                                </a>{' '}
                                            </Button>
                                        </Col>
                                    </Col>
                                </Row>
                                <Divider />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ span: 20, offset: 2 }} xs={{ span: 20, offset: 2 }}>
                                <Row>
                                    <Col lg={{ span: 11, offset: 1 }}>
                                        {/* <video controls className='videoPresentacion' poster={AppFoto}>
                                            <source src={videoApp} type='video/mp4' />
                                            Tu navegador no soporta HTML5 video.
                                        </video> */}
                                    </Col>
                                    <Col lg={{ span: 12 }}>
                                        <Col lg={{ span: 13 }}>
                                            <img alt='img' className='imagenLinea3' src={AppLinea} />
                                        </Col>
                                        <Col lg={{ span: 11 }}>
                                            <br />
                                            <h2>
                                                <span className='tituloAzul'>SISTEMA</span>
                                                <span className='letraSinNegritas'> de Gestión</span>
                                            </h2>
                                        </Col>
                                        <Col span={24}>
                                            <p className='textoVideo'>Permite al personal operativo, mediante un simple botón, obtener un cálculo estimado de las solicitudes realizadas en un estado de deductiva.</p>
                                            <Button className='btnDescargar'>
                                                {' '}
                                                <a style={{ color: 'white', textDecorationLine: 'none' }} href={pdfApp} download='APP_CONTROLLER_SISSA_DIGITAL'>
                                                    Descargar PDF
                                                </a>{' '}
                                            </Button>
                                        </Col>
                                    </Col>
                                </Row>
                                <Divider />
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        );
    }
}

export default SISSAMonitoringLandingPage;
