import React, { Component } from 'react';

import img from '../../../../assets/img/nosotros/sissa-monitoring/controles_acceso_vehicular.png';


class ControlesAcceso extends Component {

 
    render() {    

        
    return(        
            <div>
                <img className="img-fluid" alt="Controles de acceso vehicolar y peatonal" src={img} />                
            </div>                       
    );
    }
}

export default ControlesAcceso;