import React, { Component } from 'react';
import { Card, Button, Form, Col, Input, Row, Icon, Spin } from 'antd';
import { connect } from 'react-redux';
import '../../stylesheets/dashboard/dashboard.css';

const { TextArea } = Input;
class FormContacto extends Component {
    render() {
        const { getFieldDecorator } = this.props.form;
        const { onRequestForm, cleanForm } = this.props;
        if (cleanForm && cleanForm === 'crearEstadoFisicoActivo') {
            this.props.form.resetFields();
            this.props.onLimpiarFormulario();
        }

        const onSubmit = (e) => {
            e.preventDefault();
            this.props.form.validateFields((err, values) => {
                //console.log("mi formulario", values)
                if (!err) {
                    onRequestForm(values);
                }
            });
        };

        return (
            <div className='Appfondo'>
                <Card bordered={false}>
                    <Spin spinning={this.props.fetchingSendEmailContact}>
                        <Form onSubmit={onSubmit}>
                            <Row type='flex' justify='space-around'>
                                <Col xs={24} sm={24}>
                                    <Form.Item label='Nombre: '>
                                        {getFieldDecorator('nombre', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Favor de llenar el campo.',
                                                },
                                            ],
                                        })(<Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={100} placeholder='Ingresa tu nombre' />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24}>
                                    <Form.Item label='E-mail: '>
                                        {getFieldDecorator('correo', {
                                            rules: [
                                                {
                                                    type: 'email',
                                                    message: '¡No es un E-mail válido!',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Favor de llenar el campo.',
                                                },
                                            ],
                                        })(<Input prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={100} placeholder='Ingresa tu correo electrónico' />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24}>
                                    <Form.Item label='Asunto: '>
                                        {getFieldDecorator('asunto', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Favor de llenar el campo.',
                                                },
                                            ],
                                        })(<Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={100} placeholder='Ingresa el asunto' />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24}>
                                    <Form.Item label='Mensaje: '>
                                        {getFieldDecorator('mensaje', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Favor de llenar el campo.',
                                                },
                                            ],
                                        })(<TextArea rows={4} prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} maxLength={100} placeholder='Ingresa tu mensaje' />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row type='flex' justify='space-around'>
                                <Col xs={12} sm={12} lg={6}>
                                    <Form.Item>
                                        <Button size='large' className='btnMenu alinearForm' htmlType='submit' style={{ backgroundColor: '#a62531', color: 'white', borderRadius: '1em' }}>
                                            Enviar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Card>
                <br />
                <br />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        fetchingSendEmailContact: state.AdministradorReducer.fetchingSendEmailContact,
        cleanForm: state.AdministradorReducer.cleanForm,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRequestForm: (form) => {
            dispatch({ type: 'ENVIAR_CONTACTO_REQUEST', form: form });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'CLEAN_FORM', cleanForm: null });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(FormContacto));
