import React, { Component } from 'react';
import '../../../stylesheets/Pages/mainI.css';
import '../../../stylesheets/Pages/inicio.css';
// import CarouselInicio from '../Pages/carousel-inicio';
// import {Col, Row } from 'antd';
// import ShortDescriptionInicio from '../Pages/short-description-Inicio';
// import SociosDeNegocio from '../Pages/socios-de-negocio';
import Footer from '../../Dashboard/Footer';
import Header from '../../HeaderHome';
import Vertientes from '../Pages/vertientes';
import Eventos from '../Eventos';
import ConoceNuestroBlog from '../Pages/conoceNuestroBlog';
import { Helmet } from 'react-helmet';
import Video from '../Video';
import CasosExito from '../../../Containers/CasoExitoContainer';
import CarruselNuevo from '../Carousel';

class Inicio extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const script = document.createElement('script');
        script.src = '../assets/js/home/banner.js';
        script.async = true;

        document.body.appendChild(script);

        return (
            <div>
                <Helmet>
                    <title>SISSA Monitoring Integral</title>
                    <meta name='viewport' content='width=device-width, initial-scale=1' />
                    <meta name='theme-color' content='#000000' />
                    <meta property='og:title' content='SISSA Monitoring Integral' />
                    <meta property='og:type' content='article' />
                    <meta property='og:url' content='https://www.sissamx.com.mx/' />
                    <meta property='og:description' content='SISSA Monitoring Integral SAPI de CV. Soluciones integrales en el campo de las tecnologías de la información y seguridad electrónica.' />
                    <meta name='description' content='SISSA Monitoring Integral SAPI de CV. Soluciones integrales en el campo de las tecnologías de la información y seguridad electrónica.' />
                </Helmet>
                <Header />
                <main>
                    <CarruselNuevo />

                    <Video />
                    <CasosExito />

                    <Vertientes />

                    <div className='container separator-bottom'>
                        <div className='row'>
                            <div className='col-md-12 separator-line'></div>
                        </div>
                    </div>

                    <ConoceNuestroBlog />

                    <Eventos />
                </main>

                <Footer />
            </div>
        );
    }
}

export default Inicio;
