import React, { Component } from 'react';
import { Input, Col, Pagination, Row, Card, Tabs } from 'antd';
import '../../stylesheets/dashboard/dashboard.css';
import ReactPlayer from 'react-player/youtube'
import { connect } from 'react-redux'

const { TabPane } = Tabs;
const Search = Input.Search;

class BlogCategoriasCards extends Component {
    state = {
        page: 1
    }

    render() {
        const {
            listaCategoriaNotas,
            onRequestlistaCategoriaNotas,
            onBuscarNota,
            totalNotasCategoria,
            totalCategoriaEventos
        } = this.props;

        const changePage = (e) => {
            this.setState({
                page: e,
            })
            onRequestlistaCategoriaNotas(e)
        }

        const onSearch = (value) => {
            onBuscarNota(value);
        }

        return (
            <div className="dashboardFondo">
                <br />
                <br />
                <br />
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Blog" key="1">

                        {totalNotasCategoria === 0 ? (
                            <p className="blogTitulo">Por el momento no se encuentran notas de esta categoría.</p>
                        ) : (
                            <div>
                                {listaCategoriaNotas[0] ? (
                                    <div>
                                        <h1 className="blogTitulo">{listaCategoriaNotas[0].categoriaByIdCategoria.descripcion}</h1>
                                        <hr />
                                        <p className="totalBlog">{totalNotasCategoria} artículos</p>
                                    </div>
                                ) : (false)}

                                <br />
                                {listaCategoriaNotas[0] ? (
                                    <Col span={8}>
                                        <Search
                                            placeholder="Buscar Nota "
                                            onSearch={value => onSearch(value)}
                                            className="searchBar"
                                            allowClear
                                        />
                                    </Col>
                                ) : (false)}
                                <br />
                                <br />
                                <br />
                                {listaCategoriaNotas[0] ? (
                                    <div>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <div className="gutter-box">
                                                {listaCategoriaNotas.map((item, idItem) =>
                                                    <div>
                                                        {item.tipoByIdTipo.idTipo === 1 ? (
                                                            <Col className="gutter-row" xs={24} sm={8} md={8} lg={8}>
                                                                <a href={"/Nota/" + item.idNota + '/' + item.listaUrl.tituloURL}>
                                                                    {item.imagen === '' ? (
                                                                        <Card
                                                                            className="cardBlog"
                                                                            hoverable
                                                                            cover={<ReactPlayer
                                                                                className="fotoBlog"
                                                                                width='100%'
                                                                                height='100%'
                                                                                url={item.video}
                                                                            />}
                                                                        >
                                                                            <p className="categoriasBlog">{item.categoriaByIdCategoria.descripcion}</p>
                                                                            <p>Autor: {item.autor}</p>
                                                                            <h3 className="tituloBlog">{item.titulo}</h3>
                                                                            {item.listaTiempo.tieneDias &&
                                                                                <div>
                                                                                    {item.listaDias.listaDiasDiferencia[idItem] === 0 ? (
                                                                                        <p className="tiempoPublicada">Publicada recientemente</p>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {item.listaDias.listaDiasDiferencia[idItem] === 1 ? (
                                                                                                <p className="tiempoPublicada">Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} día</p>
                                                                                            ) : (
                                                                                                <p className="tiempoPublicada">Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} días</p>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            {item.listaTiempo.tieneMeses &&
                                                                                <div>
                                                                                    {item.listaDias.listaMesDiferencia[idItem] === 1 ? (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaMesDiferencia[idItem]} mes</p>
                                                                                    ) : (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaMesDiferencia[idItem]} meses</p>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            {item.listaTiempo.tieneAños &&
                                                                                <div>
                                                                                    {item.listaDias.listaAñosDiferencia[idItem] === 1 ? (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} año</p>
                                                                                    ) : (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} años</p>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            <h6 className="descripcionBlog">{item.descripcion}</h6>
                                                                            {/*  <Meta title={item.titulo} description={item.descripcion} /> */}
                                                                        </Card>
                                                                    ) : (
                                                                        <Card
                                                                            className="cardBlog"
                                                                            hoverable
                                                                            cover={<img alt='img' src={item.imagen} />}
                                                                        >
                                                                            <p className="categoriasBlog">{item.categoriaByIdCategoria.descripcion}</p>
                                                                            <p>Autor: {item.autor}</p>
                                                                            <h3 className="tituloBlog">{item.titulo}</h3>
                                                                            {item.listaTiempo.tieneDias &&
                                                                                <div>
                                                                                    {item.listaDias.listaDiasDiferencia[idItem] === 0 ? (
                                                                                        <p className="tiempoPublicada">Publicada recientemente</p>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {item.listaDias.listaDiasDiferencia[idItem] === 1 ? (
                                                                                                <p className="tiempoPublicada">Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} día</p>
                                                                                            ) : (
                                                                                                <p className="tiempoPublicada">Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} días</p>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            {item.listaTiempo.tieneMeses &&
                                                                                <div>
                                                                                    {item.listaDias.listaMesDiferencia[idItem] === 1 ? (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaMesDiferencia[idItem]} mes</p>
                                                                                    ) : (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaMesDiferencia[idItem]} meses</p>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            {item.listaTiempo.tieneAños &&
                                                                                <div>
                                                                                    {item.listaDias.listaAñosDiferencia[idItem] === 1 ? (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} año</p>
                                                                                    ) : (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} años</p>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            <h6 className="descripcionBlog">{item.descripcion}</h6>
                                                                            {/*  <Meta title={item.titulo} description={item.descripcion} /> */}
                                                                        </Card>
                                                                    )}
                                                                </a>
                                                                <br />
                                                            </Col>
                                                        ) : (false)}
                                                    </div>
                                                )}
                                            </div>
                                        </Row>
                                        <br />
                                        <Pagination onChange={changePage} total={totalNotasCategoria} style={{ textAlign: "center" }} pageSize={10} />
                                        <br />
                                    </div>
                                ) : (
                                    <p className="blogTitulo">Por el momento no se encuentran notas de esta categoría.</p>
                                )}
                            </div>
                        )}
                    </TabPane>
                    <TabPane tab="Eventos" key="2">

                        {totalCategoriaEventos === 0 ? (
                            <p className="blogTitulo">Por el momento no se encuentran eventos de esta categoría.</p>
                        ) : (
                            <div>
                                {listaCategoriaNotas[0] ? (
                                    <div>
                                        <h1 className="blogTitulo">{listaCategoriaNotas[0].categoriaByIdCategoria.descripcion}</h1>
                                        <hr />
                                        <p className="totalBlog">{totalCategoriaEventos} artículos</p>
                                    </div>
                                ) : (false)}

                                <br />
                                {listaCategoriaNotas[0] ? (
                                    <Col span={8}>
                                        <Search
                                            placeholder="Buscar Nota "
                                            onSearch={value => onSearch(value)}
                                            className="searchBar"
                                            allowClear
                                        />
                                    </Col>
                                ) : (false)}
                                <br />
                                <br />
                                <br />
                                {listaCategoriaNotas[0] ? (
                                    <div>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <div className="gutter-box">
                                                {listaCategoriaNotas.map((item, idItem) =>
                                                    <div>
                                                        {item.tipoByIdTipo.idTipo === 1 ? (
                                                            <Col className="gutter-row" xs={24} sm={8} md={8} lg={8}>
                                                                <a href={"/Nota/" + item.idNota + '/' + item.listaUrl.tituloURL}>
                                                                    {item.imagen === '' ? (
                                                                        <Card
                                                                            className="cardBlog"
                                                                            hoverable
                                                                            cover={<ReactPlayer
                                                                                className="fotoBlog"
                                                                                width='100%'
                                                                                height='100%'
                                                                                url={item.video}
                                                                            />}
                                                                        >
                                                                            <p className="categoriasBlog">{item.categoriaByIdCategoria.descripcion}</p>
                                                                            <h3 className="tituloBlog">{item.titulo}</h3>
                                                                            {item.listaTiempo.tieneDias &&
                                                                                <div>
                                                                                    {item.listaDias.listaDiasDiferencia[idItem] === 0 ? (
                                                                                        <p className="tiempoPublicada">Publicada recientemente</p>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {item.listaDias.listaDiasDiferencia[idItem] === 1 ? (
                                                                                                <p className="tiempoPublicada">Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} día</p>
                                                                                            ) : (
                                                                                                <p className="tiempoPublicada">Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} días</p>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            {item.listaTiempo.tieneMeses &&
                                                                                <div>
                                                                                    {item.listaDias.listaMesDiferencia[idItem] === 1 ? (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaMesDiferencia[idItem]} mes</p>
                                                                                    ) : (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaMesDiferencia[idItem]} meses</p>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            {item.listaTiempo.tieneAños &&
                                                                                <div>
                                                                                    {item.listaDias.listaAñosDiferencia[idItem] === 1 ? (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} año</p>
                                                                                    ) : (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} años</p>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            <h6 className="descripcionBlog">{item.descripcion}</h6>
                                                                            {/*  <Meta title={item.titulo} description={item.descripcion} /> */}
                                                                        </Card>
                                                                    ) : (
                                                                        <Card
                                                                            className="cardBlog"
                                                                            hoverable
                                                                            cover={<img alt='img' src={item.imagen} />}
                                                                        >
                                                                            <p className="categoriasBlog">{item.categoriaByIdCategoria.descripcion}</p>
                                                                            <h3 className="tituloBlog">{item.titulo}</h3>
                                                                            {item.listaTiempo.tieneDias &&
                                                                                <div>
                                                                                    {item.listaDias.listaDiasDiferencia[idItem] === 0 ? (
                                                                                        <p className="tiempoPublicada">Publicada recientemente</p>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {item.listaDias.listaDiasDiferencia[idItem] === 1 ? (
                                                                                                <p className="tiempoPublicada">Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} día</p>
                                                                                            ) : (
                                                                                                <p className="tiempoPublicada">Publicada hace {item.listaDias.listaDiasDiferencia[idItem]} días</p>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            {item.listaTiempo.tieneMeses &&
                                                                                <div>
                                                                                    {item.listaDias.listaMesDiferencia[idItem] === 1 ? (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaMesDiferencia[idItem]} mes</p>
                                                                                    ) : (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaMesDiferencia[idItem]} meses</p>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            {item.listaTiempo.tieneAños &&
                                                                                <div>
                                                                                    {item.listaDias.listaAñosDiferencia[idItem] === 1 ? (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} año</p>
                                                                                    ) : (
                                                                                        <p className="tiempoPublicada">Publicada hace {item.listaDias.listaAñosDiferencia[idItem]} años</p>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            <h6 className="descripcionBlog">{item.descripcion}</h6>
                                                                            {/*  <Meta title={item.titulo} description={item.descripcion} /> */}
                                                                        </Card>
                                                                    )}
                                                                </a>
                                                                <br />
                                                            </Col>
                                                        ) : (false)}
                                                    </div>
                                                )}
                                            </div>
                                        </Row>
                                        <br />
                                        <Pagination onChange={changePage} total={totalCategoriaEventos} style={{ textAlign: "center" }} pageSize={10} />
                                        <br />
                                    </div>
                                ) : (
                                    <p className="blogTitulo">Por el momento no se encuentran eventos de esta categoría.</p>
                                )}
                            </div>
                        )}
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}


const mapStateToProps = state => {

    return {
        totalCategoriaNotas: state.AdministradorReducer.totalCategoriaNotas,
        listaCategoriaNotas: state.AdministradorReducer.listaCategoriaNotas,
        categoria: state.AdministradorReducer.categoria,
        totalCategoriaEventos: state.AdministradorReducer.totalCategoriaEventos,
        totalNotasCategoria: state.AdministradorReducer.totalNotasCategoria,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestlistaCategoriaNotas: (page, categoria) => {
            dispatch({ type: "GET_NOTAS_CATEGORIA_REQUEST", page: page, categoria: categoria });
        },
        onBuscarNota: (nota) => {
            dispatch({ type: "BUSCAR_NOTA_CATEGORIAS_REQUEST", nota, page: 0 });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogCategoriasCards);

