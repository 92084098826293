import React, { Component } from 'react';

import imgEstrategia from '../../../../assets/img/nosotros/sissa-digital/estrategia-digital.png';


class EstrategiaDigital extends Component {

 
    render() {         
    return(        
            <div>
                <img className="img-fluid" alt="desarrollo de software" src={imgEstrategia} />                
            </div>                       
    );
    }
}

export default EstrategiaDigital;