import * as administradorActions from '../actions/AdministradorActions';

const initialState = {
    cleanForm: null,
    fetchingSendEmailContact: false,

    defaultCategoria: sessionStorage.getItem('defaultCategoria'),
    showSuccessMsg: false,
    showErrorMsg: false,
    textMessage: null,

    fetchingTiposElementos: false,
    listTiposElementos: [],

    fetchingElementos: false,
    listElementos: [],

    fetchingCrearElemento: false,
    fetchingEditarElemento: false,

    elementoSeleccionado: [],
    showModalNuevoElemeto: false,
    showModalEditarElemeto: false,
    fetchingBorrarElemento: false,

    fetchingCrearNota: false,
    fetchingGetNotas: false,
    listaNotas: [],
    listaEventos: [],
    totalNotas: null,
    total: null,
    totalEventos: null,

    showEditarNotaModal: false,
    fetchingInfoNotas: false,
    notaInfo: [],
    fetchingEditarNota: false,

    fetchingGetCategorias: false,
    categorias: [],

    showNuevaCategoriaModal: false,
    showEditarCategoriaModal: false,
    fetchingNuevaCategoria: false,
    fetchingEditarCategoria: false,
    infoCategoria: [],

    listaNotasCategoria: [],
    fetchingGetNotasCategoria: false,

    listaCategoriaNotas: [],
    totalCategoriaNotas: null,
    totalCategoriaEventos: null,
    categoria: null,

    notasRelacionadaPalabraClave: [],
    notasRelacionadaCategoria: [],
    totalNotasPalabras: null,
    totalNotasCategoria: null,
    cleanTitle: '',

    listEmpleadosActivos: [],
    listIdEmpelados: [],
    listNombresEmpleados: [],
    fetchingEmpleadosActivos: false,

    empleadoDetalle: [],
    fetchingEmpleadoDetalle: false,

    openEditarEmpleadoModal: false,
    fetchingEditarEmpleado: false,

    perfiles: [],
    fetchingPerfiles: false,

    openNuevoEmpleadoModal: false,
    fetchingNuevoEmpleado: false,

    fetchingGaleria: false,
    galeria: [],
    cantidadFotos: null,

    showAgregarFotoModal: false,
    fetchingAgregarGaleria: false,

    showEditarFotoModal: false,
    fotoInfo: [],
    fetchingEditarFoto: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case administradorActions.CLEAN_FORM:
            console.log('action :>> ', action);
            return { ...state, limpiarFormulario: action.limpiarFormulario, mensajeError: action.mensajeError };

        case administradorActions.SHOW_SUCCESS_MSG:
            return { ...state, showSuccessMsg: action.showSuccessMsg, typeMessage: 'success', textMessage: action.textMessage };
        case administradorActions.SHOW_ERROR_MSG:
            return { ...state, showErrorMsg: action.showErrorMsg, typeMessage: 'error', textMessage: action.textMessage };

        case administradorActions.OBTENER_TIPOS_ELEMENTOS_REQUEST:
            return { ...state, fetchingTiposElementos: true };
        case administradorActions.OBTENER_TIPOS_ELEMENTOS_SUCCESS:
            return { ...state, fetchingTiposElementos: false, listTiposElementos: action.listTiposElementos };
        case administradorActions.OBTENER_TIPOS_ELEMENTOS_FAILURE:
            return { ...state, fetchingTiposElementos: false };

        case administradorActions.OBTENER_ELEMENTOS_REQUEST:
            return { ...state, fetchingElementos: true };
        case administradorActions.OBTENER_ELEMENTOS_SUCCESS:
            return { ...state, fetchingElementos: false, listElementos: action.listElementos };
        case administradorActions.OBTENER_ELEMENTOS_FAILURE:
            return { ...state, fetchingElementos: false };

        case administradorActions.CREAR_ELEMENTO_REQUEST:
            return { ...state, fetchingCrearElemento: true };
        case administradorActions.CREAR_ELEMENTO_SUCCESS:
            return { ...state, fetchingCrearElemento: false };
        case administradorActions.CREAR_ELEMENTO_FAILURE:
            return { ...state, fetchingCrearElemento: false };

        case administradorActions.EDITAR_ELEMENTO_REQUEST:
            return { ...state, fetchingEditarElemento: true };
        case administradorActions.EDITAR_ELEMENTO_SUCCESS:
            return { ...state, fetchingEditarElemento: false };
        case administradorActions.EDITAR_ELEMENTO_FAILURE:
            return { ...state, fetchingEditarElemento: false };

        case administradorActions.ELEMENTO_DETALLE:
            return { ...state, elementoSeleccionado: action.elementoSeleccionado };
        case administradorActions.OPEN_NUEVO_ELEMENTO_MODAL:
            return { ...state, showModalNuevoElemeto: action.showModalNuevoElemeto };
        case administradorActions.OPEN_EDITAR_ELEMENTO_MODAL:
            return { ...state, showModalEditarElemeto: action.showModalEditarElemeto };

        case administradorActions.BORRAR_ELEMENTO_REQUEST:
            return { ...state, fetchingBorrarElemento: true };
        case administradorActions.BORRAR_ELEMENTO_SUCCESS:
            return { ...state, fetchingBorrarElemento: false };
        case administradorActions.BORRAR_ELEMENTO_FAILURE:
            return { ...state, fetchingBorrarElemento: false };

        case administradorActions.CREAR_NOTA_REQUEST:
            return { ...state, fetchingCrearNota: true };
        case administradorActions.CREAR_NOTA_SUCCESS:
            return { ...state, fetchingCrearNota: false };
        case administradorActions.CREAR_NOTA_FAILURE:
            return { ...state, fetchingCrearNota: false };

        case administradorActions.GET_NOTAS_REQUEST:
            return { ...state, fetchingGetNotas: true };
        case administradorActions.GET_NOTAS_SUCCESS:
            return { ...state, fetchingGetNotas: false, listaNotas: action.listaNotas, totalNotas: action.totalNotas, total: action.total, totalEventos: action.totalEventos, listaEventos: action.listaEventos };
        case administradorActions.GET_NOTAS_FAILURE:
            return { ...state, fetchingGetNotas: false };

        case administradorActions.GET_NOTAS_CATEGORIA_REQUEST:
            return { ...state };
        case administradorActions.GET_NOTAS_CATEGORIA_SUCCESS:
            return { ...state, listaCategoriaNotas: action.listaCategoriaNotas, totalCategoriaNotas: action.totalCategoriaNotas, categoria: action.categoria, totalCategoriaEventos: action.totalCategoriaEventos, totalNotasCategoria: action.totalNotasCategoria };
        case administradorActions.GET_NOTAS_CATEGORIA_FAILURE:
            return { ...state };

        case administradorActions.GET_NOTA_ESPECIFICA_REQUEST:
            return { ...state };
        case administradorActions.GET_NOTA_ESPECIFICA_SUCCESS:
            return { ...state, notaInfo: action.notaInfo, notasRelacionadaPalabraClave: action.notasRelacionadaPalabraClave, notasRelacionadaCategoria: action.notasRelacionadaCategoria, totalNotasPalabras: action.totalNotasPalabras, totalNotasCategoria: action.totalNotasCategoria, cleanTitle: action.cleanTitle };
        case administradorActions.GET_NOTA_ESPECIFICA_FAILURE:
            return { ...state };

        case administradorActions.CHANGE_CATEGORIA:
            return { ...state, defaultCategoria: action.defaultCategoria };

        case administradorActions.ELIMINAR_NOTA_REQUEST:
            return { ...state, fetchingGetNotas: true };
        case administradorActions.ELIMINAR_NOTA_SUCCESS:
            return { ...state, fetchingGetNotas: false };
        case administradorActions.ELIMINAR_NOTA_FAILURE:
            return { ...state, fetchingGetNotas: false };

        case administradorActions.SHOW_EDITAR_NOTA_MODAL:
            return { ...state, showEditarNotaModal: action.showEditarNotaModal };
        case administradorActions.GET_INFO_NOTA_REQUEST:
            return { ...state, fetchingInfoNotas: true };
        case administradorActions.GET_INFO_NOTA_SUCCESS:
            return { ...state, fetchingInfoNotas: false, notaInfo: action.notaInfo };

        case administradorActions.EDITAR_NOTA_REQUEST:
            return { ...state, fetchingEditarNota: true };
        case administradorActions.EDITAR_NOTA_SUCCESS:
            return { ...state, fetchingEditarNota: false };
        case administradorActions.EDITAR_NOTA_FAILURE:
            return { ...state, fetchingEditarNota: false };

        case administradorActions.GET_CATEGORIAS_REQUEST:
            return { ...state, fetchingGetCategorias: true };
        case administradorActions.GET_CATEGORIAS_SUCCESS:
            return { ...state, fetchingGetCategorias: false, categorias: action.categorias };
        case administradorActions.GET_CATEGORIAS_FAILURE:
            return { ...state, fetchingGetCategorias: false };

        case administradorActions.SHOW_NUEVA_CATEGORIA_MODAL:
            return { ...state, showNuevaCategoriaModal: action.showNuevaCategoriaModal };
        case administradorActions.SHOW_EDITAR_CATEGORIA_MODAL:
            return { ...state, showEditarCategoriaModal: action.showEditarCategoriaModal, infoCategoria: action.infoCategoria };

        case administradorActions.CREAR_CATEGORIA_REQUEST:
            return { ...state, fetchingNuevaCategoria: true };
        case administradorActions.CREAR_CATEGORIA_SUCCESS:
            return { ...state, fetchingNuevaCategoria: false };
        case administradorActions.CREAR_CATEGORIA_FAILURE:
            return { ...state, fetchingNuevaCategoria: false };

        case administradorActions.EDITAR_CATEGORIA_REQUEST:
            return { ...state, fetchingEditarCategoria: true };
        case administradorActions.EDITAR_CATEGORIA_SUCCESS:
            return { ...state, fetchingEditarCategoria: false };
        case administradorActions.EDITAR_CATEGORIA_FAILURE:
            return { ...state, fetchingEditarCategoria: false };

        case administradorActions.ELIMINAR_CATEGORIA_REQUEST:
            return { ...state };
        case administradorActions.ELIMINAR_CATEGORIA_SUCCESS:
            return { ...state };
        case administradorActions.ELIMINAR_CATEGORIA_FAILURE:
            return { ...state };

        case administradorActions.BUSCAR_NOTA_REQUEST:
            return { ...state };
        case administradorActions.BUSCAR_NOTA_SUCCESS:
            return { ...state, listaNotas: action.listaNotas, totalNotas: action.totalNotas };
        case administradorActions.BUSCAR_NOTA_FAILURE:
            return { ...state };

        case administradorActions.BUSCAR_NOTA_CATEGORIAS_REQUEST:
            return { ...state };
        case administradorActions.BUSCAR_NOTA_CATEGORIAS_SUCCESS:
            return { ...state, listaCategoriaNotas: action.listaCategoriaNotas, totalCategoriaNotas: action.totalCategoriaNotas };
        case administradorActions.BUSCAR_NOTA_CATEGORIAS_FAILURE:
            return { ...state };

        case administradorActions.ENVIAR_CONTACTO_REQUEST:
            return { ...state, fetchingSendEmailContact: true };
        case administradorActions.ENVIAR_CONTACTO_SUCCESS:
            return { ...state, fetchingSendEmailContact: false };
        case administradorActions.ENVIAR_CONTACTO_FAILURE:
            return { ...state, fetchingSendEmailContact: false };

        case administradorActions.EMPLEADOS_ACTIVOS_REQUEST:
            return { ...state, fetchingEmpleadosActivos: true };
        case administradorActions.EMPLEADOS_ACTIVOS_SUCCESS:
            return { ...state, fetchingEmpleadosActivos: false, listEmpleadosActivos: action.listEmpleadosActivos, listNombresEmpleados: action.listNombresEmpleados, listIdEmpelados: action.listIdEmpelados };
        case administradorActions.EMPLEADOS_ACTIVOS_FAILURE:
            return { ...state, fetchingEmpleadosActivos: false };

        case administradorActions.EMPLEADO_DETALLE_REQUEST:
            return { ...state, fetchingEmpleadoDetalle: true };
        case administradorActions.EMPLEADO_DETALLE_SUCCESS:
            return { ...state, fetchingEmpleadoDetalle: false, empleadoDetalle: action.empleadoDetalle };
        case administradorActions.EMPLEADO_DETALLE_FAILURE:
            return { ...state, fetchingEmpleadoDetalle: false };

        case administradorActions.OPEN_EDITAR_EMPLEADO_MODAL:
            return { ...state, openEditarEmpleadoModal: action.openEditarEmpleadoModal };
        case administradorActions.OPEN_NUEVO_EMPLEADO_MODAL:
            return { ...state, openNuevoEmpleadoModal: action.openNuevoEmpleadoModal };

        case administradorActions.NUEVO_EMPLEADO_REQUEST:
            return { ...state, fetchingNuevoEmpleado: true };
        case administradorActions.NUEVO_EMPLEADO_SUCCESS:
            return { ...state, fetchingNuevoEmpleado: false };
        case administradorActions.NUEVO_EMPLEADO_FAILURE:
            return { ...state, fetchingNuevoEmpleado: false };

        case administradorActions.GET_PERFILES_REQUEST:
            return { ...state, fetchingPerfiles: true };
        case administradorActions.GET_PERFILES_SUCCESS:
            return { ...state, fetchingPerfiles: false, perfiles: action.perfiles };
        case administradorActions.GET_PERFILES_FAILURE:
            return { ...state, fetchingPerfiles: false };

        case administradorActions.EDITAR_EMPLEADO_REQUEST:
            return { ...state, fetchingEditarEmpleado: true };
        case administradorActions.EDITAR_EMPLEADO_SUCCESS:
            return { ...state, fetchingEditarEmpleado: false };
        case administradorActions.EDITAR_EMPLEADO_FAILURE:
            return { ...state, fetchingEditarEmpleado: false };

        case administradorActions.GET_GALERIA_REQUEST:
            return { ...state, fetchingGaleria: true };
        case administradorActions.GET_GALERIA_SUCCESS:
            return { ...state, fetchingGaleria: false, galeria: action.galeria, cantidadFotos: action.cantidadFotos };
        case administradorActions.GET_GALERIA_FAILURE:
            return { ...state, fetchingGaleria: false };

        case administradorActions.SHOW_AGREGAR_FOTO_MODAL:
            return { ...state, showAgregarFotoModal: action.showAgregarFotoModal };

        case administradorActions.AGREGAR_FOTO_REQUEST:
            return { ...state, fetchingAgregarGaleria: true };
        case administradorActions.AGREGAR_FOTO_SUCCESS:
            return { ...state, fetchingAgregarGaleria: false };
        case administradorActions.AGREGAR_FOTO_FAILURE:
            return { ...state, fetchingAgregarGaleria: false };

        case administradorActions.SHOW_EDITAR_FOTO_MODAL:
            return { ...state, showEditarFotoModal: action.showEditarFotoModal, fotoInfo: action.fotoInfo };

        case administradorActions.EDITAR_FOTO_REQUEST:
            return { ...state, fetchingEditarFoto: true };
        case administradorActions.EDITAR_FOTO_SUCCESS:
            return { ...state, fetchingEditarFoto: false };
        case administradorActions.EDITAR_FOTO_FAILURE:
            return { ...state, fetchingEditarFoto: false };

        case administradorActions.ELIMINAR_FOTO_REQUEST:
            return { ...state };
        case administradorActions.ELIMINAR_FOTO_SUCCESS:
            return { ...state };
        case administradorActions.ELIMINAR_FOTO_FAILURE:
            return { ...state };

        default:
            return state;
    }
};
