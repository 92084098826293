import React, { Component } from 'react';
import { Row, Col } from 'antd';
import CasosExito from '../Components/Dashboard/CasosExito'
import '../stylesheets/dashboard/casosExito.css';

class CasosExitoContainer extends Component {
    render() {
        return (
            <div>
                {/*  <div className="fondoExito"/> */}
                <div className="containerExito">
                    <p className="centrar tituloContenido" id="Blog">NUESTROS PRINCIPALES CLIENTES</p>
                    <Row>
                        <Col xs={24} lg={{ span: 20, offset: 2 }}>
                            <div className="espacioArriba" />
                            <CasosExito />
                            <div className="espacioAbajo" />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default CasosExitoContainer;

