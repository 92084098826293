import React, { Component } from 'react';
import { Card, Alert, Spin, Row, Col, Icon } from 'antd';
import avatar from '../../assets/img/perfilAvatar.jpg';
import { connect } from "react-redux";

import '../../stylesheets/dashboard/dashboard.css';

function isEmpty(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}


class InfoEmpleadoSeleccionado extends Component {

    constructor(props) {
        super(props);
        this.state = { visible: false };
    }

    render() {

        const { empleadoDetalle,
            fetchingEmpleadoDetalle,
        } = this.props;

        //console.log("empleadoDetalle", empleadoDetalle)

        function ShowInfo(props) {
            if (isEmpty(empleadoDetalle)) {
                return (
                    <Alert
                        message="No hay usuario seleccionado"
                        type="info"
                    />);
            }

            else if (fetchingEmpleadoDetalle) {
                return (
                    <div>
                        <Spin spinning={fetchingEmpleadoDetalle} />
                    </div>
                );
            }
            let foto = avatar;
            if (empleadoDetalle.foto)
                foto = empleadoDetalle.foto;

            return (
                <div>
                    <Row align="middle" justify="center">
                        <Col span={24}>
                            <img className="logoEmpleado" src={foto} alt="Smiley face" />
                        </Col>
                    </Row>

                    <Row align="middle" justify="center">
                        <Col span={24}>
                            <div className="nombreEmpleado">
                                {empleadoDetalle.empleado}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <div className="iconEmpleado">
                                <Icon type="user" />
                            </div>
                        </Col>
                        <Col span={20}>
                            <div className="infoEmpleado">
                                <div>{empleadoDetalle.perfiles[0]}</div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={4}>
                            <div className="iconEmpleado">
                                <Icon type="mail" />
                            </div>
                        </Col>
                        <Col span={20}>
                            <div className="infoEmpleado">
                                <div>{empleadoDetalle.ife}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        }

        return (
            <div>
                <Card className="cardDetalle" title="Usuario">
                    <ShowInfo info={empleadoDetalle} />
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        empleadoDetalle: state.AdministradorReducer.empleadoDetalle,
        fetchingEmpleadoDetalle: state.AdministradorReducer.fetchingEmpleadoDetalle,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onChangeEditMode: () => {
            dispatch({ type: "CHANGE_EDIT_MODE_PROVEEDOR", editModeProveedor: true });
        },
        onChangeMenuMode: () => {
            dispatch({ type: "CHANGE_MENU_MODE_PROVEEDOR", menuModeProveedor: true });
        },

        onShowNuevoProveedorModal: () => {
            dispatch({ type: "OPEN_NUEVO_PROVEEDOR_MODAL", openNuevoProveedorModal: true });
        },
    };
};




export default connect(mapStateToProps, mapDispatchToProps)(InfoEmpleadoSeleccionado);
