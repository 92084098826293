import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import '../../stylesheets/dashboard/dashboard.css';
import { connect } from 'react-redux'

class Eventos extends Component {
    state = {
        page: 1
    }
    componentDidMount() {
        this.props.onRequestListaNotas(0)
    }
    render() {
        const {
            listaEventos
        } = this.props;
        return (
            <div>
                {listaEventos[0] ? (
                    <div>
                        <Row style={{ backgroundColor: "#a62531" }}>
                            <Col span={24} className="fondoBlanco">
                                <p className="textoReto" style={{ textAlign: "center" }}><span style={{ fontWeight: "bolder" }}>SALA DE PRENSA</span></p>
                            </Col>
                            <Row>
                                <Col lg={{ span: 8, offset: 2 }} sm={{ span: 22, offset: 2 }} xs={{ span: 22, offset: 2 }}>
                                    <a href={"/Nota/" + listaEventos[0].idNota + '/' + listaEventos[0].listaUrl.tituloURL}>
                                        <Card
                                            hoverable
                                            className="cardEvento"
                                            cover={<img className="imagenEvento" alt="example" src={listaEventos[0].imagen} />}
                                        >
                                            <p className="titloEvento" >{listaEventos[0].titulo}</p>
                                            <p className="infoevento">{listaEventos[0].descripcion}</p>
                                            <p style={{ color: '#a62531', fontWeight: "bolder" }} href={"/Nota/" + listaEventos[0].idNota + '/' + listaEventos[0].listaUrl.tituloURL}>Leer más</p>

                                        </Card>
                                    </a>
                                </Col>
                                <Col lg={{ span: 11, offset: 2 }} xs={{ span: 22, offset: 2 }} className="responsiveEspacioArriba">
                                    <Row justify="space-around">
                                        <Col lg={12} sm={10} xs={20}>
                                            {listaEventos[1] &&
                                                <a href={"/Nota/" + listaEventos[1].idNota + '/' + listaEventos[1].listaUrl.tituloURL}>
                                                    <Card
                                                        hoverable
                                                        className="cardEvento2"
                                                        cover={<img className="imagenEvento2" alt="example" src={listaEventos[1].imagen} />}
                                                    >
                                                        <p className="titloEvento2" >{listaEventos[1].titulo}</p>
                                                        <p className="infoevento3">{listaEventos[1].descripcion}</p>
                                                        <p style={{ color: '#a62531', fontWeight: "bolder" }} className="infoevento3" href={"/Nota/" + listaEventos[1].idNota + '/' + listaEventos[1].listaUrl.tituloURL}>Leer más</p>
                                                    </Card>
                                                </a>
                                            }
                                        </Col>
                                        <Col lg={12} sm={10} xs={20} className="responsiveEspacioArriba">
                                            {listaEventos[2] &&
                                                <a href={"/Nota/" + listaEventos[2].idNota + '/' + listaEventos[2].listaUrl.tituloURL}>
                                                    <Card
                                                        hoverable
                                                        className="cardEvento2"
                                                        cover={<img className="imagenEvento2" alt="example" src={listaEventos[2].imagen} />}
                                                    >
                                                        <p className="titloEvento2" >{listaEventos[2].titulo}</p>
                                                        <p className="infoevento3">{listaEventos[2].descripcion}</p>
                                                        <p style={{ color: '#a62531', fontWeight: "bolder" }} className="infoevento3" href={"/Nota/" + listaEventos[2].idNota + '/' + listaEventos[2].listaUrl.tituloURL}>Leer más</p>
                                                    </Card>
                                                </a>
                                            }
                                        </Col>
                                    </Row>
                                    <Row justify="center" className="espacioArriba">
                                        <Col lg={12} sm={10} xs={20}>
                                            {listaEventos[3] &&
                                                <a href={"/Nota/" + listaEventos[3].idNota + '/' + listaEventos[3].listaUrl.tituloURL}>
                                                    <Card
                                                        hoverable
                                                        className="cardEvento2"
                                                        cover={<img className="imagenEvento2" alt="example" src={listaEventos[3].imagen} />}
                                                    >
                                                        <p className="titloEvento2" >{listaEventos[3].titulo}</p>
                                                        <p className="infoevento3">{listaEventos[3].descripcion}</p>
                                                        <p style={{ color: '#a62531', fontWeight: "bolder" }} className="infoevento3" href={"/Nota/" + listaEventos[3].idNota + '/' + listaEventos[3].listaUrl.tituloURL}>Leer más</p>
                                                    </Card>
                                                </a>
                                            }
                                        </Col>
                                        <Col lg={12} sm={10} xs={20} className="responsiveEspacioArriba">
                                            {listaEventos[4] &&
                                                <a href={"/Nota/" + listaEventos[4].idNota + '/' + listaEventos[4].listaUrl.tituloURL}>
                                                    <Card
                                                        hoverable
                                                        className="cardEvento2"
                                                        cover={<img className="imagenEvento2" alt="example" src={listaEventos[4].imagen} />}
                                                    >
                                                        <p className="titloEvento2" >{listaEventos[4].titulo}</p>
                                                        <p className="infoevento3">{listaEventos[4].descripcion}</p>
                                                        <p style={{ color: '#a62531', fontWeight: "bolder" }} className="infoevento3" href={"/Nota/" + listaEventos[4].idNota + '/' + listaEventos[4].listaUrl.tituloURL}>Leer más</p>
                                                    </Card>
                                                </a>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Row>
                    </div>
                ) : (false)}
            </div>
        );
    }
}

const mapStateToProps = state => {

    return {
        fetchingGetNotas: state.AdministradorReducer.fetchingGetNotas,
        listaNotas: state.AdministradorReducer.listaNotas,
        totalNotas: state.AdministradorReducer.totalNotas,
        total: state.AdministradorReducer.total,
        totalEventos: state.AdministradorReducer.totalEventos,
        listaEventos: state.AdministradorReducer.listaEventos
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestListaNotas: (page) => {
            dispatch({ type: "GET_NOTAS_REQUEST", page: page });
        },
        onBuscarNota: (nota) => {
            dispatch({ type: "BUSCAR_NOTA_REQUEST", nota, page: 0 });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Eventos);



